import React from "react";
// import { useNavigate } from "react-router-dom";
// import TableImportImg from "../../assets/images/table/import.png";
// import ExportImportImg from "../../assets/images/table/export.png";
// import AddPropertyImg from "../../assets/images/table/plus.png";
// import ChevronUP from "../../assets/images/table/chevron-up.png";
// import ChevronDown from "../../assets/images/table/chevron-down.png";
// import RedChevronDown from "../../assets/images/table/red-chevron-down.png";
// import CommonButton from "../../components/commonButton";
// import { routes } from "../../routes/pageRoutes";
import {Table } from "react-bootstrap";
import {
  NpaInquiryList,
  // NpaPropertiesList,
} from "../../components/TableHeadings";
import Input from '../../components/input';
import ExportBtnWithIcon from "../../components/exportBtnWithIcon";
import {
  // EyeIcon,
  MepEyeIcon,
  // MepSentIcon,
  // MoreVerticalIcon,
  // SentIcon,
} from "../../assets/icons";
// import CommonActionDropDown from "../../components/common-action-dropdown";
import NoData from "../../components/no-data";
import { getUserInitial } from "../../components/common";
import StatusChip from "../../components/statusChip";
import moment from "moment";

const InquiryList = ({data,setCurrentPage,setSearchTerm,searchTerm,handleOpenViewModal,handleExportInquiry}) => {

  return (
    <>
        <div className="p-4 d-flex align-items-center justify-content-between gap-4">
          <Input
            type="search"
            value={searchTerm}
            placeholder="Search Inquiry"
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setCurrentPage(1);
            }}
            className="shadow-none base-search"
          />

          <div className="action-buttons-container">
            <ExportBtnWithIcon title="Export" onClick={handleExportInquiry}/>
          </div>
    </div>
      {/* Table */}
      <Table responsive className="common-table">
        <thead>
          <tr>
            {NpaInquiryList?.map((item, i) => (
              <th key={i}>{item.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.length === 0 ? <NoData/> : 
            data?.map((item, i) => (
            <tr key={item?.id}>
              <td className="text-start">{item?.property ? `# ${item?.property?.property_number_id}` : "-"}</td>
                <td>
                  <div className="d-flex align-items-center gap-2">
                    <div className="profile-icon">{item?.user_name && getUserInitial(item?.user_name)}</div>
                    <div className="ms-1"><p className="fw-medium text-capitalize">{item?.user_name ? item?.user_name : "-"}</p>
                    </div>
                  </div>
                </td>
                <td className="text-start">{item?.phone_number ? item?.phone_number : '-'}</td>
                <td>{item?.email ? item?.email : ""}</td>
                <td>{item?.property ?   <StatusChip bg={"chip-green"} pill title={item?.property?.possessionType?.name} className="text-capitalize"/> : ""}</td>
                <td>{item?.property ?   <StatusChip bg={"chip-green"} pill title={item?.property?.propertyType?.name} className="text-capitalize"/> : ""} </td>
                <td className="text-start">
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                    <span>{item?.created_at ? moment(item.created_at).format("DD MMM YYYY") : ""}</span>
                    <span>{item?.created_at ? moment(item.created_at).format("hh:mm A") : ""}</span>
                  </div>
                </td>
              {/* <td>09 Feb 2020 11:00 AM</td> */}
              <td onClick={(e) => e.stopPropagation()}  className="cursor-pointer">
                {/* <span className="mx-2">
                  <MepSentIcon
                    width={17}
                    height={17}
                    color="#6D6B77"
                    strokeWidth="4"
                  />
                </span> */}
                <span onClick={() => handleOpenViewModal(item)}>
                  <MepEyeIcon
                    width={17}
                    height={17}
                    color="#6D6B77"
                    strokeWidth="4"
                  />
                </span>
                {/* <CommonActionDropDown
                  as={ButtonGroup}
                  id={`dropdown-basic-${i}`}
                  icon={
                    <MoreVerticalIcon
                      width={20}
                      height={20}
                      color="#6D6B77"
                      strokeWidth="4"
                    />
                  }
                  actions={getCustomerActions(item)}
                  show={openDropdown === i}
                  onToggle={() => handleToggleDropdown(i)}
                  className="action-menu"
                /> */}
              </td>
            </tr>
            ))
          }
        </tbody>
      </Table>
    </>
  );
};

export default InquiryList;
