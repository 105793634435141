import React from "react";
// import { Link } from "react-router-dom";

export default function BackPageBtnTab({icon, title, onClick,type }) {
  return (
    <div className="d-flex align-items-center text-decoration-none back-page-tab">
      <button onClick={onClick} type={type} className="border-0 bg-transparent">
        {icon && <div>{icon}</div>}
      </button>
      {title && <h2 className="fw-medium">{title}</h2>}
    </div>
  );
}
