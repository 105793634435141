import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../modules/Auth/login";
// import Register from "../modules/Auth/register";
import NPAProperties from "../modules/NPAProperties";
import CommonLayout from "../layouts";
import { AuthProvider } from "../context/AuthContext";
import { LoaderProvider } from "../context/LoaderContext";
import Loader from "../components/loader";
import PrivateRouteLayout from './PrivateRouteLayout'; 
import { routes } from "./pageRoutes";
import AddNpaProperties from "../modules/NPAProperties/AddNpaProperties";
import NPAInquiries from "../modules/NPAInquiries";
import ViewNpaProperties from "../modules/NPAProperties/ViewNpaProperties";
import AllUsers from "../modules/UserDetails/AllUsers";
import RequestedUser from "../modules/UserDetails/RequestedUser";
import ApprovedUser from "../modules/UserDetails/ApprovedUser";
import RejectedUser from "../modules/UserDetails/RejectedUser";
import Profile from "../modules/Profile";
import Banks from "../modules/BankManage/Banks";
import { Toaster } from "react-hot-toast";
import ViewBankDetails from "../modules/BankManage/Banks/ViewBankDetails";

const AppRoutes = () => (
  <AuthProvider>
    <LoaderProvider>
      <Loader />
      <Router>
        <Routes>
          <Route exact path={routes.login} element={<Login />} />
          {/* <Route exact path={routes.register} element={<Register />} /> */}
            <Route path={routes.home} element={<PrivateRouteLayout />}>
              <Route path={routes.home} element={<CommonLayout />}>
              
               <Route exact path={routes.home} element={<NPAProperties />} />
                
               <Route exact path={routes.profile} element={<Profile/>}/>

               <Route exact path={routes.addNpaProperties} element={<AddNpaProperties/>}/>
               <Route exact path={routes.editNpaProperties} element={<AddNpaProperties/>}/>
               <Route exact path={routes.viewNpaProperties} element={<ViewNpaProperties/>}/>
               
               <Route exact path={routes.npaInquiries} element={<NPAInquiries/>}/>

               <Route exact path={routes.allUsers} element={<AllUsers/>}/>
               <Route exact path={routes.requestedUser} element={<RequestedUser/>}/>
               <Route exact path={routes.approvedUser} element={<ApprovedUser/>}/>
               <Route exact path={routes.rejectedUser} element={<RejectedUser/>}/>

               <Route exact path={routes.banks} element={<Banks />} />
               <Route exact path={routes.viewBanksDetail} element={<ViewBankDetails />} />

                {/* <Route exact path="/testimonials" element={<TestimonialList />} />
                <Route exact path="/testimonial-add" element={<TestimonialAdd />} />
                <Route exact path="/testimonial-edit/:id" element={<TestimonialEdit />} />
                <Route exact path="/teams" element={<TeamList />} />
                <Route exact path="/team-add" element={<TeamAdd />} />
                <Route exact path="/team-edit/:id" element={<TeamEdit />} />
                <Route exact path="/inquiries" element={<InquiryList />} />
                <Route exact path="/products" element={<ProductList />} />
                <Route exact path="/product-add" element={<ProductAdd />} />
                <Route exact path="/product-edit/:id" element={<ProductEdit />} />

                <Route exact path="/general" element={<General />} />
                <Route exact path="/home-page" element={<HomePage />} />
                <Route exact path="/about-us" element={<AboutUs />} />
                <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route exact path="/terms-conditions" element={<TermsAndConditions />} /> */}
              </Route>
          </Route>
        </Routes>
      </Router>
      <Toaster position="top-right" reverseOrder={false} />
    </LoaderProvider>
  </AuthProvider>
);

export default AppRoutes;
