import { configureStore } from '@reduxjs/toolkit';
// import rootReducer from './rootReducer';
import authReducer from './slice/authSlice';
import userReducer from "./slice/userSlice";
import propertyReducer from "./slice/propertiesSlice";
import inquiryReducer from "./slice/inquirySlice";
import bankReducer from "./slice/bankSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    property:propertyReducer,
    inquiry:inquiryReducer,
    user:userReducer,
    bank:bankReducer
  },
  // auth: authReducer,
  // reducer: rootReducer,
  
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),

//   devTools: process.env.NODE_ENV !== 'production',  // Enables Redux DevTools in development
//   // You can also add middleware here if needed
});

export default store;