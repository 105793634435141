import React, { useEffect, useState } from "react";
// import BackPageTab from "../../../components/back-page-tab";
import DashboardCard from "../../../components/dashboard-card";
import RequestedUser from "../../../assets/images/npa-properties/requested-user.png";
// import SbiLogo from "../../../assets/images/banks/detail/sbilogo.png";
import { Card, Container, Modal, Row, Col,Form, ToastContainer, Toast } from "react-bootstrap";
import { ArrowLeftIcon, Cancel01Icon, CheckmarkBadge04Icon } from "../../../assets/icons";
import BackPageBtnTab from "../../../components/back-page-btn-tab";
import { routes } from "../../../routes/pageRoutes";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { reqToAddBranch, reqToGetBankBranchDetails, reqToGetBankDetails } from "../../../redux/slice/bankSlice";
import { useLoader } from "../../../context/LoaderContext";
import { useDispatch, useSelector } from "react-redux";
import { BankIcons } from "../../../components/common";
import DetailList from "./DetailList";
import CommonFormLabel from "../../../components/formLabel";
import Input from "../../../components/input";
import CommonButton from "../../../components/commonButton";
import { Formik } from "formik";
import { addBranchSchema } from "../../../schema/validationSchemas";
import ValidationFeedback from "../../../components/validationFeedback";
import CommonPagination from "../../../components/commonPagination";

export default function ViewBankDetails() {
  const { setLoading } = useLoader();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const id = params?.id;

  const passedPageNo = location?.state?.currentPage

  // Toast
  const [showToast, setShowToast] = useState(false);
  const [toastVariant, setToastVariant] = useState("toast-success");
  const [toastMessage, setToastMessage] = useState("");
  // Add Branch Modal
  const [openAddBranchModal, setOpenAddBranchModal] = useState(false);
  // Search Bar
  const [detailSearchBar,setDetailSearchBar] = useState("")
   // Pagination
    const [
      limit,
      //  setLimit
    ] = useState({ value: 10, label: 10 });
  const [currentPage, setCurrentPage] = useState(1);

  const details = useSelector((state) => state?.bank?.bankDetails?.data);
  const {totalBranches = 0, branches = []} = useSelector((state) => state?.bank?.bankBranchDetails?.data || {}) 

  const initialEventState = {
    branch_name: "",
    branch_city: "",
  };

  // Api Call for Search
  useEffect(() => {
    if (id){
      const delayTimeout = setTimeout(() => {
        dispatch(reqToGetBankBranchDetails({ id, search: detailSearchBar }));
      }, 2000); // 3 second delay
  
      // Cleanup function to clear the timeout if searchTerm changes before the delay
      return () => clearTimeout(delayTimeout);
    }
  }, [id, detailSearchBar, dispatch]);

  
  // API to get Bank Details
  useEffect(() => {
    if (id) {
      setLoading(true);
      Promise.all([dispatch(reqToGetBankDetails({ id })),dispatch(reqToGetBankBranchDetails({ id,page: currentPage, limit: limit?.value, }))])
        .then(() => setLoading(false))  // Stop loading only after both are done
        .catch((err) => {
          setLoading(false); // Ensure loading stops even if one request fails
        });
    }
  }, [dispatch,id,limit?.value, setLoading, currentPage]);
    
 
  const handleSubmit = async (values,{setSubmitting}) => {
    if (!id){
      setToastMessage("Bank Id not found!");
      setToastVariant("toast-success");
      setShowToast(true);
      return
    }

    const data = {
      "bank_id": id,
      "branch_name": values?.branch_name,
      "branch_city": values?.branch_city
    }

    setLoading(true)
     try {
      const result = await dispatch(reqToAddBranch({ data })).unwrap();
      if (result?.success === true){
        setToastMessage(result?.message ||"Branch Added successfully!");
        setToastVariant("toast-success");
        setSubmitting(false);
        dispatch(reqToGetBankBranchDetails({ id }))
        setOpenAddBranchModal(false);
        setLoading(false)
      }
    } catch (err) {
      const errorMessage = err?.message || "Failed to Add Branch!";
      setToastMessage(errorMessage);
      setToastVariant("toast-danger");
      setLoading(false);
    } finally {
      setShowToast(true);
      setSubmitting(false);
    }
  }

  const handleBack = () => {
    navigate(routes.banks)
    sessionStorage.setItem('currentPageNo', passedPageNo);
  }

  // Pagination Handler
  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber);
  };

  return (
    <>
     <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={5000}
          autohide
          bg={toastVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{toastMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Container fluid className="px-0">
        <BackPageBtnTab
          onClick={handleBack}
          icon={<ArrowLeftIcon width={22} height={22} color="#444050" strokeWidth="2.2"/>}
          title="Bank Details"
        />
        <div className="grid gap-4 mt-4 pt-1">
          <div className="g-col-5 g-col-xl-4">
            <Card className="card-outer-wrapper w-100">
              <Card.Body className="d-flex align-items-center">
                <div className="d-flex align-items-center gap-2">
                  {details?.bank_name ? <>{BankIcons(details?.bank_name)}</> : "-"}
                  <h5 style={{ color: "#5E5873" }}>{details?.bank_name ? details?.bank_name : "-"}</h5>
                </div>
              </Card.Body>
            </Card>
          </div>
          <DashboardCard
            totalUser={totalBranches}
            title="Total Branch"
            classNameMain="g-col-4 g-col-xxl-3"
            src={RequestedUser}
            alt="Total Branch Image"
            // active={item.status === cardStatus}
            status="pending"
            // onClick={handleCardClick}
          />
        </div>
        <div className="bg-white rounded-2 overflow-hidden common-shadow mt-4">
          <DetailList data={branches} setCurrentPage={setCurrentPage} detailSearchBar={detailSearchBar} setDetailSearchBar={setDetailSearchBar} setOpenAddBranchModal={setOpenAddBranchModal}/>
          {/* Pagination */}
          <div className="p-3 mx-1 d-flex flex-wrap align-items-center justify-content-between gap-4">
            <p className="custom-table-infotext ">
              {/* Displaying Showing 1 to 7 of 100 entries */}
            </p>
            <div className="d-flex gap-4">
              {totalBranches > limit?.value && (
                <>
                  <CommonPagination
                    totalItems={totalBranches}
                    itemsPerPage={limit?.value}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </Container>
      <Modal
        show={openAddBranchModal}
        centered
        dialogClassName="common-popup-dialog"
        contentClassName="common-popup-content"
        backdropClassName="common-popup-backdrop"
        onHide={() => setOpenAddBranchModal(false)}
      >
        <Modal.Body className="common-popup-body">
          <div
            className="position-absolute end-0 z-2 bg-white rounded-1 cursor-pointer d-flex align-items-center justify-content-center common-popup-close-btn"
            onClick={() => setOpenAddBranchModal(false)}
          >
            <Cancel01Icon
              width={16}
              height={16}
              color="#BCBBC1"
              strokeWidth="2.5"
            />
          </div>
          <div
            className="text-center common-popup-heading"
            style={{ paddingRight: "20px", paddingLeft: "20px" }}
          >
            <h2 className="text-center p-light mb-2">Create New Branch</h2>
            <Formik
             initialValues={initialEventState} 
             validationSchema={addBranchSchema}
             onSubmit={handleSubmit}>
            {({errors,touched,isSubmitting,handleChange,handleBlur,values,setFieldValue,handleSubmit}) => (
      
             <>
             {console.log("val",values)}
             {console.log("errors",errors)}
             <Form  onSubmit={handleSubmit}>
               <Row className="text-start">
              <Col className="mt-4" md={12}>
                <CommonFormLabel title="Branch Name" htmlFor="branch_name"/>
                <Input
                  id="branch_name"
                  name="branch_name"
                  type="text"
                  placeholder="Ex. PNB West Branch"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`shadow-none ${touched.branch_name && errors.branch_name ? "error" : "correct"}`}/>
                  {touched.branch_name && errors.branch_name ? (<ValidationFeedback title={errors.branch_name} />) : null}
               </Col>
              <Col className="mt-4 mb-3" md={12}>
                <CommonFormLabel title="City" htmlFor="branch_city"/>
                <Input
                  id="branch_city"
                  name="branch_city"
                  type="text"
                  placeholder="Surat"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`shadow-none ${touched.branch_city && errors.branch_city ? "error" : "correct"}`}/>
                  {touched.branch_city && errors.branch_city ? (<ValidationFeedback title={errors.branch_city} />) : null}
               </Col>
            </Row>
             <div className="text-center" style={{ width: "50%", margin: "0 auto" }}>
              <CommonButton
                title="Add Branch"
                type="submit"
                disabled={isSubmitting}
                className="w-100 btn-primary primary-shadow my-4"
              />
            </div>
             </Form>
             </>
            )}  
            </Formik>

           
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
