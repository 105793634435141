import {NpaPropertiesIcon, NpaInquiriesIcon, RecordIcon} from "../../assets/icons";
import { routes } from "../../routes/pageRoutes";

export const SidebarContent = [
  {
    title: "NPA Properties",
    icon: (
      <NpaPropertiesIcon
        width={18}
        height={18}
        color="#444050"
        strokeWidth="2"
      />
    ),
    link: routes.home,
  },
  {
    title: "NPA Inquiries",
    icon: (
      <NpaInquiriesIcon
        width={22}
        height={22}
        color="#444050"
        strokeWidth="2"
      />
    ),
    link: routes.npaInquiries,
  },
];

export const ConfigurationContent = [
  {
    title: "All Users",
    icon: <RecordIcon width={10} height={10} color="#444050" strokeWidth="3" />,
    link: routes.allUsers,
  },
  {
    title: "Requested User",
    icon: <RecordIcon width={10} height={10} color="#444050" strokeWidth="3" />,
    link: routes.requestedUser,
  },
  {
    title: "Approved User",
    icon: <RecordIcon width={10} height={10} color="#444050" strokeWidth="3" />,
    link: routes.approvedUser,
  },
  {
    title: "Rejected Users",
    icon: <RecordIcon width={10} height={10} color="#444050" strokeWidth="3" />,
    link: routes.rejectedUser,
  },
];

export const BankManageContent = [
  {
    title: "Banks",
    icon: <RecordIcon width={10} height={10} color="#444050" strokeWidth="3" />,
    link: routes.banks,
  },
  // {
  //   title: "Banks Detail",
  //   icon: <RecordIcon width={10} height={10} color="#444050" strokeWidth="3" />,
  //   link: routes.viewBanksDetail,
  // },
];
