import React, { useCallback, useEffect, useRef, useState } from "react";
import { Container, Toast, ToastContainer } from "react-bootstrap";
import InquiryList from "./InquiryList";
import * as XLSX from "xlsx";
import TotalInquiryIcon from "../../assets/images/npa-properties/total-inquiry-icon.png";
import "../../assets/styles/src/container/pages/npa-inquiries.css";
import ViewNpaInquiries from "./ViewNpaInquiries";
import DashboardCard from "../../components/dashboard-card";
import { getInquiries } from "../../redux/slice/inquirySlice";
import { useDispatch, useSelector } from "react-redux";
import CommonPagination from "../../components/commonPagination";
import { useLoader } from "../../context/LoaderContext";
import { CheckmarkBadge04Icon } from "../../assets/icons";

const Index = () => {
  const dispatch = useDispatch();
  const { setLoading } = useLoader();

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("toast-success");
  // Toast
  const [searchTerm,setSearchTerm] = useState("");
  const [openInquiryModal, setOpenInquiryModal] = useState(false);
  const [selectedInquiry, setSelectedInquiry] = useState(null);
  // Pagination
  const [
    limit,
    //  setLimit
  ] = useState({ value: 10, label: 10 });
  const [currentPage, setCurrentPage] = useState(1);

  const { data = [], total = 0 } = useSelector((state) => state.inquiry.inquiries);
  
  // Handler for Opening View Modal
  const handleOpenViewModal = (item) => {
    setSelectedInquiry(item);
    setOpenInquiryModal(true);
  };

  // Pagination Handler
  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber);
  };

  const searchTimeout = useRef(null);

  // Debounced Search Handler
  const handleSearch = useCallback(() => {
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    searchTimeout.current = setTimeout(() => {
      dispatch(getInquiries({ search: searchTerm, page: currentPage }));
    }, 600); // 300ms delay for better UX
  }, [searchTerm,currentPage, dispatch]);

  // Trigger API Call when search or page changes
  useEffect(() => {
    handleSearch();
    return () => clearTimeout(searchTimeout.current);
  }, [currentPage, handleSearch]);

  // Api Call for Search
  // useEffect(() => {
  //   const delayTimeout = setTimeout(() => {
  //     dispatch(getInquiries({ search: searchTerm }));
  //   }, 3000); // 3 second delay

  //   // Cleanup function to clear the timeout if searchTerm changes before the delay
  //   return () => clearTimeout(delayTimeout);
  // }, [searchTerm, dispatch]);

  const getAllInquiries = useCallback(() => {
    setLoading(true);
    dispatch(
      getInquiries({
        page: currentPage,
        limit: limit?.value,
      })
    )
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, limit?.value, setLoading, currentPage]);

  useEffect(() => {
    getAllInquiries();
  }, [getAllInquiries]);

    const handleExportInquiry = () => {
      try{
        setLoading(true);
        dispatch(getInquiries({ search: searchTerm,limit: 100000 })).then((res) => {
          const data = res?.payload?.data
          console.log("data",data);
          
          if (!data || data.length === 0){
            setSnackbarMessage("No data available to Export");
            setSnackbarVariant("toast-success");
            setShowSnackbar(true);
            setLoading(false);
            return;
          }

          // Map the data for export
          const dataToExport = data?.map((item) => {
            return {
              property_id: item?.property?.property_number_id || "-",
              name: item?.user_name || "-",
              mobile_number: item?.phone_number || "-",
              email: item?.email || "-",
              property_type: item?.property?.propertyType?.name || "-",
              possession_type: item?.property?.possessionType?.name || "-",
              message: item?.message || "-",
              inquiry_created_at: item?.created_at ? new Date(item.created_at).toLocaleString("en-IN", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  }) : "-",
            }
          })

          // Export to Excel
          const worksheet = XLSX.utils.json_to_sheet(dataToExport);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "NPA Inquiries");
          const fileName = "NPA Inquiries(Admin).xlsx";
          XLSX.writeFile(workbook, fileName);
          setLoading(false);
          dispatch(getInquiries({ search: searchTerm, limit: 10 }))
        })
      }catch(error){
        console.error("Error exporting data: ", error);
        setSnackbarMessage("Error exporting data: ", error);
        setSnackbarVariant("toast-success");
        setShowSnackbar(true);
        setLoading(false); // Disable loading state on error
      }  
  };

  return (
    <>
      {/* Toast Section */}
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{snackbarMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Container fluid className="px-0">
        <div className="grid gap-4">
          <DashboardCard
            totalUser={total}
            title="Total Inquiry"
            classNameMain="g-col-4 g-col-xxl-3"
            src={TotalInquiryIcon}
            alt="TotalInquiryIcon"
          />
        </div>

        {/* List Section */}
        <div className="bg-white rounded-2 overflow-hidden common-shadow mt-5">
          <InquiryList
            data={data}
            searchTerm={searchTerm}
            setCurrentPage={setCurrentPage}
            setSearchTerm={setSearchTerm}
            handleOpenViewModal={handleOpenViewModal}
            handleExportInquiry={handleExportInquiry}
          />

          {/* Pagination */}
          <div className="p-3 mx-1 d-flex flex-wrap align-items-center justify-content-between gap-4">
            <p className="custom-table-infotext ">
              Displaying {Math.min((currentPage - 1) * limit?.value + 1, total)}{" "}
              to {Math.min(currentPage * limit?.value, total)} of {total}
            </p>
            <div className="d-flex gap-4">
              {total > limit?.value && (
                <>
                  {/* <CreatableSelect
                  isClearable
                  options={recordsPerPageOptions}
                  value={limit}
                  onChange={handleChange}
                  placeholder="Select or create"
                  menuPortalTarget={document.body} // Render the menu at the body level
                  menuPlacement="top" // Display the options above the input
                  styles={{
                    control: (base) => ({
                      ...base,
                      width: "170px", // Fixed width for the input field
                    }),
                    menu: (base) => ({
                      ...base,
                      width: "170px", // Fixed width for the dropdown menu
                    }),
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999, // Ensure it appears above everything
                    }),
                  }}
           /> */}
                  <CommonPagination
                    totalItems={total}
                    itemsPerPage={limit?.value}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </Container>

      <ViewNpaInquiries
        show={openInquiryModal}
        selectedInquiry={selectedInquiry}
        onHide={() => setOpenInquiryModal(false)}
      />
    </>
  );
};

export default Index;
