import React, { useEffect, useRef, useState } from "react";
import SidebarItem from "./sidebar-item";
import { Link, useLocation } from "react-router-dom";
import { Image } from "react-bootstrap";
import {
  SidebarContent,
  ConfigurationContent,
  BankManageContent,
} from "./sidebar-content";
import SidebarLogo from "../../assets/images/sidebar-icon/mep-sidebar-logo.png";
import { ArrowRight02Icon2, Building03Icon, UserIcon } from "../../assets/icons";
import "./sidebar.css";
import { routes } from "../../routes/pageRoutes";

export default function Sidebar({ className }) {
  const [scrolled, setScrolled] = useState(false);
  const sidebarRef = useRef(null);

  const location = useLocation();

  const handleSidebarScroll = () => {
    const scrollPosition = sidebarRef.current.scrollTop;

    if (scrollPosition > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    const sidebar = sidebarRef.current;
    if (sidebar) {
      sidebar.addEventListener("scroll", handleSidebarScroll);
    }
    return () => {
      if (sidebar) {
        sidebar.removeEventListener("scroll", handleSidebarScroll);
      }
    };
  }, []);

  const isActive = (path) => {
    if (location.pathname.includes("/npa-properties/") && path === routes.home) {
      return true;
    }
    if (location.pathname.startsWith("/bank-details/") && path === routes.banks) {
      return true;
    }
    if (
      (location.pathname === "/product-add" ||
        location.pathname.startsWith("/product-edit/")) &&
      path === "/products"
    ) {
      return true;
    }

    if (
      (location.pathname === "/gallery-add" ||
        location.pathname.startsWith("/gallery-edit/")) &&
      path === "/galleries"
    ) {
      return true;
    }

    if (
      (location.pathname === "/testimonial-add" ||
        location.pathname.startsWith("/testimonial-edit/")) &&
      path === "/testimonials"
    ) {
      return true;
    }

    if (
      (location.pathname === "/team-add" ||
        location.pathname.startsWith("/team-edit/")) &&
      path === "/teams"
    ) {
      return true;
    }
    return location.pathname === path;
  };

  return (
    <>
      <aside className={`bg-white ${className} sidebar`}>
        <div className="h-100 position-relative d-flex flex-column">
          <div className="flex-shrik-1 position-relative mt-3 mb-2">
            <div
              className={`d-flex align-items-center position-sticky top-0 start-0 end-0 site-logo ${
                scrolled ? "effect-logo" : null
              }`}
            >
              <Link to={routes.home}>
                <h1>
                  <Image src={SidebarLogo} />
                </h1>
              </Link>
            </div>
          </div>
          <div className="py-1 flex-grow-1 overflow-y-auto" ref={sidebarRef}>
            <ul className="mb-0 list-unstyled">
              {SidebarContent.map((item, i) => {
                return (
                  <SidebarItem
                    key={i}
                    to={item.link}
                    icon={item.icon}
                    title={item.title}
                    className={isActive(item.link) === true ? "active" : null}
                  />
                );
              })}
            </ul>
            <ul className="my-0 list-unstyled">
              <SidebarItem
                to="#"
                icon={
                  <UserIcon
                    width={18}
                    height={18}
                    color="#444050"
                    strokeWidth="2"
                  />
                }
                dropdownIcon={
                  <ArrowRight02Icon2
                    width={18}
                    height={18}
                    color="#444050"
                    strokeWidth="2"
                  />
                }
                title="User Details"
                children={ConfigurationContent}
              />
              <SidebarItem
                to="#"
                icon={
                  <Building03Icon
                    width={18}
                    height={18}
                    color="#444050"
                    strokeWidth="2"
                  />
                }
                dropdownIcon={
                  <ArrowRight02Icon2
                    width={18}
                    height={18}
                    color="#444050"
                    strokeWidth="2"
                  />
                }
                title="Bank Manage"
                children={BankManageContent}
              />
            </ul>
          </div>
        </div>
      </aside>
    </>
  );
}
