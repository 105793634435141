import React from "react";
import {
  OverlayTrigger,
  //  ButtonGroup, Form, 
  Table, 
  Tooltip} from "react-bootstrap";
import StatusChip from "../../../components/statusChip";
import ExportImportImg from "../../../assets/images/table/export.png";
import { RequestedUserList } from "../../../components/TableHeadings";
import {CheckIcon,CrossIcon} from "../../../assets/icons";
import CommonButton from "../../../components/commonButton";
import Input from "../../../components/input";
import NoData from "../../../components/no-data";
import moment from "moment";
import { BankIcons } from "../../../components/common";
// import CommonActionDropDown from "../../../components/common-action-dropdown";

const List = ({ data,searchTerm,setCurrentPage,setSearchTerm,handlerRequestedUser,handleExportPendingUsers }) => {
  
  return (
    <>
        <div className="p-4 d-flex align-items-center justify-content-between gap-4">
          <Input
            type="search"
            value={searchTerm}
            placeholder="Search Requested User"
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setCurrentPage(1);
            }}
            className="shadow-none base-search"
          />

          <div className="action-buttons-container">
            <CommonButton
              icon={<span><img src={ExportImportImg} alt="import" /></span>}
              className="btn-primary-light me-2"
              title="Export"
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                handleExportPendingUsers();
              }}
            />
          </div>
        </div>

        <Table responsive className="common-table">
          <thead>
            <tr>
              {RequestedUserList?.map((column, index) => (
                <th key={index}>{column.label}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {data?.length === 0 ? (
              <NoData />
            ) : (
               data?.map((item, i) => ( 
                <tr 
                key={item?.id}
                >
                  <td>{item?.profile && item?.profile?.name ? item?.profile?.name : "-"}</td>
                  <td>{item?.mobile_number ? item?.mobile_number : '-'}</td>
                  <td>{item?.email ? item?.email : ""}</td>
                  <td>{item?.profile && item?.profile?.designation ? item?.profile?.designation : "-"}</td>
                  <td className="text-start">{item?.profile?.bank ? <>{BankIcons(item?.profile?.bank?.bank_name)}{" "} {item?.profile?.bank?.bank_name}</> : '-'}</td>
                  <td>{item?.profile?.branch ? item?.profile?.branch?.branch_city :"-"}</td>
                  {/* <td>{item?.created_at ? moment(item.created_at).format("DD-MM-YYYY hh:mm A") : ""}</td> */}
                  <td className="text-start">
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                      <span>{item?.created_at ? moment(item.created_at).format("DD MMM YYYY") : ""}</span>
                      <span>{item?.created_at ? moment(item.created_at).format("hh:mm A") : ""}</span>
                    </div>
                  </td>
                  <td className="text-capitalize">
                    {item?.status ? (
                      <StatusChip
                        pill
                        bg="chip-warning"
                        title={item?.status}
                        className="text-capitalize"
                      />
                    ) : (
                      ""
                    )}
                  </td>

                  <td className="text-center">
                    <div className="d-flex align-items-center cursor-pointer gap-2">
                    <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>Reject User</Tooltip>}>
                      <span onClick={() => handlerRequestedUser("rejected",item)}>
                        <CrossIcon
                          width={22}
                          height={22}
                          color="#9F2F48"
                          strokeWidth="1.5"
                        />
                      </span>
                      </OverlayTrigger>
                      <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>Accept User</Tooltip>}>
                      <span  onClick={() => handlerRequestedUser("approved",item)}>
                        <CheckIcon
                          width={22}
                          height={22}
                          color="#9F2F48"
                          strokeWidth="1.5"
                        />
                      </span>
                      </OverlayTrigger>
                    </div>
                  </td>
                </tr>
             
                  ))
            )} 
          </tbody>
        </Table>
    </>
  );
};

export default List;
