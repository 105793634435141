import React, { useState } from 'react';
import CommonFormLabel from '../../../components/formLabel';
import { Col, Form, Modal, Row, Toast, ToastContainer } from 'react-bootstrap';
import Input from '../../../components/input';
import { Cancel01Icon, CheckmarkBadge04Icon, EyeIcon, ViewOffSlashIcon } from '../../../assets/icons';
import CommonButton from '../../../components/commonButton';
import { Formik } from 'formik';
import { ResetPasswordSchema } from '../../../schema/validationSchemas';
import ValidationFeedback from '../../../components/validationFeedback';
import { useDispatch } from 'react-redux';
import { allUserList, reqToChangePassword } from '../../../redux/slice/userSlice';
import { useLoader } from "../../../context/LoaderContext";

const ChangePasswordModal = ({show,onHide,cardStatus,currentPage,limit,changePasswordId,setChangePasswordId}) => {
  const dispatch = useDispatch();
  const { setLoading } = useLoader();

  const [showEyeIcon, setShowEyeIcon] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastVariant, setToastVariant] = useState("toast-success");
  const [toastMessage, setToastMessage] = useState("");

  const initialEventState = {
    newPassword: "",
    confirmPassword: "",
  }

  const handleSubmit =  async (values,{setSubmitting}) => {
    const data = {
      userId:changePasswordId?.id,
      newPassword:values?.confirmPassword
    }
    try{
      setLoading(true);
      const response = await dispatch(reqToChangePassword({data})).unwrap();
      if (response?.success === true){
        setToastMessage(response?.message || "Password changed successfully");
        setToastVariant("toast-success");
        setShowToast(true);
        dispatch(allUserList({status: cardStatus,page: currentPage,limit: limit?.value})).then((res) => {
          setChangePasswordId("")
          setLoading(false);
        })
      }
    }catch(error){
      const errorMessage = error?.msg || "Failed to Add Properties!";
      setToastMessage(errorMessage);
      setToastVariant("toast-danger");
    }finally{
      setShowToast(true);
      setSubmitting(false);
      onHide()
    }}

  return (
    <>
    <ToastContainer position="top-end" className="p-3">
    <Toast
      onClose={() => setShowToast(false)}
      show={showToast}
      delay={5000}
      autohide
      bg={toastVariant}
      className="position-fixed top-3 end-0 me-3 shadow-none"
    >
      <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
        <CheckmarkBadge04Icon width={18} height={18} color="#28c76f" strokeWidth="2"/>
        <span className="text-capitalize">{toastMessage}</span>
      </Toast.Body>
    </Toast>
  </ToastContainer>
    <Modal
    show={show}
    centered
    dialogClassName="common-popup-dialog"
    contentClassName="common-popup-content"
    backdropClassName="common-popup-backdrop"
  >
    <Modal.Body className="common-popup-body">
      <div
        className="position-absolute end-0 z-2 bg-white rounded-1 cursor-pointer d-flex align-items-center justify-content-center common-popup-close-btn"
        onClick={onHide}
      >
        <Cancel01Icon width={16} height={16} color="#BCBBC1" strokeWidth="2.5"/>
      </div>
      <div className="text-center common-popup-heading" style={{paddingRight: "20px",paddingLeft: "20px"}}>
      <h2 className="text-center p-light mb-2">Change Password</h2>
      <Formik
      initialValues={initialEventState} 
       validationSchema={ResetPasswordSchema}
       onSubmit={handleSubmit}>
        {({errors, touched, isSubmitting, handleChange, values, handleSubmit}) => (
          <Form onSubmit={handleSubmit}>
            <Row className="text-start">
              <Col className="mt-4" md={12}>
                <CommonFormLabel title="New Password" />
                  <div className="position-relative">
                    <Input
                      id="newPassword"
                      name="newPassword"
                      type={showEyeIcon ? "password" : "text"}
                      placeholder="Enter your password"
                      value={values?.newPassword}
                      onChange={handleChange}
                      className={`shadow-none ${
                        touched.newPassword && errors.newPassword
                          ? "error" : "correct"}`}
                    />
                    <div className="position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer">
                      {showEyeIcon === false ? (
                        <ViewOffSlashIcon
                          width={20}
                          height={20}
                          color="#444050"
                          strokeWidth="1.5"
                          className={touched.newPassword && errors.newPassword? "error" : "correct"}
                          onClick={() => setShowEyeIcon(true)}
                        />
                        ) : (
                        <EyeIcon
                          width={20}
                          height={20}
                          color="#444050"
                          strokeWidth="1.5"
                          className={`${touched.newPassword && errors.newPassword ? "error" : "correct"}`}
                          onClick={() => setShowEyeIcon(false)}
                        />
                      )}
                    </div>
                  </div>
                  {touched.newPassword && errors.newPassword ? (
                  <ValidationFeedback title={errors.newPassword} />
                  ) : null}
              </Col>
              <Col  className="mt-4 mb-3" md={12}>
                <CommonFormLabel title="Confirm Password" />
                  <div className="position-relative">
                    <Input
                      id="confirmPassword"
                      name="confirmPassword"
                      type={showEyeIcon ? "password" : "text"}
                      placeholder="Enter your password"
                      value={values?.confirmPassword}
                      onChange={handleChange}
                      className={`shadow-none ${touched.confirmPassword && errors.confirmPassword ? "error" : "correct"}`}
                    />
                    <div className="position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer">
                      {showEyeIcon === false ? (
                        <ViewOffSlashIcon
                          width={20}
                          height={20}
                          color="#444050"
                          strokeWidth="1.5"
                          className={touched.confirmPassword && errors.confirmPassword ? "error" : "correct"}
                          onClick={() => setShowEyeIcon(true)}
                        />
                        ) : (
                        <EyeIcon
                          width={20}
                          height={20}
                          color="#444050"
                          strokeWidth="1.5"
                          className={`${touched.confirmPassword && errors.confirmPassword ? "error" : "correct"}`}
                          onClick={() => setShowEyeIcon(false)}
                        />
                      )}
                    </div>
                  </div>
                    {touched.confirmPassword && errors.confirmPassword ? (
                    <ValidationFeedback title={errors.confirmPassword} />
                    ) : null}
              </Col>
            </Row>
            <div className="text-center" style={{ width: "50%", margin: "0 auto" }}>
              <CommonButton title="Change Password" type="submit" disabled={isSubmitting} className="w-100 btn-primary primary-shadow my-4"/>
            </div>
          </Form>
        )}
      </Formik>
      </div>
    </Modal.Body>
    </Modal>
    </>
  )
}

export default ChangePasswordModal