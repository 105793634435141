import React, { useState, useEffect } from "react";
import CommonFormLabel from "../../components/formLabel";
import Input from "../../components/input";
import ValidationFeedback from "../../components/validationFeedback";
import CommonButton from "../../components/commonButton";
import { Container, Image, Form, Toast, ToastContainer } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../redux/slice/authSlice";
import { loginSchema } from "../../schema/validationSchemas";
import {ViewOffSlashIcon,EyeIcon,CheckmarkBadge04Icon} from "../../assets/icons";
import loginLogo from "../../assets/images/login/login-logo.png";
import "./login.css";
import { routes } from "../../routes/pageRoutes";
import useMounted from "../../hooks/useMounted";

export default function LogIn() {
  const [showToast, setShowToast] = useState(false);
  const [showEyeIcon, setShowEyeIcon] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("toast-success");
  const { status, error } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hasMounted = useMounted();

  console.log("authHeaders",process.env.REACT_APP_BASE_URL);

  

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const result = await dispatch(loginUser(values)).unwrap();
      if (result && result.token) {
        localStorage.setItem("auth-token", result.token);
        setToastMessage(result.message || "Logged in successfully!");
        setToastVariant("toast-success");
        setTimeout(() => {
          if (hasMounted) {
            navigate(routes.home, { replace: true });
          }
        }, 1000);
      } else {
        setToastMessage(result.message || "Failed to login!");
        setToastVariant("toast-danger");
      }
    } catch (err) {
      const errorMessage = err?.message || "Failed to login!";
      setToastMessage(errorMessage);
      setToastVariant("toast-danger");
    } finally {
      setShowToast(true);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (error) {
      setToastMessage(error);
      setToastVariant("toast-danger");
      setShowToast(true);
    }

    if (status === "loading") {
      setToastMessage("Logging in...");
      setToastVariant("toast-warning");
      setShowToast(true);
    }
  }, [status, error]);

  return (
    <>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={5000}
          autohide
          bg={toastVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{toastMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Container fluid="xxl" className="mx-auto px-4">
        <div className="d-flex align-items-center justify-content-center py-4  login-content">
          <div className="position-relative w-100 py-4 login-sub-content">
            <div className="bg-white rounded-2 p-5 common-shadow login-card">
              <h1 className="fs-5 d-flex align-content-center justify-content-center mb-4">
                <Link
                  // to="https://mep.in/"
                  className=" text-decoration-none  d-inline-block"
                >
                  <Image src={loginLogo} alt="Site-Logo" />
                </Link>
              </h1>
              <div className="mb-4 welcome-content">
                <h4 className="mb-1 fw-medium">Welcome to MyEstatePoint!</h4>
                <p>Login seamlessly with mobile number or email</p>
              </div>
              <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={loginSchema}
                onSubmit={handleSubmit}
              >
                {({
                  errors,
                  touched,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="mb-4">
                      <CommonFormLabel title="Email" />
                      <Input
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Enter your email"
                        onChange={handleChange}
                        className={`shadow-none ${
                          touched.email && errors.email ? "error" : "correct"
                        }`}
                      />
                      {touched.email && errors.email ? (
                        <ValidationFeedback title={errors.email} />
                      ) : null}
                    </div>
                    <div className="mb-4">
                      <CommonFormLabel title="Password" />
                      <div className="position-relative">
                        <Input
                          id="password"
                          name="password"
                          // type="password"
                          type={`${showEyeIcon ? "text" : "password"}`}
                          placeholder="············"
                          onChange={handleChange}
                          className={`shadow-none ${
                            touched.password && errors.password
                              ? "error"
                              : "correct"
                          }`}
                        />
                        <div className="position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer">
                          {showEyeIcon === false ? (
                            <ViewOffSlashIcon
                              width={20}
                              height={20}
                              color="#444050"
                              strokeWidth="1.5"
                              className={
                                touched.password && errors.password
                                  ? "error"
                                  : "correct"
                              }
                              onClick={() => setShowEyeIcon(true)}
                            />
                          ) : (
                            <EyeIcon
                              width={20}
                              height={20}
                              color="#444050"
                              strokeWidth="1.5"
                              className={`${
                                touched.password && errors.password
                                  ? "error"
                                  : "correct"
                              }`}
                              onClick={() => setShowEyeIcon(false)}
                            />
                          )}
                        </div>
                      </div>
                      {touched.password && errors.password ? (
                        <ValidationFeedback title={errors.password} />
                      ) : null}
                    </div>
                    {/* <div className="d-flex align-items-center mb-4">
                    <input type="checkbox" className="login-checkbox"/>
                    <CommonFormLabel title="Remember me" className="mt-1 checkbox-label"/>
                    {touched.email && errors.email ? (
                      <ValidationFeedback title={errors.email} />
                    ) : null}
                  </div> */}
                    <CommonButton
                      title="Login"
                      type="submit"
                      disabled={isSubmitting}
                      className="w-100 btn-primary primary-shadow"
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
