import React from "react";
import Input from "../../../components/input";
import CommonButton from "../../../components/commonButton";
import NoData from "../../../components/no-data";
import { Add01Icon} from "../../../assets/icons";
import { Table } from "react-bootstrap";
import { BanksList } from "../../../components/TableHeadings";
import { BankIcons } from "../../../components/common";
// import CommonPagination from "../../../components/commonPagination";

export default function List({ data,setCurrentPage,limit,total,handlePageChange,navigate,searchTerm,currentPage,setSearchTerm, setOpenAddbankModal}) {
  return (
    <>
      <div className="p-4 d-flex align-items-center justify-content-between gap-4">
        <Input
          type="search"
          value={searchTerm}
          placeholder="Search by Bank Name"
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setCurrentPage(1);
          }}
          className="shadow-none base-search"
        />
        <div className="action-buttons-container">
          <CommonButton
            onClick={() => setOpenAddbankModal(true)}
            icon={
              <Add01Icon
                height={14}
                width={14}
                color="#ffffff"
                strokeWidth="3"
              />
            }
            title="Add Bank"
            className="btn-primary"
            subClassName="gap-2"
          />
        </div>
      </div>
      <Table responsive className="common-table">
        <thead>
          <tr>
            {BanksList?.map((column, index) => (
              <th key={index} className="text-start text-uppercase">
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.length === 0 ? (
            <NoData />
          ) : (
            data?.map((item, i) => (
              <tr key={i} className="cursor-pointer"
              onClick={(e) => {
                e.stopPropagation()
                navigate(`/bank-details/${item?.id}`,{state:{currentPage:currentPage}})
              }}>
                <td className="d-flex align-items-center border-0 justify-content-start gap-2">
                  {item?.bank_name ? <>{BankIcons(item?.bank_name)}</> : '-'}
                  {item.bank_name}
                </td>
                <td className="text-start">{item.branch_count}</td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

        {/* Pagination */}
      {/* <div className="p-3 mx-1 d-flex flex-wrap align-items-center justify-content-between gap-4">
        <p className="custom-table-infotext ">
          Displaying {Math.min((currentPage - 1) * limit?.value + 1, total)}{" "}
          to {Math.min(currentPage * limit?.value, total)} of {total}
        </p>
        <div className="d-flex gap-4">
          {total > limit?.value && (
            <>
              <CommonPagination
                totalItems={total}
                itemsPerPage={limit?.value}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </>
          )}
        </div>
      </div> */}
    </>
  );
}
