import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders, formAuthHeaders } from "../../utils/axios";

export const reqToGetAllBank = createAsyncThunk("bank/reqToGetAllBank",async ({limit, page, search},{ rejectWithValue }) => {
    try {
      const response = await Axios.get(`/api/admin/bank/all?limit=${limit || 10}&page=${page || 1}&search=${search || ""}`,authHeaders());
      return response?.data; // Success case
    } catch (error) {
      return rejectWithValue(error.response.data); // Error case
    }
});

export const reqToGetBankDetails = createAsyncThunk("bank/reqToGetBankDetails",async ({id},{ rejectWithValue }) => {
  try{
   const response = await Axios.get(`/api/admin/bank/${id}`,authHeaders());
   return response.data; // Success case
  }catch(error){
    return rejectWithValue(error.response.data); // Error case
  }
});

export const reqToGetBankBranchDetails = createAsyncThunk("bank/reqToGetBankBranchDetails",async ({id,search,limit,page},{ rejectWithValue }) => {
  try{
   const response = await Axios.get(`/api/admin/branch?bank_id=${id}&search=${search || ""}&limit=${limit || 10}&page=${page || 1}`,authHeaders());
   return response.data; // Success case
  }catch(error){
    return rejectWithValue(error.response.data); // Error case
  }
});

export const reqToAddBranch = createAsyncThunk("bank/reqToAddBranch",async ({data}, { rejectWithValue }) => {
  try {
    const response = await Axios.post(`/api/admin/branch/add`,data,authHeaders());
    return response.data; // Success case
  } catch (error) {
    return rejectWithValue(error.response.data); // Error case
  }
});

export const reqToAddBank = createAsyncThunk("bank/reqToAddBank",async ({data}, { rejectWithValue }) => {
  try {
    const response = await Axios.post(`/api/admin/bank/add`,data,formAuthHeaders());
    return response.data; // Success case
  } catch (error) {
    return rejectWithValue(error.response.data); // Error case
  }
});


const bankSlice = createSlice({
    name: "bank",
    initialState:{
        error: null,
        isLoading: false,
        banks:[],
        bankDetails:{},
        bankBranchDetails:[],
    },
    reducer:{},
    extraReducers: (builder) => {
    builder
    .addCase(reqToGetAllBank.fulfilled, (state, action) => {
        state.isLoading = false;
        state.banks = action.payload;
    })
    .addCase(reqToGetBankDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.bankDetails = action.payload;
    })
    .addCase(reqToGetBankBranchDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.bankBranchDetails = action.payload;
    })
    }
})

export default bankSlice.reducer;