import React, { useCallback, useEffect, useState } from "react";
import { Container, Toast, ToastContainer } from "react-bootstrap";
import List from "./List";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import {
  approveOrRejectUser,
  requestedUser,
} from "../../../redux/slice/userSlice";
import { CheckmarkBadge04Icon } from "../../../assets/icons";
import { useLoader } from "../../../context/LoaderContext";
import DashboardCard from "../../../components/dashboard-card";
import RequestedUserCardImg from "../../../assets/images/npa-properties/requested-user.png";
import CommonPagination from "../../../components/commonPagination";

const RequestedUser = () => {
  const dispatch = useDispatch();
  const { setLoading } = useLoader();

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("toast-success");
  // Search Bar
  const [searchTerm, setSearchTerm] = useState("");

  // const [openDropdown, setOpenDropdown] = useState(null);
  // Pagination
  const [
    limit,
    // setLimit
  ] = useState({ value: 10, label: 10 });
  const [currentPage, setCurrentPage] = useState(1);

  const { totalUsers = 0, users = [] } = useSelector(
    (state) => state?.user?.requested?.users || {}
  );

  // Pagination Handler
  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber);
  };

  // Handler for accepting or rejecting the requested user
  const handlerRequestedUser = (type, item) => {
    try {
      const id = item?.id;
      if (type && id) {
        setLoading(true);
        const data = { status: type };
        dispatch(approveOrRejectUser({ data, id })).then((res) => {
          setToastMessage(
            res?.payload?.message || "User status updated successfully!"
          );
          if (res?.payload?.user) {
            dispatch(
              requestedUser({
                status: "pending",
                page: currentPage,
                limit: limit?.value,
              })
            );
          }
        });
      } else {
        setLoading(false);
        setToastMessage("User status or Id Missing!");
        setToastVariant("toast-danger");
      }
    } catch (err) {
      setLoading(false);
      const errorMessage = err?.message || "Failed to update user status!";
      setToastMessage(errorMessage);
      setToastVariant("toast-danger");
    } finally {
      setLoading(false);
      setShowToast(true);
    }
  };

  // Api Call for Search
  useEffect(() => {
    const delayTimeout = setTimeout(() => {
      dispatch(requestedUser({ status: "pending", search: searchTerm, page: currentPage }));
    }, 2000); // 3 second delay

    return () => clearTimeout(delayTimeout);
  }, [searchTerm,currentPage, dispatch]);

  // API Call to get all requested user
  const getAllRequestedUser = useCallback(() => {
    setLoading(true);
    dispatch(
      requestedUser({
        status: "pending",
        page: currentPage,
        limit: limit?.value,
      })
    )
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        // setLoading(false);
      });
  }, [dispatch, limit?.value, currentPage, setLoading]);

  useEffect(() => {
    getAllRequestedUser();
  }, [getAllRequestedUser]);

  // Handler for Exporting All Users
  const handleExportPendingUsers = () => {
    try {
      setLoading(true);
      dispatch(requestedUser({ status: "pending", search: searchTerm,  limit: 100000 })).then(
        (res) => {
          const data = res?.payload?.users?.users;
          if (!data || data.length === 0) {
            setToastMessage("No data available to Export");
            setToastVariant("toast-success");
            setShowToast(true);
            setLoading(false);
            return;
          }

          // Map the data for export
          const dataToExport = data?.map((item) => {
            return {
              Name: item?.profile?.name || "N/A",
              MobileNo: item?.mobile_number || "N/A",
              Email: item?.email || "N/A",
              Designation: item?.profile?.designation || "N/A",
              BankName: item?.profile?.bank?.bank_name || "N/A",
              BranchCity: item?.profile?.branch?.branch_name || "N/A",
              Status: item?.status || "N/A",
              CreatedAt: item?.created_at
                ? new Date(item.created_at).toLocaleString("en-IN", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })
                : "-",
              UpdatedAt: item?.updated_at
                ? new Date(item.updated_at).toLocaleString("en-IN", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })
                : "-",
            };
          });

          // Export to Excel
          const worksheet = XLSX.utils.json_to_sheet(dataToExport);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Requested Users");
          const fileName = "Requested Users(Admin).xlsx";
          XLSX.writeFile(workbook, fileName);
          setLoading(false);
          dispatch(requestedUser({ search: searchTerm, status: "pending", limit: limit?.value, page: currentPage }));
        }
      );
    } catch (error) {
      console.error("Error exporting data: ", error);
      setToastMessage("Error exporting data: ", error);
      setToastVariant("toast-success");
      setShowToast(true);
      setLoading(false); // Disable loading state on error
    }
  };

  return (
    <>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={5000}
          autohide
          bg={toastVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{toastMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Container fluid className="px-0">
        {/* Card Section */}
        <div className="grid gap-4">
          <DashboardCard
            totalUser={totalUsers}
            title="Requested User"
            classNameMain="g-col-4 g-col-xxl-3"
            src={RequestedUserCardImg}
          />
        </div>

        <div className="bg-white rounded-2 overflow-hidden common-shadow mt-5">
          {/* List Section */}
          <List
            data={users}
            searchTerm={searchTerm}
            setCurrentPage={setCurrentPage}
            setSearchTerm={setSearchTerm}
            handlerRequestedUser={handlerRequestedUser}
            handleExportPendingUsers={handleExportPendingUsers}
          />

          {/* Pagination */}
          <div className="p-3 mx-1 d-flex flex-wrap align-items-center justify-content-between gap-4">
            <p className="custom-table-infotext ">
              Displaying{" "}
              {Math.min((currentPage - 1) * limit?.value + 1, totalUsers)} to{" "}
              {Math.min(currentPage * limit?.value, totalUsers)} of {totalUsers}
            </p>
            <div className="d-flex gap-4">
              {totalUsers > limit?.value ? (
                <>
                  {/* <CreatableSelect
                    isClearable
                    options={recordsPerPageOptions}
                    value={limit}
                    onChange={handleChange}
                    placeholder="Select or create"
                    menuPortalTarget={document.body} // Render the menu at the body level
                    menuPlacement="top" // Display the options above the input
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "170px", // Fixed width for the input field
                      }),
                      menu: (base) => ({
                        ...base,
                        width: "170px", // Fixed width for the dropdown menu
                      }),
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999, // Ensure it appears above everything
                      }),
                    }}
                  /> */}

                  <CommonPagination
                    totalItems={totalUsers}
                    itemsPerPage={limit?.value}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                  />
                </>
              ) : null}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default RequestedUser;
