import React from "react";
import { Outlet } from 'react-router-dom';
// import Sidebar from "./sidebar";
// import Header from "./Header";
import NavBar from "../components/navbar";
import Sidebar from "../components/sidebar";
import { Container } from "react-bootstrap";


export default function DashboardLayout() {
    // const [showMenu, setShowMenu] = useState(true);
  
    // const ToggleMenu = () => {
    //   return setShowMenu(!showMenu);
    // };

  
    return (
      <div className="h-100 position-relative main-content">
        <Sidebar className="position-fixed start-0 top-0 bottom-0" />
  
        <div className="position-relative pg-content">
          <Container className="px-4 mx-auto pg-container">
            <NavBar />
            <main className="py-4 mt-3"><Outlet /></main>
          </Container>
        </div>
      </div>
    );
  }