import moment from 'moment';
import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
    email: Yup.string().min(1, 'Minimum email length is 1').max(190, 'Maximum email length is 190').required('Email is required'),
    password: Yup.string().min(8, 'Minimum password length is 8').max(30, 'Maximum password length is 30').required('Password is required'),
});

export const addUserSchema = Yup.object().shape({
  name: Yup.string().required("Name is required").min(3, "Minimum name length is 3"),
  designation: Yup.string().required("Name is required").min(3, "Minimum name length is 3"),
  phone: Yup.string().matches(/^[0-9]{10}$/, 'Invalid mobile number').required('Mobile number is required'),
  email: Yup.string().min(1, "Minimum email length is 1").max(190, "Maximum email length is 190").required("Email is required"),
  bank_id: Yup.string().required("Bank is required"),
  branch_id: Yup.string().required("Branch is required"),
  state: Yup.string().required("State is required"),
  city: Yup.string().required("City is required"),
  password: Yup.string().min(8, "Minimum password length is 8").max(30, "Maximum password length is 30").required("Password is required"),
});

export const addBranchSchema = Yup.object().shape({
  branch_name: Yup.string().required("Branch Name is required"),
  branch_city: Yup.string().required("Branch City is required"),
})

export const addBankSchema = Yup.object().shape({
  bank_name: Yup.string().required("Bank Name is required"),
  logo: Yup.mixed()
  .required("Bank logo is required") // Ensures the file is selected
  .test(
    "fileSize",
    "File size should be less than 5MB",
    (value) => value && value.size <= 5 * 1024 * 1024 // 5MB
  )
  .test(
    "fileType",
    "Only JPG, PNG, and GIF files are allowed",
    (value) =>
      value && ["image/jpeg", "image/png", "image/gif"].includes(value.type)
  ),
})

export const registerSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').min(3, 'Minimum name length is 3'),
    mobile_number: Yup.string().required('Mobile number is required'),
    email: Yup.string().min(1, 'Minimum email length is 1').max(190, 'Maximum email length is 190').required('Email is required'),
    bank_id: Yup.string().required('Bank is required'),
    city_id: Yup.string().required('City is required'),
    branch_name: Yup.string().required('Branch is required'),
    password: Yup.string().min(8, 'Minimum password length is 8').max(30, 'Maximum password length is 30').required('Password is required'),
    password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirm password is required'),
});

export const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().min(1, 'Minimum email length is 1').max(190, 'Maximum email length is 190').required('Email is required'),
});

export const ResetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, 'Minimum password length is 8')
    .max(30, 'Maximum password length is 30')
    .required('New password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

export const passwordSchema = Yup.object().shape({
  current_password: Yup.string().required('Current password is required'),
  new_password: Yup.string()
    .min(8, 'Minimum password length is 8')
    .max(30, 'Maximum password length is 30')
    .required('New password is required'),
  new_password_confirmation: Yup.string()
    .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

export const addPropertySchema = Yup.object().shape({
  // bank_id: Yup.string().required("Bank is required"),
  // branch_id: Yup.string().required("Branch is required"),
  assigned_to:Yup.string().required("User Selection is required"),
  asset_category: Yup.string().required("Category is required"),
  property_type: Yup.string().required("Property is required"),
  property_address: Yup.string().required("Address is required"),
  taluka: Yup.string().required("Taluka is required"),
  // city: Yup.string().required("City is required"),
  // state: Yup.string().required("State is required"),
  province_state: Yup.string().required("State is required"),
  city_town: Yup.string().required("City is required"),
  area_locality: Yup.string().required("Category is required"),
  // status:Yup.string().required("Status is required"),
  land_area: Yup.string()
    .matches(/^\d+(\.\d+)?$/, "Land area must be a valid number")
    .required("Land area is required"),
  builtup_carpet_area: Yup.string()
    .matches(/^\d+(\.\d+)?$/, "carpet area must be a valid number")
    .required("carpet area is required"),
  borrower_names: Yup.string().required("Borrower Names is required"),
  possession_type: Yup.string().required("Possession Type is required"),
  description:Yup.string().required("Description is required"),
  country:Yup.string().required("Country is required"),
  reserve_price: Yup.string()
    .matches(/^\d+(\.\d+)?$/, "Reserve price must be a valid number")
    .required("Reserve price is required"),
  emd: Yup.string()
    .matches(/^\d+(\.\d+)?$/, "Emd must be a valid number")
    .required("Emd is required"),
  min_increment: Yup.string()
    .matches(/^\d+(\.\d+)?$/, "Minimum Increment must be a valid number")
    .required("Minimum Increment is required"),
    contact_number: Yup.string()
    .matches(/^\d+(\.\d+)?$/, "Contact Number must be a valid number")
    .required("Contact Number is required"),
  amount_outstanding: Yup.string()
    .matches(/^\d+(\.\d+)?$/, "Amount outstanding must be a valid number")
    .required("Amount outstanding is required"),
  dsc_required: Yup.boolean().required("This field is required"),
  contact_name:Yup.string().required("Contact Name is required"),
  auction_type: Yup.string().required("Auction Type is required"),
  auction_start_time: Yup.string().required(
    "Auction Start date & time is required"
  ),
  auction_end_time: Yup.string()
    .required("Auction End date & time is required")
    .test(
      "is-after-start",
      "Auction End date & time must be after Auction Start date & time",
      function (value) {
        const { auction_start_time } = this.parent; // Access sibling fields
        if (!value || !auction_start_time) return true; // Skip if either field is empty
        return moment(value).isAfter(moment(auction_start_time)); // Ensure end time is after start time
      }
    ),
  // auction_end_time: Yup.string().required('Auction End date & time is required'),
  time_extension: Yup.string().required("Type Extension is required"),
  application_submission_date: Yup.string().required('Application Submission date & time is required'),
  // application_submission_date: Yup.string()
  //   .required("Application Submission date & time is required")
  //   .test(
  //     "is-between-auction-times",
  //     "Application Submission date & time must be between Auction Start and Auction End times, and not the same as either",
  //     function (value) {
  //       const { auction_start_time, auction_end_time } = this.parent; // Access sibling fields
  //       if (!value || !auction_start_time || !auction_end_time) return true; // Skip if any field is empty
  //       const submissionTime = moment(value);
  //       return (
  //         submissionTime.isAfter(moment(auction_start_time)) && // After start
  //         submissionTime.isBefore(moment(auction_end_time)) && // Before end
  //         !submissionTime.isSame(moment(auction_start_time)) && // Not same as start
  //         !submissionTime.isSame(moment(auction_end_time)) // Not same as end
  //       );
  //     }
  //   ),
  property_inspection_datetime: Yup.string().required("Property Inspection date & time is required"),
  // property_inspection_datetime: Yup.string()
  // .required("Property Inspection date & time is required")
  // .test(
  //   "is-between-auction-times",
  //   "Property Inspection date & time must be between Auction Start and Auction End times, and not the same as either",
  //   function (value) {
  //     const { auction_start_time, auction_end_time } = this.parent; // Access sibling fields
  //     if (!value || !auction_start_time || !auction_end_time) return true; // Skip if any field is empty

  //     const inspectionTime = moment(value);
  //     const auctionStartTime = moment(auction_start_time);
  //     const auctionEndTime = moment(auction_end_time);

  //     return (
  //       inspectionTime.isAfter(auctionStartTime) && // After start
  //       inspectionTime.isBefore(auctionEndTime) && // Before end
  //       !inspectionTime.isSame(auctionStartTime) && // Not the same as start
  //       !inspectionTime.isSame(auctionEndTime) // Not the same as end
  //     );
  //   }
  // ),
  service_provider: Yup.string().required("Service Provider is required"),
  // property_image: Yup.mixed().required("Image is required")
  //   .test(
  //     "fileType",
  //     "Only image files (png, jpg, jpeg) are allowed",
  //     (value) =>
  //       value && ["image/png", "image/jpeg", "image/jpg"].includes(value.type)
  //   )
  //   .test(
  //     "fileSize",
  //     "File size should be less than 3MB",
  //     (value) => value && value.size <= 3 * 1024 * 1024
  //   ),
  // property_document: Yup.mixed()
  //   .required("PDF file is required")
  //   .test("fileType", "Only PDF files are allowed", (value) => {
  //     return value && value.type === "application/pdf"; // Check if the file is a PDF
  //   })
  //   .test("fileSize", "File size must be less than 5 MB", (value) => {
  //     return value && value.size <= 5 * 1024 * 1024; // Check if file size is <= 5 MB
  //   }),
  property_document: Yup.mixed()
  .required("PDF file is required")
  .test("fileType", "Only PDF files are allowed", (value) => {
    // Skip validation for existing file paths (string values)
    if (typeof value === "string") {
      return true;
    }
    // Validate only for new file uploads
    return value && value.type === "application/pdf";
  })
  .test("fileSize", "File size must be less than 5 MB", (value) => {
    // Skip validation for existing file paths (string values)
    if (typeof value === "string") {
      return true;
    }
    // Validate only for new file uploads
    return value && value.size <= 5 * 1024 * 1024;
  }),
});

// MEP SCHEMA ENDS

// const SUPPORTED_FORMATS = ['image/jpeg', 'image/png', 'image/gif']; // Allowed image types
// const MAX_FILE_SIZE = 1024 * 1024; // 1MB in bytes

export const categorySchema = Yup.object().shape({
  category_name: Yup.string().required('Name is required'),
  page_title: Yup.string().required('Page title is required'),
  page_sub_title: Yup.string().required('Page Sub title is required'),
  category_video_link: Yup.string().required('Video link is required'),
  category_image: Yup.mixed().required("Image is required"),
  image_alt: Yup.string().required('Image alt is required'),
  category_banner_image: Yup.mixed().required("Banner Image is required"),
  category_description: Yup.string().required('Description is required'),
  meta_title: Yup.string().required('Meta title is required'),
  meta_description: Yup.string().required('Meta description is required'),
  meta_keyword: Yup.string().required('Meta keyword is required'),
  // default_category_description: Yup.string().required('Default description is required'),

  // category_image: Yup.mixed()
  //   .required('Image is required')
  //   .test(
  //     'fileSize',
  //     'File too large. Maximum size is 1MB',
  //     (value) => value && value.size <= MAX_FILE_SIZE
  //   )
  //   .test(
  //     'fileFormat',
  //     'Unsupported file format. Only JPEG, PNG, and GIF are allowed',
  //     (value) => value && SUPPORTED_FORMATS.includes(value.type)
  //   )
  //   .test('fileDimensions', 'Image must be 600x400 pixels', (file) => {
  //     if (!file) return false; // If no file, validation fails
  //     return new Promise((resolve) => {
  //       const img = new Image();
  //       img.src = URL.createObjectURL(file);
  //       img.onload = () => {
  //         const { width, height } = img;
  //         URL.revokeObjectURL(img.src); // Clean up URL
  //         resolve(width === 600 && height === 400); // Validate dimensions
  //       };
  //     });
  //   }),
});

export const categoryUpdateSchema = Yup.object().shape({
  category_name: Yup.string().required('Name is required'),
  page_title: Yup.string().required('Page title is required'),
  page_sub_title: Yup.string().required('Page Sub title is required'),
  image_alt: Yup.string().required('Image alt is required'),
  category_video_link: Yup.string().required('Video link is required'),
  // category_image: Yup.mixed().required("Image is required"),
  // category_banner_image: Yup.mixed().required("Banner Image is required"),
  category_description: Yup.string().required('Description is required'),
  meta_title: Yup.string().required('Meta title is required'),
  meta_description: Yup.string().required('Meta description is required'),
  meta_keyword: Yup.string().required('Meta keyword is required'),
  // default_category_description: Yup.string().required('Default description is required'),
});

export const subCategorySchema = Yup.object().shape({
  sub_category_name: Yup.string().required('Name is required'),
  sub_category_video_link: Yup.string().required('Video link is required'),
  sub_category_image: Yup.mixed().required("Image is required"),
  sub_category_banner_image: Yup.mixed().required("Banner Image is required"),
  sub_category_description: Yup.string().required('Description is required'),
  meta_title: Yup.string().required('Meta title is required'),
  meta_description: Yup.string().required('Meta description is required'),
  meta_keyword: Yup.string().required('Meta keyword is required'),
  default_sub_category_description: Yup.string().required('Default description is required'),
  default_meta_title: Yup.string().required('Default meta title is required'),
  default_meta_description: Yup.string().required('Default meta description is required'),
  default_meta_keyword: Yup.string().required('Default meta keyword is required'),
});

export const subCategoryUpdateSchema = Yup.object().shape({
  sub_category_name: Yup.string().required('Name is required'),
  sub_category_video_link: Yup.string().required('Video link is required'),
  // sub_category_image: Yup.mixed().required("Image is required"),
  // sub_category_banner_image: Yup.mixed().required("Banner Image is required"),
  sub_category_description: Yup.string().required('description is required'),
  meta_title: Yup.string().required('Meta title is required'),
  meta_description: Yup.string().required('Meta description is required'),
  meta_keyword: Yup.string().required('Meta keyword is required'),
  default_sub_category_description: Yup.string().required('Default description is required'),
  default_meta_title: Yup.string().required('Default meta title is required'),
  default_meta_description: Yup.string().required('Default meta description is required'),
  default_meta_keyword: Yup.string().required('Default meta keyword is required'),
});

export const blogSchema = Yup.object().shape({
  blog_title: Yup.string().required('Title is required'),
  author: Yup.string().required('Author is required'),
  blog_image: Yup.mixed().required("Image is required"),
  blog_description: Yup.string().required('Description is required'),
});

export const blogUpdateSchema = Yup.object().shape({
  blog_title: Yup.string().required('Title is required'),
  author: Yup.string().required('Author is required'),
  blog_description: Yup.string().required('Description is required'),
});

export const gallerySchema = Yup.object().shape({
  gallery_title: Yup.string().required('Title is required'),
  gallery_image: Yup.mixed().required("Image is required"),
});

export const galleryUpdateSchema = Yup.object().shape({
  gallery_title: Yup.string().required('Title is required'),
});

export const testimonialSchema = Yup.object().shape({
  client_name: Yup.string().required('Name is required'),
  rating:  Yup.number().required('Rating is required'),
  client_address: Yup.string().required('Address is required'),
  client_message: Yup.string().required('Message is required'),
  client_image: Yup.mixed().required("Image is required"),
});

export const testimonialUpdateSchema = Yup.object().shape({
  client_name: Yup.string().required('Name is required'),
  rating:  Yup.number().required('Rating is required'),
  client_address: Yup.string().required('Address is required'),
  client_message: Yup.string().required('Message is required'),
  // client_image: Yup.mixed().required("Image is required"),
});

export const settingsSchema = Yup.object().shape({
  customer_care_no: Yup.string().min(1, 'Minimum Customer Care No length is 1').max(15, 'Maximum Customer Care No length is 15').required('Customer Care No is required'),
  company_name: Yup.string().min(1, 'Minimum Company Name length is 1').max(50, 'Maximum Company Name length is 50').required('Company Name is required'),
  ceo_name: Yup.string().min(1, 'Minimum CEO Name length is 1').max(50, 'Maximum CEO Name length is 50').required('CEO Name is required'),
  description: Yup.string().min(5, 'Minimum Description length is 5').max(500, 'Maximum Description length is 500').required('Description is required'),
  website_email: Yup.string().min(1, 'Minimum email length is 1').max(190, 'Maximum email length is 190').required('Website email is required'),
  address: Yup.string().min(5, 'Minimum Address length is 5').max(300, 'Maximum Address length is 300').required('Company Address is required'),
  facebook_link: Yup.string().min(1, 'Minimum Facebook Link length is 1').max(100, 'Maximum Facebook Link length is 100').required('Facebook Link is required'),
  instagram_link: Yup.string().min(1, 'Minimum Instagram Link length is 1').max(100, 'Maximum Instagram Link length is 100').required('Instagram Link is required'),
  twitter_link: Yup.string().min(1, 'Minimum Twitter Link length is 1').max(100, 'Maximum Twitter Link length is 100').required('Twitter Link is required'),
  pinterest_link: Yup.string().min(1, 'Minimum Pinterest Link length is 1').max(100, 'Maximum Pinterest Link length is 100').required('Pinterest Link is required'),
  linkedin_link: Yup.string().min(1, 'Minimum Linkedin Link length is 1').max(100, 'Maximum Linkedin Link length is 100').required('Linkedin Link is required'),
  youtube_link: Yup.string().min(1, 'Minimum Youtube Link length is 1').max(100, 'Maximum Youtube Link length is 100').required('Youtube Link is required'),
});

export const socialLinksSchema = Yup.object().shape({
  facebook_link: Yup.string().min(1, 'Minimum Facebook Link length is 1').max(100, 'Maximum Facebook Link length is 100').required('Facebook Link is required'),
  instagram_link: Yup.string().min(1, 'Minimum Instagram Link length is 1').max(100, 'Maximum Instagram Link length is 100').required('Instagram Link is required'),
  twitter_link: Yup.string().min(1, 'Minimum Twitter Link length is 1').max(100, 'Maximum Twitter Link length is 100').required('Twitter Link is required'),
  pinterest_link: Yup.string().min(1, 'Minimum Pinterest Link length is 1').max(100, 'Maximum Pinterest Link length is 100').required('Pinterest Link is required'),
  linkedin_link: Yup.string().min(1, 'Minimum Linkedin Link length is 1').max(100, 'Maximum Linkedin Link length is 100').required('Linkedin Link is required'),
  youtube_link: Yup.string().min(1, 'Minimum Youtube Link length is 1').max(100, 'Maximum Youtube Link length is 100').required('Youtube Link is required'),
});

export const basicInformationSchema = Yup.object().shape({
  alternate_email: Yup.string().min(1, 'Minimum email length is 1').max(190, 'Maximum email length is 190').required('Email is required'),
  phone_number: Yup.string().min(1, 'Minimum Phone Number length is 1').max(15, 'Maximum Phone Number length is 15').required('Phone Number is required'),
  alternate_phone_number: Yup.string().min(1, 'Alternate Phone Number No length is 1').max(15, 'Maximum Alternate Phone Number length is 15').required('Alternate Phone Number is required'),
  whatsapp_number: Yup.string().min(1, 'Minimum Whatsapp Number No length is 1').max(15, 'Maximum Whatsapp Number No length is 15').required('Whatsapp Number is required'),
  address: Yup.string().min(5, 'Minimum Address length is 5').max(300, 'Maximum Address length is 300').required('Address is required'),
});

export const companyInformationSchema = Yup.object().shape({
  customer_care_no: Yup.string().min(1, 'Minimum Customer Care No length is 1').max(15, 'Maximum Customer Care No length is 15').required('Customer Care No is required'),
  company_name: Yup.string().min(1, 'Minimum Company Name length is 1').max(50, 'Maximum Company Name length is 50').required('Company Name is required'),
  ceo_name: Yup.string().min(1, 'Minimum CEO Name length is 1').max(50, 'Maximum CEO Name length is 50').required('CEO Name is required'),
  description: Yup.string().min(5, 'Minimum Description length is 5').max(500, 'Maximum Description length is 500').required('Description is required'),
  company_address: Yup.string().min(5, 'Minimum Address length is 5').max(300, 'Maximum Address length is 300').required('Company Address is required'),
  pin_code: Yup.string().required('Pin Code is required'),
  map_location_1: Yup.string().min(10, 'Minimum Address length is 10').required('Location 1 is required'),
  map_location_2: Yup.string().min(10, 'Minimum Address length is 10').required('Location 2 is required'),
});

export const websiteConfigurationSchema = Yup.object().shape({
  customer_care_no: Yup.string().min(1, 'Minimum Customer Care No length is 1').max(15, 'Maximum Customer Care No length is 15').required('Customer Care No is required'),
  website_email: Yup.string().min(1, 'Minimum email length is 1').max(190, 'Maximum email length is 190').required('Website email is required'),
});

export const storeProductSchema = Yup.object().shape({
  category_id: Yup.string().required('Category id is required'),
  sub_category_id: Yup.string().required('Sub Category id is required'),
  product_name: Yup.string().min(1, 'Minimum Product name length is 1').max(100, 'Maximum Product name length is 100').required('Product name is required'),
  product_code: Yup.string().min(1, 'Minimum Product code length is 1').max(15, 'Maximum Product code length is 50').required('Product code is required'),
  price: Yup.number().min(1, 'Minimum Product price length is 1').max(1000000, 'Maximum Product price length is 1000000').required('Product price is required'),
  selling_price: Yup.number().min(1, 'Minimum Product selling price length is 1').max(1000000, 'Maximum Product selling price length is 1000000').required('Product selling price is required'),
  description: Yup.string().min(1, 'Minimum Product description length is 1').max(190, 'Maximum Product description length is 190').required('Product description is required'),
  quantity: Yup.number().min(1, 'Minimum Product quantity length is 1').max(1000000, 'Maximum Product quantity length is 1000000').required('Product quantity is required'),
  min_order_quantity: Yup.number().min(1, 'Minimum Product order quantity length is 1').max(1000000, 'Maximum Product order quantity length is 1000000').required('Product order quantity is required'),
  meta_title: Yup.string().min(1, 'Minimum Meta title length is 1').max(190, 'Maximum Meta title length is 190').required('Meta title is required'),
  meta_description: Yup.string().min(1, 'Minimum Meta description length is 1').max(190, 'Maximum Meta description length is 190').required('Meta description is required'),
  meta_keyword: Yup.string().min(1, 'Minimum Meta keyword length is 1').max(190, 'Maximum Meta keyword length is 190').required('Meta keyword is required'),
  // product_images: Yup.array().min(1, 'Product Images is required').required('Product Images is required'),
  // product_attributes: Yup.array().min(1, 'Product Attribute is required')
});

export const updateProductSchema = Yup.object().shape({
  category_id: Yup.string().required('Category id is required'),
  sub_category_id: Yup.string().required('Sub Category id is required'),
  product_name: Yup.string().min(1, 'Minimum Product name length is 1').max(100, 'Maximum Product name length is 100').required('Product name is required'),
  product_code: Yup.string().min(1, 'Minimum Product code length is 1').max(15, 'Maximum Product code length is 50').required('Product code is required'),
  price: Yup.number().min(1, 'Minimum Product price length is 1').max(1000000, 'Maximum Product price length is 1000000').required('Product price is required'),
  selling_price: Yup.number().min(1, 'Minimum Product selling price length is 1').max(1000000, 'Maximum Product selling price length is 1000000').required('Product selling price is required'),
  description: Yup.string().min(1, 'Minimum Product description length is 1').max(190, 'Maximum Product description length is 190').required('Product description is required'),
  quantity: Yup.number().min(1, 'Minimum Product quantity length is 1').max(1000000, 'Maximum Product quantity length is 1000000').required('Product quantity is required'),
  min_order_quantity: Yup.number().min(1, 'Minimum Product order quantity length is 1').max(1000000, 'Maximum Product order quantity length is 1000000').required('Product order quantity is required'),
  meta_title: Yup.string().min(1, 'Minimum Meta title length is 1').max(190, 'Maximum Meta title length is 190').required('Meta title is required'),
  meta_description: Yup.string().min(1, 'Minimum Meta description length is 1').max(190, 'Maximum Meta description length is 190').required('Meta description is required'),
  meta_keyword: Yup.string().min(1, 'Minimum Meta keyword length is 1').max(190, 'Maximum Meta keyword length is 190').required('Meta keyword is required'),
});


export const teamSchema = Yup.object().shape({
  member_name: Yup.string().required('Name is required'),
  member_position: Yup.string().required('Position is required'),
  member_image: Yup.mixed().required("Image is required"),
});

export const teamUpdateSchema = Yup.object().shape({
  member_name: Yup.string().required('Name is required'),
  member_position: Yup.string().required('Position is required'),
  // member_image: Yup.mixed().required("Image is required"),
});