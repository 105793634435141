import React, { useCallback, useEffect, useRef, useState } from "react";
import DashboardCard from "../../../components/dashboard-card";
import AllUserImg from "../../../assets/images/npa-properties/all-users.png";
import RequestedUser from "../../../assets/images/npa-properties/requested-user.png";
import { reqToAddBank, reqToGetAllBank } from "../../../redux/slice/bankSlice";
import { useLoader } from "../../../context/LoaderContext";
import List from "./List";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Form, Modal, Row, Toast, ToastContainer } from "react-bootstrap";
import CommonPagination from "../../../components/commonPagination";
import { useNavigate } from "react-router-dom";
import { AddImgLogo, Cancel01Icon, CheckmarkBadge04Icon } from "../../../assets/icons";
import CommonFormLabel from "../../../components/formLabel";
import Input from "../../../components/input";
import CommonButton from "../../../components/commonButton";
import { Formik } from "formik";
import { addBankSchema } from "../../../schema/validationSchemas";
import ValidationFeedback from "../../../components/validationFeedback";

export default function Banks() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setLoading } = useLoader();

   // Toast
    const [showToast, setShowToast] = useState(false);
    const [toastVariant, setToastVariant] = useState("toast-success");
    const [toastMessage, setToastMessage] = useState("");
  // Pagination
  const [
    limit,
    //  setLimit
  ] = useState({ value: 10, label: 10 });
  let savePage = sessionStorage.getItem('currentPageNo');
  // console.log("savePage =>:",savePage);
  
  let final = parseInt(savePage,10);
  // console.log("final ====>:: ",final);
  
  const [currentPage, setCurrentPage] = useState(final ? final : 1);
  const [searchTerm, setSearchTerm] = useState("");
  const [openAddbankModal, setOpenAddbankModal] = useState(false);
  const [addBankImgName, setAddBankImgName] = useState("");
  const [addBankPreviewImg, setAddBankPreviewImg] = useState("");

  const list = useSelector((state) => state?.bank?.banks?.data?.banks || []);
  const totalBranchCount = useSelector((state) => state?.bank?.banks?.data?.total_branches);
  const total = useSelector((state) => state?.bank?.banks?.pagination?.totalRecords);

  const initialEventState = {
    bank_name: "",
    logo: "",
  };

  const AllTypesOfUserCard = [
    {
      id: 1,
      total: total,
      title: "Total Banks",
      src: AllUserImg,
      status: "",
    },
    {
      id: 2,
      total: totalBranchCount,
      title: "Total Branch",
      src: RequestedUser,
      status: "pending",
    },
  ];


  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber);
  };

  const searchTimeout = useRef(null);

  // Debounced Search Handler
  const handleSearch = useCallback(() => {
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    searchTimeout.current = setTimeout(() => {
      dispatch(reqToGetAllBank({ search: searchTerm, page: currentPage }))
    }, 300); // 300ms delay for better UX
  }, [searchTerm, currentPage, dispatch]);

  // Trigger API Call when search or page changes
  useEffect(() => {
    handleSearch();
    return () => clearTimeout(searchTimeout.current);
  }, [currentPage, handleSearch]);  

  // Api Call for Search
  // useEffect(() => {
  //   const delayTimeout = setTimeout(() => {
  //     dispatch(reqToGetAllBank({ search: searchTerm, page: currentPage }))
  //   }, 2000); // 3 second delay

  //   return () => clearTimeout(delayTimeout);
  // }, [searchTerm, setLoading, currentPage, dispatch]);

  // API Call to get All Banks in Bank Manage Module
  const getAllBanks = useCallback(() => {
    sessionStorage.removeItem('currentPageNo'); // Optional: Clear 
    setLoading(true);
    dispatch(
      reqToGetAllBank({
        page: currentPage,
        limit: limit?.value,
      })
    )
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch,limit?.value, setLoading, currentPage]);

  useEffect(() => {
    getAllBanks();
  }, [getAllBanks]);

  // ADD Bank Handler
  const handleSubmit = async (values, { setSubmitting }) => {
    const formData = new FormData();
    formData.append("bank_name", values?.bank_name);
    formData.append("logo", values?.logo);
    setLoading(true)
    try {
        const result = await dispatch(reqToAddBank({ data:formData })).unwrap();
        if (result?.success === true){
          setToastMessage(result?.message ||"Bank Added successfully!");
          setToastVariant("toast-success");
          setSubmitting(false);
          dispatch(reqToGetAllBank({limit:limit?.value}))
          setOpenAddbankModal(false);
          setAddBankImgName("");
          setAddBankPreviewImg("");
          setLoading(false)
        }
      } catch (err) {
        const errorMessage = err?.message || "Failed to Add Bank!";
        setToastMessage(errorMessage);
        setToastVariant("toast-danger");
        setLoading(false);
      } finally {
        setShowToast(true);
        setSubmitting(false);
      }
  };

  // Close Add Bank Modal
  const handleCloseAddBankModal = () => {
    setOpenAddbankModal(false);
    setAddBankPreviewImg("");
    setAddBankImgName("")
  }

  return (
    <>
     <ToastContainer position="top-end" className="p-3">
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={5000}
        autohide
        bg={toastVariant}
        className="position-fixed top-3 end-0 me-3 shadow-none"
      >
      <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
        <CheckmarkBadge04Icon width={18} height={18} color="#28c76f" strokeWidth="2"/>
        <span className="text-capitalize">{toastMessage}</span>
      </Toast.Body>
     </Toast>
  </ToastContainer>
      <Container fluid className="px-0">
        <div className="grid gap-4">
          {AllTypesOfUserCard?.map((item, i) => (
            <DashboardCard
              key={i}
              totalUser={item?.total}
              title={item?.title}
              classNameMain="g-col-4 g-col-xxl-3"
              src={item?.src}
              alt={item?.title}
              // active={item.status === cardStatus}
              status={item?.status}
              // onClick={handleCardClick}
            />
          ))}
        </div>
        <div className="bg-white rounded-2 overflow-hidden common-shadow mt-5">
          <List
            data={list}
            navigate={navigate}
            searchTerm={searchTerm}
            limit={limit}
            total={total}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            handlePageChange={handlePageChange}
            setSearchTerm={setSearchTerm}
            setOpenAddbankModal={setOpenAddbankModal}
          />

          {/* Pagination */}
          <div className="p-3 mx-1 d-flex flex-wrap align-items-center justify-content-between gap-4">
            <p className="custom-table-infotext ">
              Displaying {Math.min((currentPage - 1) * limit?.value + 1, total)}{" "}
              to {Math.min(currentPage * limit?.value, total)} of {total}
            </p>
            <div className="d-flex gap-4">
              {total > limit?.value && (
                <>
                  <CommonPagination
                    totalItems={total}
                    itemsPerPage={limit?.value}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </div>
          </div>

          {/* Add Bank Modal */}
          <Modal
            show={openAddbankModal}
            centered
            dialogClassName="common-popup-dialog"
            contentClassName="common-popup-content"
            backdropClassName="common-popup-backdrop"
            // onHide={handleCloseAddBankModal}
          >
            <Modal.Body className="common-popup-body">
              <div
                className="position-absolute end-0 z-2 bg-white rounded-1 cursor-pointer d-flex align-items-center justify-content-center common-popup-close-btn"
                onClick={handleCloseAddBankModal}
              >
                <Cancel01Icon
                  width={16}
                  height={16}
                  color="#BCBBC1"
                  strokeWidth="2.5"
                />
              </div>
              <div
                className="text-center common-popup-heading"
                style={{ paddingRight: "20px", paddingLeft: "20px" }}
              >
                <h2 className="text-center p-light mb-2">Create New Bank</h2>

                <Formik
                  initialValues={initialEventState}
                  validationSchema={addBankSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    errors,
                    touched,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    values,
                    setFieldValue,
                    handleSubmit,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <>
                        {console.log("val", values)}
                        {/* {console.log("errors",errors)} */}
                        <Row className="text-start">
                          <Col className="mt-4" md={12}>
                            <div className=" d-flex flex-column gap-1 justify-content-center align-items-center">
                              <div className="overflow-hidden position-relative d-flex align-items-center justify-content-center img-import">
                               {addBankPreviewImg ?
                                <img
                                  src={addBankPreviewImg}
                                  className=" object-fit-cover d-inline-block mx-auto"
                                  height={71} width={71} alt={addBankImgName}
                                /> : 
                                <div className=" text-center"><AddImgLogo /></div>}
                                 
                                <input
                                  type="file"
                                  name="logo"
                                  accept="image/*"
                                  onChange={(event) => {
                                    setFieldValue("logo",event.currentTarget.files[0]);
                                    console.log("file",event.currentTarget.files[0]);
                                    setAddBankImgName(event.currentTarget.files[0]?.name);
                                    setAddBankPreviewImg(URL.createObjectURL(event.currentTarget.files[0]))
                                  }}
                                  className="position-absolute z-1 top-0 bottom-0 start-0 end-0 cursor-pointer opacity-0"
                                />
                              </div>
                              <span className="fw-semibold import-text">
                                {addBankImgName
                                  ? addBankImgName
                                  : "Choose Logo"}
                              </span>
                            </div>
                            <div className="text-center">
                              {touched.logo && errors.logo ? (
                                <ValidationFeedback title={errors.logo} />
                              ) : null}
                            </div>
                          </Col>
                          <Col className="mt-4" md={12}>
                            <CommonFormLabel
                              title="Branch Name"
                              htmlFor="bank_name"
                            />
                            <Input
                              id="bank_name"
                              name="bank_name"
                              type="text"
                              placeholder="Ex. ICICI Bank Ltd"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`shadow-none ${
                                touched.bank_name && errors.bank_name
                                  ? "error"
                                  : "correct"
                              }`}
                            />
                            {touched.bank_name && errors.bank_name ? (
                              <ValidationFeedback title={errors.bank_name} />
                            ) : null}
                          </Col>
                        </Row>

                        <div
                          className="text-center"
                          style={{ width: "50%", margin: "0 auto" }}
                        >
                          <CommonButton
                            title="Add Bank"
                            type="submit"
                            className="w-100 btn-primary primary-shadow my-4"
                          />
                        </div>
                      </>
                    </Form>
                  )}
                </Formik>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </Container>
    </>
  );
}
