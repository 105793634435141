import axios from "axios";
import { toast } from "react-hot-toast";

export const authHeaders = () => {
  const authToken =
    localStorage.getItem("userData") &&
    JSON.parse(localStorage.getItem("userData")).token;
  return {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };
};

export const formAuthHeaders = () => {
  const authToken =
    localStorage.getItem("userData") &&
    JSON.parse(localStorage.getItem("userData")).token;
  return {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${authToken}`,
    },
  };
};

// console.log("authHeaders",process.env.REACT_APP_BASE_URL);

const Axios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  withCredentials: true,
});

Axios.interceptors.response.use(
  (response) => {
    // console.log("Interceptor Response ::: ", response);
    return response
  },
  (error) => {
    // console.log("Api Error::::  ", error);
    if (error.response && error.response.status === 401) {
      console.log("Interceptor: Unauthorized (401) error detected");
      // Clear user data or handle redirection if needed

      // Show toast for session expiration
      toast.error("Session Expired. Please log in again.", {
      duration: 4000, // Toast disappears after 3 seconds
      });
 
      localStorage.removeItem("auth-token");
      localStorage.removeItem("userData");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default Axios;


