import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders, formAuthHeaders } from "../../utils/axios";

export const addNpaProperties = createAsyncThunk("property/addNpaProperties",async ({data}, { rejectWithValue }) => {
  try {
    const response = await Axios.post(`/api/admin/properties/add`,data,formAuthHeaders());
    return response.data; // Success case
  } catch (error) {
    return rejectWithValue(error.response.data); // Error case
  }
});

export const updateNpaProperties = createAsyncThunk("property/updateNpaProperties",async ({ id, data}, { rejectWithValue }) => {
  try {
    const response = await Axios.put(`/api/admin/properties/update/${id}`,data,formAuthHeaders());
    return response.data; // Success case
  } catch (error) {
    return rejectWithValue(error.response.data); // Error case
  }
});

export const deleteProperty = createAsyncThunk("properties/deleteProperty",async({id},{ rejectWithValue }) => {
    try{
     const response = await Axios.delete(`/api/admin/properties/delete/${id}`,authHeaders());
     return response.data; //success Case
    }catch(err){
      return rejectWithValue(err.message); // Error case
    }
})

export const npaProperties = createAsyncThunk("properties/npaProperties",async ({status, page, limit, search, asset_category, property_type, possession_type, city_town, reserve_price},{ rejectWithValue }) => {
    try {
      const response = await Axios.get(`/api/admin/properties/list?status=${status || ""}&limit=${limit || 10}&page=${page || 1}&search=${search || ""}&asset_category=${asset_category || ""}&property_type=${property_type || ""}&possession_type=${possession_type || ""}&city_town=${city_town || ""}&reserve_price=${reserve_price || ""}`, authHeaders());
      return response.data; //success Case
    } catch (error) {
      return rejectWithValue(error.message); // Error case
    }
  });

export const propertyDetails = createAsyncThunk("properties/propertyDetails",async ({id},{ rejectWithValue }) => {
    try{
     const response = await Axios.get(`/api/admin/properties/${id}`,authHeaders());
     return response.data; // Success case
    }catch(error){
      return rejectWithValue(error.response.data); // Error case
    }
  });


export const reqToGetFilterData = createAsyncThunk("properties/reqToGetFilterData",async ({ rejectWithValue }) => {
  try {
    const response = await Axios.get(`/api/bank/filters/metadata`,authHeaders());
    return response?.data; // Success case
  } catch (error) {
    return rejectWithValue(error.response.data); // Error case
  }
});

export const importUserList = createAsyncThunk("properties/importUserList",async (data,{ rejectWithValue }) => {
  try {
    const response = await Axios.get(`/api/admin/users/bank-users`,data,authHeaders());
    return response?.data; // Success case
  } catch (error) {
    return rejectWithValue(error.response.data); // Error case
  }
});


export const reqToImportNpaProperties = createAsyncThunk("properties/reqToImportNpaProperties",async ({data},{ rejectWithValue }) => {
  try {
    const response = await Axios.post(`/api/admin/properties/bulk-insert`,data,formAuthHeaders());
    return response?.data; // Success case
  } catch (error) {
    return rejectWithValue(error.response.data); // Error case
  }
});

export const propertyTypesDropdown = createAsyncThunk("properties/propertyTypesDropdown",async ({ rejectWithValue }) => {
  try {
    const response = await Axios.get(`/api/admin/properties/dropdowns`,authHeaders());
    return response?.data; // Success case
  } catch (error) {
    return rejectWithValue(error.response.data); // Error case
  }
});


const propertiesSlice = createSlice({
  name: "properties",
  initialState: {
    user: null,
    isAuthenticated: false,
    isLoading: false,
    error: null,
    property: [],
    propertyDetail:{},
    filter:{},
    addProperty:{},
    updateProperty:{},
    deleteNpaProperty:{},
    importData:[],
    importBankUsers:[],
    propertyDropdown:[],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
       // Add NPA Property
       .addCase(addNpaProperties.pending, (state, action) => {
        state.isLoading = true;
      })
       .addCase(addNpaProperties.fulfilled, (state, action) => {
        state.isLoading = false;
        state.addProperty = action.payload;
      })
      .addCase(addNpaProperties.rejected, (state, action) => {
        state.isLoading = false;
      })
      // Update NPA Property
      .addCase(updateNpaProperties.pending, (state, action) => {
        state.isLoading = true;
      })
        .addCase(updateNpaProperties.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateProperty = action.payload;
      })
      .addCase(updateNpaProperties.rejected, (state, action) => {
        state.isLoading = false;
      })
       // NPA Property List
       .addCase(npaProperties.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(npaProperties.fulfilled, (state, action) => {
        state.isLoading = false;
        state.property = action.payload;
      })
      .addCase(npaProperties.rejected, (state, action) => {
        state.isLoading = false;
      })
      // NPA Property Details
      .addCase(propertyDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.propertyDetail = action.payload;
      })
      // Delete NPA Property
      .addCase(deleteProperty.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteProperty.fulfilled, (state, action) => {
        state.isLoading = false;
        state.deleteNpaProperty = action.payload;
      })
      .addCase(deleteProperty.rejected, (state, action) => {
        state.isLoading = false;
      })
      // request to fetch filter data
      .addCase(reqToGetFilterData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.filter = action.payload;
      })
      // request to get list of Import Users
      .addCase(importUserList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.importBankUsers = action.payload;
      })
      // request to import Npa Properties
      .addCase(reqToImportNpaProperties.fulfilled, (state, action) => {
        state.isLoading = false;
        state.importData = action.payload;
      })
      // request to Types of property Dropdown
      .addCase(propertyTypesDropdown.fulfilled, (state, action) => {
        state.isLoading = false;
        state.propertyDropdown = action.payload;
      })
  },
});

export default propertiesSlice.reducer;
