import React from "react";
import CommonActionDropDown from "../../../components/common-action-dropdown";
import { ButtonGroup, Table } from "react-bootstrap";
import ExportImportImg from "../../../assets/images/table/export.png";
import { AllUsersList } from "../../../components/TableHeadings";
// import TableImportImg from "../../../assets/images/table/import.png";
// import ExportImportImg from "../../../assets/images/table/export.png";
import AddPropertyImg from "../../../assets/images/table/plus.png";
import Input from "../../../components/input";
import CommonButton from "../../../components/commonButton";
import NoData from "../../../components/no-data";
import { MoreVerticalIcon } from "../../../assets/icons";
import moment from "moment";
import StatusChip from "../../../components/statusChip";
import { BankIcons } from "../../../components/common";

const List = ({
  data,
  searchTerm,
  setSearchTerm,
  setCurrentPage,
  openDropdown,
  addUserHandler,
  getCustomerActions,
  handleToggleDropdown,
  handleExportAllUsersList
}) => {

  // console.log("data",data);
  

  return (
    <>
        <div className="p-4 d-flex align-items-center justify-content-between gap-4">
          <Input
            type="search"
            value={searchTerm}
            placeholder="Search User"
            onChange={(e) => {
              setSearchTerm(e.target.value)
              setCurrentPage(1);
            }}
            className="shadow-none base-search"
          />

          <div className="action-buttons-container">
             
            <CommonButton
              icon={<span><img src={ExportImportImg} alt="import" /></span>}
              className="btn-primary-light me-2"
              title="Export"
              type="button"
              onClick={(e) => {
                e.stopPropagation()
                handleExportAllUsersList()
              }}
            />
             <CommonButton
                icon={<span><img src={AddPropertyImg} alt="import" /></span>}
                className="btn-primary"
                title="User"
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  addUserHandler()
                }}
              />
          </div>
        </div>

        <Table responsive className="common-table">
          <thead>
            <tr>
              {AllUsersList?.map((column, index) => (
                <th key={index}>{column.label}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {data?.length === 0 ? (
              <NoData />
            ) : (
              data?.map((item, i) => (
                <tr key={item?.id}>
                  <td>{item?.profile && item?.profile?.name ? item?.profile?.name : "-"}</td>
                  <td>{item?.mobile_number ? item?.mobile_number : '-'}</td>
                  <td>{item?.email ? item?.email : ""}</td>
                  <td>{item?.profile && item?.profile?.designation ? item?.profile?.designation : "-"}</td>
                  <td className="text-start">{item?.profile?.bank ? <>{BankIcons(item?.profile?.bank?.bank_name)}{" "} {item?.profile?.bank?.bank_name}</> : '-'}</td>
                  <td>{item?.profile?.branch ? item?.profile?.branch?.branch_city :"-"}</td>
                  {/* <td>
                    {item?.created_at ? moment(item.created_at).format("DD-MM-YYYY hh:mm A") : ""}
                  </td> */}
                   <td className="text-start">
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                      <span>{item?.created_at ? moment(item.created_at).format("DD MMM YYYY") : ""}</span>
                      <span>{item?.created_at ? moment(item.created_at).format("hh:mm A") : ""}</span>
                    </div>
                   </td>
                  <td className="text-capitalize">
                    {item?.status && (
                      <StatusChip pill bg={item?.status === "pending" ? "chip-warning" : item?.status === "approved" ? "chip-green" : item?.status === "rejected" ? "chip-red" : "custom-status-chip"} title={item?.status} className="text-capitalize"/>
                    )}
                  </td>

                  <td onClick={(e) => e.stopPropagation()}>
                    <CommonActionDropDown
                      as={ButtonGroup}
                      id={`dropdown-basic-${i}`}
                      icon={
                        <MoreVerticalIcon
                          width={20}
                          height={20}
                          color="#6D6B77"
                          strokeWidth="4"
                        />
                      }
                      actions={getCustomerActions(item)}
                      show={openDropdown === i}
                      onToggle={() => handleToggleDropdown(i)}
                      className="action-menu"
                    />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
    </>
  );
};

export default List;
