export const routes = {
  home: "/",
  profile: "/profile",
  login: "/login",
  register: "/register",

  addNpaProperties: "/npa-properties/add",
  editNpaProperties: "/npa-properties/edit/:id",
  viewNpaProperties: "/npa-properties/property-details/:id",

  npaInquiries: "/npa-inquiries",

  allUsers: "/all-users",
  requestedUser: "/requested-user",
  approvedUser: "/approved-user",
  rejectedUser: "/rejected-user",

  banks: "/banks",
  viewBanksDetail: "/bank-details/:id",
};
