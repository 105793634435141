import React from "react";
import Input from "../../../components/input";
import CommonButton from "../../../components/commonButton";
import NoData from "../../../components/no-data";
import {Add01Icon} from "../../../assets/icons";
import { Table } from "react-bootstrap";
import { BanksDetailList } from "../../../components/TableHeadings";
import { BankIcons } from "../../../components/common";

export default function DetailList({ data, setCurrentPage, detailSearchBar, setDetailSearchBar, setOpenAddBranchModal}) {
  
  return (
    <>
      <div className="p-4 d-flex align-items-center justify-content-between gap-4">
        <Input
          type="search"
          value={detailSearchBar}
          placeholder="Search by Branch Name"
          onChange={(e) => {
            setDetailSearchBar(e.target.value);
            setCurrentPage(1);
          }}
          className="shadow-none base-search"
        />
        <div className="action-buttons-container">
          <CommonButton
            onClick={() => setOpenAddBranchModal(true)}
            icon={
              <Add01Icon
                height={14}
                width={14}
                color="#ffffff"
                strokeWidth="3"
              />
            }
            title="Add Branch"
            className="btn-primary"
            subClassName="gap-2"
          />
        </div>
      </div>
      <Table responsive className="common-table">
        <thead>
          <tr>
            {BanksDetailList?.map((column, index) => (
              <th key={index} className="text-start text-uppercase">
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.length === 0 ? (
            <NoData />
          ) : (
            data?.map((item, i) => (
              <tr key={i}>
                <td className="d-flex align-items-center border-0 justify-content-start gap-2">
                  {item?.bank?.bank_name ? <>{BankIcons(item?.bank?.bank_name)}</> : "-"}
                  {item.branch_name}
                </td>
                <td className="text-start">{item.branch_city}</td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </>
  );
}
