import React from "react";
import CommonButton from "../../components/commonButton";
import TableImportImg from "../../assets/images/table/import.png";
import ExportImportImg from "../../assets/images/table/export.png";
import AddPropertyImg from "../../assets/images/table/plus.png";
import Input from "../../components/input";
import { routes } from "../../routes/pageRoutes";
import { FilterIcon } from "../../assets/icons";

const NpaAddImpExprtBtn = ({
  filterValues,
  navigate,
  setFilterValues,
  setFilterModal,
  importBtnHandler,
  setCurrentPage,
  handleExportNpaProperties
}) => {


  // Check if any value except 'search' is non-empty
  const isFilterActive = Object?.entries(filterValues)
    .filter(([key]) => key !== "search") // Exclude the 'search' key
    .some(([, value]) => value !== ""); // Check if any value is non-empty

  return (
    <>
      <div className="p-4 d-flex align-items-center justify-content-between flex-wrap gap-3">
        <div className="d-flex flex-wrap align-items-center gap-2">
          <Input
            type="search"
            value={filterValues?.search}
            placeholder="City, state, possession, borrower's name"
            onChange={(e) => {
              setFilterValues((val) => ({
                ...val,
                search: e.target.value,
              }));
              setCurrentPage(1)
            }}
            className="shadow-none base-search"
          />
          <button
            onClick={() => setFilterModal(true)}
            className={`rounded-2 position-relative custom-filter-btn ${
              isFilterActive ? "btn-active" : ""
            }`}
          >
            {/* btn-active --- Apply Filter Class */}
            <div className="d-flex gap-2 align-items-center justify-content-center ">
              <span>
                <FilterIcon height={16} width={16} strokeWidth="1.5" color="" />
              </span>
              <p>Filter</p>
            </div>
          </button>
        </div>
        <div className="action-buttons-container">
          <CommonButton
            icon={<span><img src={TableImportImg} alt="import" /></span>}
            className="btn-primary-light me-2"
            onClick={importBtnHandler}
            title="Import"
            type="button"
          />
          <CommonButton
            icon={<span><img src={ExportImportImg} alt="import" /></span>}
            className="btn-primary-light me-2"
            title="Export"
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              handleExportNpaProperties();
            }}
          />
          <CommonButton
            icon={<span><img src={AddPropertyImg} alt="import" /></span>}
            className="btn-primary"
            title="Add Property"
            onClick={(e) => {
              e.stopPropagation();
              navigate(routes.addNpaProperties);
            }}
          />
        </div>
      </div>

    </>
  );
};

export default NpaAddImpExprtBtn;
