import React from "react";
import { Calendar03Icon, Cancel01Icon, GreenRupeeIcon } from "../../assets/icons";
import { Card, Modal } from "react-bootstrap";
import { formatCurrencyIndianStyle, getUserInitial } from "../../components/common";
import moment from "moment";

const ViewNpaInquiries = ({ show, onHide,selectedInquiry }) => {
  // console.log("selectedInquiry",selectedInquiry);
  
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      dialogClassName="common-popup-dialog"
      contentClassName="common-popup-content"
      backdropClassName="common-popup-backdrop"
    >
      <Modal.Body className="common-popup-body">
        <div
          className="position-absolute end-0 z-2 bg-white rounded-1 cursor-pointer d-flex align-items-center justify-content-center common-popup-close-btn"
          onClick={onHide}
        >
          <Cancel01Icon
            width={16}
            height={16}
            color="#BCBBC1"
            strokeWidth="2.5"
          />
        </div>
        <h2 className="text-center p-light mb-2">Inquiry Details</h2>
        <div className="modal-p">
            <div className="user-cell mb-3">
              <span className="user-initials">{selectedInquiry?.user_name && getUserInitial(selectedInquiry?.user_name)}</span>
              {selectedInquiry?.user_name ? selectedInquiry?.user_name : "-"}
            </div>
          <p className="p-dark mb-1">Contact Info</p>
          <p className="p-extra-light mb-1">Email: {selectedInquiry?.email ? selectedInquiry?.email : "-"}</p>
          <p className="p-extra-light mb-3">Mobile: {selectedInquiry?.phone_number ? selectedInquiry?.phone_number : "-"}</p>
          <Card className="rounded-2 g-col-12 g-col-lg-6 g-col-xxl-12 border-0 w-100 common-card">
            <Card.Body className="p-0 common-card-body">
              <div className="grid row-gap-3 column-gap-0 column-gap-xxl-2 mb-3 mb-xxl-4">
                <div className="g-col-6">
                  <div className="d-flex align-items-center status-content success-status">
                    <div className="rounded-2 d-flex align-items-center justify-content-center status-icon">
                    <GreenRupeeIcon
                      width={17}
                      height={17}
                      color="#28c76f"
                      strokeWidth="1.5"
                    />
                    </div>
                    <div>
                      <h6 className="" style={{ color: "#28C76F" }}>
                        {/* ₹17,50,0000 */}
                        {selectedInquiry?.budget_range ? `₹ ${formatCurrencyIndianStyle(selectedInquiry?.budget_range)}` : "-"}
                      </h6>
                      <span className="text-capitalize">Budget Range</span>
                    </div>
                  </div>
                </div>
                <div className="g-col-6">
                  <div className="d-flex align-items-center status-content primary-status">
                    <div className="rounded-2 d-flex align-items-center justify-content-center status-icon">
                      <Calendar03Icon
                        width={22}
                        height={22}
                        color="#9F2F48"
                        strokeWidth="1.5"
                      />
                    </div>
                    <div>
                      <h6 className="npa-details">
                        {/* 09 Feb 2020   */}
                        {selectedInquiry?.created_at ? moment(selectedInquiry?.created_at).format("DD MMM YYYY") : "-"}
                      </h6>
                      <span className="text-capitalize">Inquiry Created</span>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
          <h6 className="p-dark mb-3">Notes</h6>
          <p className="p-dark">{selectedInquiry?.message ? selectedInquiry?.message: "-"}</p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewNpaInquiries;
