import moment from "moment";
import { AxisBank, BankOfBaroda, BankOfIndia, CanaraBank, CentralBankOfIndia, HdfcBank, IciciBank, IdbiBank, IndianBank, KotakMahindra, NoImgIcon, PunjabNational, Sbi, Uco, UnionBankOfIndia } from "../../assets/icons";
// import AllProperty from "../../assets/images/npa-properties/all-property-icon.png";
// import ExpiredProperty from "../../assets/images/npa-properties/expired-property.png";
// import LiveProperty  from "../../assets/images/npa-properties/live-property-icon.png";
// import UpcomingProperty from "../../assets/images/npa-properties/upcoming-property.png";
// import AllUsers from "../../assets/images/npa-properties/all-users.png";
// import RequestedUser from "../../assets/images/npa-properties/requested-user.png";
// import AcceptedUser from "../../assets/images/npa-properties/accepted-user.png";
// import RejectedUser from "../../assets/images/npa-properties/rejected-user.png";





export const getUserInitial = (name) => {
  return name
    ? name
        .split(" ")
        .map((n) => n.charAt(0))
        .join("")
    : "UN";
};

export const formatCurrencyIndianStyle = (value) => {
  if (!value) return '';
  // Convert to a string and split into integer and fractional parts
  const [integerPart, fractionalPart] = value.toString().split('.');
  // Format integer part with Indian numbering style
  const lastThree = integerPart.slice(-3);
  const otherNumbers = integerPart.slice(0, -3);
  const formattedInteger = otherNumbers
    ? otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + ',' + lastThree
    : lastThree;
  // Include fractional part only if it exists and is not "00"
  const formattedFractional = fractionalPart && fractionalPart !== '00' ? `.${fractionalPart}` : '';
  // Combine integer part with fractional part
  return `${formattedInteger}${formattedFractional}`;
};

export const formatDateFromString = (dateString, format = "DD-MM-YYYY") => {
  if (!dateString) return "-"; // Fallback for invalid input
  const dateTime = dateString.split(" at ")[1]; // Extract the date-time
  if (!dateTime) return "-"; // Fallback if no date-time part is found
  return moment(dateTime).isValid() ? moment(dateTime).format(format) : "-"; // Format if valid
};

export const formatTimeFromString = (dateString, format = "hh:mm A") => {
  if (!dateString) return "-"; // Fallback for invalid input
  const dateTime = dateString.split(" at ")[1]; // Extract the date-time
  if (!dateTime) return "-"; // Fallback if no date-time part is found
  return moment(dateTime).isValid() ? moment(dateTime).format(format) : "-"; // Format if valid
};

// Transform response into { value, label } format
export const mapToSelectOptions = (arr) =>
  arr?.map((item) => ({ value: item, label: item })) || [];

export const BankIcons = (value) => {
  switch (value) {
    case "State Bank of India (SBI)":
      return <Sbi/>
    case "Punjab National Bank (PNB)":
      return <PunjabNational/>
    case "Bank of Baroda (BoB)":
      return <BankOfBaroda/>
    case "Canara Bank":
      return <CanaraBank/>
    case "Union Bank of India":
      return <UnionBankOfIndia/>
    case "Indian Bank":
      return <IndianBank/>
    case "Bank of India (BOI)":
      return <BankOfIndia/>
    case "Central Bank of India":
      return <CentralBankOfIndia/>
    case "UCO Bank":
      return <Uco/>
    case "IDBI Bank":
        return <IdbiBank/>
    case "HDFC Bank":
        return <HdfcBank/>
    case "ICICI Bank":
        return <IciciBank/>
    case "Axis Bank":
        return <AxisBank/>
    case "Kotak Mahindra Bank":
        return <KotakMahindra/>
    default:
      return <NoImgIcon/>; // Fallback icon URL
  }
};


