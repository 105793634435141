import React from 'react'
import { Link } from 'react-router-dom';

export default function BackPageTab({ to, icon, title, onClick }) {
    return (
        <div className='d-flex align-items-center gap-1 text-decoration-none back-page-tab'>
            <Link to={to} onClick={onClick}>
                {icon && <div>{icon}</div>}
            </Link>
            {title && <h2 className='fw-medium'>{title}</h2>}
        </div>
    )
}
