import React, { useEffect, useState } from 'react'
import { Cancel01Icon, CheckmarkBadge04Icon, EyeIcon, ViewOffSlashIcon } from '../../../assets/icons'
import { Col, Form, Modal, Row, Toast, ToastContainer} from 'react-bootstrap'
import Input from '../../../components/input'
import { Formik } from "formik";
import CommonFormLabel from "../../../components/formLabel";
// import SimpleDropdown from "../../../components/simpleDropdown";
import SimpleDropdownOther from "../../../components/simpleDropdownOther";
// import ValidationFeedback from '../../../components/validationFeedback';
import CommonButton from '../../../components/commonButton';
import { addUserSchema } from '../../../schema/validationSchemas';
import ValidationFeedback from '../../../components/validationFeedback';
import { useDispatch } from 'react-redux';
import { useLoader } from "../../../context/LoaderContext";
// import { useNavigate } from "react-router-dom";
// import  useMounted  from '../../../hooks/useMounted';
import { allUserList, reqToAddUserFromAdmin, reqToGetAllBankList, reqToGetBranchList, reqToGetCityList, reqToGetStateList } from '../../../redux/slice/userSlice';

const AddUser = ({show,onHide,setOpenAddUserModal,cardStatus,currentPage,limit}) => {
  const dispatch = useDispatch();
  const { setLoading } = useLoader();
  // const navigate = useNavigate();
  // const hasMounted = useMounted();

  const [showToast, setShowToast] = useState(false);
  const [toastVariant, setToastVariant] = useState("toast-success");
  const [toastMessage, setToastMessage] = useState("");

  const [showEyeIcon, setShowEyeIcon] = useState(false);
  const [bankOptions, setBankOptions] = useState([]);
  const [branchOptions, setBranchOptions] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [selectedBankId, setSelectedBankId] = useState(null);
  const [selectedStateId, setSelectedStateId] = useState(null);

  const initialEventState = {
    name: "",
    email: "",
    bank_id: "",
    branch_id: "",
    city: "",
    state: "",
    designation:"",
    phone: "",
    password: "",
  };

  // console.log("Bank :: ,",selectedBankId);
  // console.log("State :: ,",selectedStateId);
  

    // Api call to fetch all the banks & state list
    useEffect(() => {
      dispatch(reqToGetAllBankList({})).then((res) => {
        if (res?.payload?.success === true) {
          const list = res?.payload?.banks?.map((item) => ({
            value: item?.id,
            label: item?.bank_name,
          }));
          setBankOptions(list);
        }
      });
  
      dispatch(reqToGetStateList({})).then((res) => {
        if (res?.payload?.success === true) {
          const list = res?.payload?.states?.map((item) => ({
            value: item?.id,
            label: item?.name,
          }));
          setStateList(list);
        }
      });
    }, [dispatch]);

    useEffect(() => {
      if (selectedBankId !== null) {
        dispatch(reqToGetBranchList({ selectedBankId })).then((res) => {
          if (res?.payload?.success === true) {
            const list = res?.payload?.branches?.map((item) => ({
              value: item?.id,
              label: `${item?.branch_name} - (${item?.branch_city})`,
            }));
            setBranchOptions(list);
          }
        });
      }
    }, [dispatch,selectedBankId])
    
    // Api call to fetch branch & city list based on selected Bank and state
    useEffect(() => {
      // if (selectedBankId !== null) {
      //   dispatch(reqToGetBranchList({ selectedBankId })).then((res) => {
      //     if (res?.payload?.success === true) {
      //       const list = res?.payload?.branches?.map((item) => ({
      //         value: item?.id,
      //         label: `${item?.branch_name} - (${item?.branch_city})`,
      //       }));
      //       setBranchOptions(list);
      //     }
      //   });
      // }
  
      if (selectedStateId !== null) {
        dispatch(reqToGetCityList({ selectedStateId })).then((res) => {
          if (res?.payload?.success === true) {
            const list = res?.payload?.cities?.map((item) => ({
              value: item?.id,
              label: item?.name,
            }));
            setCityList(list);
          }
        });
      }
    }, [dispatch,selectedStateId]);


   // Add User Final Handler
   const handleSubmit = async (values,{setSubmitting}) => {
    const data = {
      name: values?.name,
      designation: values?.designation,
      bank_id: values.bank_id,
      branch_id: values.branch_id,
      state_id: values?.state,
      email: values?.email,
      city_id: values.city,
      mobile_number: values?.phone,
      password: values?.password,
    };  
    setLoading(true);  
    try{
      const result = await dispatch(reqToAddUserFromAdmin({data})).unwrap()
      if (result?.success === true) {
        setToastMessage("User Added Successfully!")
        setToastVariant("toast-success");
        setOpenAddUserModal(false);
        setSubmitting(false);
        dispatch(allUserList({ 
          status: cardStatus,
          page: currentPage,
          limit: limit?.value})).then((res) => {
          setLoading(false);
        })
      }
    } catch(error){
      const errorMessage = error?.message || "Failed to Add Properties!";
      setToastMessage(errorMessage);
      setToastVariant("toast-danger");
    } finally {
      setShowToast(true);
      setSubmitting(false);
      setOpenAddUserModal(false);
    }
   }

  return (
    <>
     <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={5000}
          autohide
          bg={toastVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{toastMessage}</span>
          </Toast.Body>
        </Toast>
     </ToastContainer>
    <Modal
      show={show}
      centered
      dialogClassName="common-popup-dialog"
      contentClassName="common-popup-content"
      backdropClassName="common-popup-backdrop"
    >
    <Modal.Body className="common-popup-body">
      <div className="position-absolute end-0 z-2 bg-white rounded-1 cursor-pointer d-flex align-items-center justify-content-center common-popup-close-btn" onClick={onHide}>
        <Cancel01Icon width={16} height={16} color="#BCBBC1" strokeWidth="2.5"/>
      </div>
      <div className="text-center common-popup-heading">
      <h2 className="text-center p-light mb-2">Create New User</h2>
      <Formik 
        initialValues={initialEventState} 
        validationSchema={addUserSchema}
        onSubmit={handleSubmit}
      >
        {({errors,touched,isSubmitting,handleChange,values,setFieldValue,handleSubmit}) => (
          <>
          {/* {console.log("values",values)} */}
           <Form onSubmit={handleSubmit}>
            <Row className="mt-4 text-start">
              <Col md={6}>
                  <CommonFormLabel title="Name" />
                  <Input
                    id="name"
                    name="name"
                    type="text"
                    placeholder="John Doe"
                    onChange={handleChange}
                    className={`shadow-none ${touched.name && errors.name ? "error" : "correct"}`}/>
                    {touched.name && errors.name ? (<ValidationFeedback title={errors.name} />) : null}
              </Col>
        
              <Col md={6}>
                <CommonFormLabel title="Designation" />
                <Input
                  id="designation"
                  name="designation"
                  type="text"
                  placeholder="Manager"
                  onChange={handleChange}
                  className={`shadow-none ${
                    touched.designation && errors.designation ? "error" : "correct"
                  }`}
                />
                {touched.designation && errors.designation ? (
                  <ValidationFeedback title={errors.designation} />
                ) : null}
              </Col>
            </Row>

            <Row className="text-start mt-3">
              <Col md={12}>
                  <SimpleDropdownOther
                  label="Bank Name"
                  name="bank_id"
                  value={values.bank_id}
                  onChange={(selectedOption) => {
                      setFieldValue("bank_id", selectedOption.value);
                      setSelectedBankId(selectedOption.value);
                  }}
                  options={bankOptions}
                  placeholder="Select a Bank"
                  error={errors?.bank_id}
                  touched={touched?.bank_id}
                  isClearable={false}
                  />
              </Col>
            </Row>

            <Row className="text-start mt-3">
              <Col md={6}>
                  <SimpleDropdownOther
                  label="Branch Name"
                  name="branch_id"
                  value={values.branch_id}
                  onChange={(selectedOption) => {
                    setFieldValue("branch_id", selectedOption.value);
                      // setSelectedBranchId(selectedOption.value);
                  }}
                  options={branchOptions}
                  placeholder="Select a Branch"
                  error={errors?.branch_id}
                  touched={touched?.branch_id}
                  isDisabled={!selectedBankId}
              
                  />
              </Col>
              <Col md={6}>
                  <SimpleDropdownOther
                  label="State"
                  name="state"
                  value={values.state}
                  onChange={(selectedOption) => {
                    setFieldValue("state", selectedOption.value);
                    setSelectedStateId(selectedOption.value);
                  }}
                  options={stateList}
                  placeholder="Select a State"
                  error={errors?.state}
                  touched={touched?.state}
       
                  />
              </Col>
            </Row>

            <Row className="text-start  mt-3">
              <Col md={6}>
                  <CommonFormLabel title="Email" />
                  <Input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="rohit@gmail.com"
                  onChange={handleChange}
                  className={`shadow-none ${
                      touched.email && errors.email
                      ? "error"
                      : "correct"
                  }`}
                  />
                  {touched.email && errors.email ? (
                  <ValidationFeedback title={errors.email} />
                  ) : null}
              </Col>
              <Col md={6}>
                  <SimpleDropdownOther
                  label="City"
                  name="city"
                  value={values.city}
                  onChange={(selectedOption) => {
                    setFieldValue("city", selectedOption.value);
                  }}
                  options={cityList}
                  placeholder="Select a City"
                  error={errors?.state}
                  touched={touched?.state}
                  isDisabled={!selectedStateId}
 
                  />
              </Col>
            </Row>

            <Row className="text-start mt-3">
              <Col md={6}>
                  <CommonFormLabel title="Mobile Number" />
                  <Input
                    id="phone"
                    name="phone"
                    type="text"
                    placeholder="9876543210"
                    onChange={handleChange}
                    className={`shadow-none ${
                      touched.phone && errors.phone
                        ? "error"
                        : "correct"
                    }`}
                  />
                  {touched.phone && errors.phone ? (
                    <ValidationFeedback title={errors.phone} />
                  ) : null}
              </Col>  
            </Row>
                        

            <Row className="text-start mt-3">
              <Col md={12}>
                  <CommonFormLabel title="Password" />
                  <div className="position-relative">
                  <Input
                      id="password"
                      name="password"
                      type={showEyeIcon ? "password" : "text"}
                      placeholder="Enter your password"
                      onChange={handleChange}
                      className={`shadow-none ${
                        touched.password && errors.password
                          ? "error"
                          : "correct"
                      }`}
                  />
                  <div className="position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer">
                      {showEyeIcon === false ? (
                      <ViewOffSlashIcon
                          width={20}
                          height={20}
                          color="#444050"
                          strokeWidth="1.5"
                          className={
                            touched.password && errors.password
                              ? "error"
                              : "correct"
                          }
                          onClick={() => setShowEyeIcon(true)}
                      />
                      ) : (
                      <EyeIcon
                          width={20}
                          height={20}
                          color="#444050"
                          strokeWidth="1.5"
                          className={`${
                            touched.password && errors.password
                              ? "error"
                              : "correct"
                          }`}
                          onClick={() => setShowEyeIcon(false)}
                      />
                      )}
                  </div>
                  </div>
                  {touched.password && errors.password ? (
                  <ValidationFeedback title={errors.password} />
                  ) : null}
              </Col>
            </Row>

            <div className="text-center my-4" style={{ width: "50%", margin: "0 auto" }}>
              <CommonButton
                title="Add User"
                type="submit"
                disabled={isSubmitting}
                className="w-100 btn-primary primary-shadow"
              />
            </div>
           </Form>
          </>
        )}
       </Formik>
      </div>
    </Modal.Body>
    </Modal>
    </>
  )
}

export default AddUser