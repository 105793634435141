import React from "react";
import { Card } from "react-bootstrap";

const DashboardCard = ({
  totalUser,
  title,
  src,
  alt,
  className,
  classNameMain,
  onClick = null,
  active = null,
  status,
}) => {
  return (
    // <Row className="g-3">
    //   <Col md={3}>
    <div className={classNameMain}>
      <Card
        className={`card-outer-wrapper cursor-pointer w-100 ${
          active && "card-border-active"
        }`}
        onClick={onClick ? () => onClick(status) : undefined}
      >
        <Card.Body className="text-center">
          <div className="d-flex justify-content-between align-items-center card-content">
            {/* Left Container: Texts */}
            <div className="d-flex flex-column align-items-start text-container">
              <h6 className="mb-1 card-numbers-color">{totalUser || 0}</h6>
              <p className="fs-9 mb-0 card-para-color">{title}</p>
            </div>

            {/* Right Container: Image */}
            <div className="image-container">
              <img src={src} alt={alt} className={`img-fluid ${className}`} />
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
    //   </Col>
    // </Row>
  );
};

export default DashboardCard;
