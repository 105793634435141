import React, { useCallback, useEffect, useRef, useState } from "react";
import {ToastContainer, Toast, Modal, Row, Col, Button, Form, Table, OverlayTrigger, Tooltip} from "react-bootstrap";
// import Papa from "papaparse";
import * as XLSX from "xlsx";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLoader } from "../../context/LoaderContext";
import AllProperty from "../../assets/images/npa-properties/all-property-icon.png";
import ExpiredProperty from "../../assets/images/npa-properties/expired-property.png";
import LiveProperty from "../../assets/images/npa-properties/live-property-icon.png";
import UpcomingProperty from "../../assets/images/npa-properties/upcoming-property.png";
import "../../assets/styles/src/container/pages/npa-properties.css";
import NpaListSection from "./NpaListSection";
import NpaAddImpExprtBtn from "./NpaAddImpExprtBtn";
import { deleteProperty, importUserList, npaProperties, propertyTypesDropdown, reqToGetFilterData, reqToImportNpaProperties,} from "../../redux/slice/propertiesSlice";
import CommonPagination from "../../components/commonPagination";
import { mapToSelectOptions } from "../../components/common";
import DashboardCard from "../../components/dashboard-card";
import DeletePopup from "../../components/delete-popup";
import { Cancel01Icon, CheckmarkBadge04Icon, Delete02Icon, InformationCircleIcon} from "../../assets/icons";
import CommonFormLabel from "../../components/formLabel";
import Select from "react-select";
// import moment from "moment";
import CommonButton from "../../components/commonButton";
// import { allUserList } from "../../redux/slice/userSlice";

export default function List() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setLoading } = useLoader();

  // Toast
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("toast-success");
  // List Dropdown
  const [openDropdown, setOpenDropdown] = useState(null);
  // Pagination
  const [
    limit,
    // setLimit
  ] = useState({ value: 10, label: 10 });
  let savePage = sessionStorage.getItem('currentPageNo');
  let final = parseInt(savePage,10);
  const [currentPage, setCurrentPage] = useState(final ? final : 1);
  // Delete Property, Modal state
  const [openDeletePropertyModal, setOpenDeletePropertyModal] = useState(false);
  const [selectedDeleteProperty, setSelectedDeleteProperty] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // Card Status
  const [cardStatus, setCardStatus] = useState("");
  // Add or Edit Form
  const [isEdit, setIsEdit] = useState(false);
  // Filter Modal State
  const [filterModal, setFilterModal] = useState(false);
  // search & Filter
  const [filterValues, setFilterValues] = useState({
    search: "",
    asset: "",
    property: "",
    possession: "",
    city: "",
    reservePrice: "",
  });
  // Open Modal for Import
  const [openImportModal, setOpenImportModal] = useState(false);
  // CSV Data
  const [csvData, setCsvData] = useState("");
  const [importApiErr,setImportApiErr] = useState("")
  // console.log("csvData",csvData);
  
  // const [
  //   // errors,
  //    setErrors] = useState([]);
  const [csvFileName, setCsvFileName] = useState("");
  // Options for filters dropdown
  const [assetOptions, setAssetOptions] = useState();
  const [propertyOptions, setPropertyOptions] = useState();
  const [possessionOptions, setPossessionOptions] = useState();
  const [cityOptions, setCityOptions] = useState();
  const [reservePriceOptions, setReservePriceOptions] = useState();

   const [dropdownData, setDropdownData] = useState({
      propertyTypes: [],
      assetCategories: [],
      possessionTypes: [],
      auctionTypes: [],
    });

    // console.log("dropdownData",dropdownData);
    // console.log("cardStatus",cardStatus);
    
    

  // const {users = []} = useSelector((state) => state?.user?.allUser?.data || {});

  const importModalUsersList = useSelector((state) => state?.property?.importBankUsers?.data || []);
  
  const importModalUsers = importModalUsersList?.map((user) => ({
    id: user?.id,
    value: user?.id,
    label: user?.profile?.name,
    bankName: user?.profile?.bank?.bank_name,
    branchName: user?.profile?.branch?.branch_name,
    city: user?.profile?.branch?.branch_city,
  }))

  // ID will store in this state if we select any user from dropdown
  const [selectedUserId, setSelectedUserId] = useState(""); // Default to first user

  // console.log("selectedUserId===>",selectedUserId);
  

  // Find selected user details
  const selectedUserFromDropdown = importModalUsers.find((user) => user.id === selectedUserId);

  // console.log("selectedUserId",selectedUserId);
  // console.log("selected-Bank",selectedUserFromDropdown);
  
  const { properties, totalProperties, statusCounts } = useSelector(
    (state) => state?.property?.property
  );

  const NpaPropertiesCard = [
    {id: 1, total: statusCounts?.total || 0, title: "All Property", src: AllProperty, status: ""},
    {id: 2, total: statusCounts?.live || 0, title: "Live Property", src: LiveProperty, status: "live",},
    {id: 3, total: statusCounts?.upcoming || 0, title: "Upcoming Property", src: UpcomingProperty, status: "upcoming"},
    {id: 4, total: statusCounts?.expired || 0, title: "Expired Property", src: ExpiredProperty, status: "expired"},
  ];

  // status handler based on Card Click (APi will get call and display list based on status)
  const handleCardClick = (value) => {
    setCardStatus(value);
    setCurrentPage(1);
    setFilterValues({
      asset: "",
      property: "",
      possession: "",
      city: "",
      reservePrice: "",
      search: "",
    })
  };

  const searchTimeout = useRef(null);

  // Debounced Search Handler
  const handleSearch = useCallback(() => {
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    searchTimeout.current = setTimeout(() => {
      dispatch(npaProperties({ 
        search: filterValues?.search, 
        page: currentPage,
        status: cardStatus,
        asset_category: filterValues?.asset,
        property_type: filterValues?.property,
        possession_type: filterValues?.possession,
        city_town: filterValues?.city,
        reserve_price: filterValues?.reservePrice,
       }));
    }, 1000); // 300ms delay for better UX
  }, [filterValues,cardStatus, currentPage, dispatch]);

  // Trigger API Call when search or page changes
  useEffect(() => {
    handleSearch();
    return () => clearTimeout(searchTimeout.current);
  }, [handleSearch]);

  const getAllNpaProperties = useCallback(() => {
    sessionStorage.removeItem('currentPageNo'); // Optional: Clear 
    setLoading(true);
    // Perform both API calls simultaneously
    Promise.all([
      // First API call: dispatching npaPropertiesList
      dispatch(
        npaProperties({
          status: cardStatus,
          page: currentPage,
          limit: limit?.value,
          asset_category: filterValues?.asset,
          property_type: filterValues?.property,
          possession_type: filterValues?.possession,
          city_town: filterValues?.city,
          reserve_price: filterValues?.reservePrice,
          // search: filterValues?.search
        })
      ),

      // Second API call: reqToGetFilterData
      dispatch(reqToGetFilterData({})),
    ])
      .then(([_, filterDataResponse]) => {
        // Assuming reqToGetFilterData response has the desired data
        const data = filterDataResponse?.payload?.filters;

        // Update the states with API response data
        setAssetOptions(mapToSelectOptions(data?.asset_category) || []);
        setPropertyOptions(mapToSelectOptions(data?.property_type) || []);
        setPossessionOptions(mapToSelectOptions(data?.possession_type) || []);
        setCityOptions(mapToSelectOptions(data?.city_town) || []);
        setReservePriceOptions([
          {
            value: data?.reserve_price?.min || 0,
            label: `Min: ₹${data?.reserve_price?.min || 0}`,
          },
          {
            value: data?.reserve_price?.max || 0,
            label: `Max: ₹${data?.reserve_price?.max || 0}`,
          },
        ]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    dispatch,
    setLoading,
    filterValues?.asset,
    filterValues?.property,
    filterValues?.possession,
    filterValues?.city,
    filterValues?.reservePrice,
    cardStatus,
    limit?.value,
    currentPage,
    // filterValues?.search
  ]);

  useEffect(() => {
    getAllNpaProperties();
  }, [getAllNpaProperties]);

  useEffect(() => {
    dispatch(reqToGetFilterData({}));
    dispatch(propertyTypesDropdown({})).then((res) => {
      if (res?.payload?.success === true) {
        const formattedData = {
          propertyTypes: res?.payload?.data?.propertyTypes?.map(item => item?.name),
          assetCategories: res?.payload?.data?.assetCategories?.map(item => item?.name),
          possessionTypes: res?.payload?.data?.possessionTypes?.map(item => item?.name),
          auctionTypes: res?.payload?.data?.auctionTypes.map(item => item?.name),
        };
        setDropdownData(formattedData);
      }
    });
  }, [dispatch]);

  const handleToggleDropdown = (dropdownId) => {
    setOpenDropdown((prev) => (prev === dropdownId ? null : dropdownId));
  };

  // Pagination Handler
  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber);
  };

  const getCustomerActions = (item) => [
    {
      title: "Delete",
      handler: () => handlerDeleteProperty(item),
    },
  ];

  const handlerDeleteProperty = (item) => {
    setSelectedDeleteProperty(item);
    setOpenDeletePropertyModal(true);
  };

  // Main Handler for Deleting NPA Properties
  const handleConfirmDelete = () => {
    try {
      setLoading(true);
      setIsSubmitting(true);
      dispatch(deleteProperty({ id: selectedDeleteProperty?.id })).then(
        (res) => {
          setSnackbarMessage(res?.msg || "Property deleted successfully");
          setSnackbarVariant("toast-success");
          setShowSnackbar(true);
          setOpenDeletePropertyModal(false);
          dispatch(npaProperties({})).then((res) => {
            setLoading(false);
          })
        }
      );
    } catch (error) {
      setSnackbarMessage(error?.msg || "Failed to delete Property");
      setSnackbarVariant("toast-danger");
      setShowSnackbar(true);
      setLoading(false);
    } finally {
      setIsSubmitting(false);
      // setLoading(false); // Optional loader
      setOpenDeletePropertyModal(false);
    }
  };

  const handleEditBtn = (item) => {
    setIsEdit(true);
    navigate(`/npa-properties/edit/${item.id}`, {
      state: { item, isEdit: true },
    });
  };

  // Handler for Exporting NPA Properties
  const handleExportNpaProperties = () => {
    try{
      setLoading(true);
      dispatch(npaProperties({ 
        search: filterValues?.search, 
        page: currentPage,
        status: cardStatus,
        asset_category: filterValues?.asset,
        property_type: filterValues?.property,
        possession_type: filterValues?.possession,
        city_town: filterValues?.city,
        reserve_price: filterValues?.reservePrice,
        limit: 100000 })).then((res) => {
          const data = res?.payload?.properties

          // console.log("data",data);
          
          if (!data || data.length === 0) {
            setSnackbarMessage("No data available to Export");
            setSnackbarVariant("toast-success");
            setShowSnackbar(true);
            setLoading(false);
            return;
          }

          // Map the data for export
          const dataToExport = data?.map((item) => {
            return {
              borrower_names: item?.borrower_names || "-",
              property_type: item?.propertyType?.name || "-",
              asset_category: item?.assetCategory?.name || "-",
              land_area: item?.land_area || "-",
              builtup_carpet_area: item?.builtup_carpet_area || "-",
              dsc_required: item?.dsc_required === true ? "Yes" : item?.dsc_required === false ? "No" : "-",
              possession_type: item?.possessionType?.name || "-",
              auction_start_time: item?.auction_start_time ? new Date(item.auction_start_time).toLocaleString("en-IN", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  }) : "-",
              auction_end_time: item?.auction_end_time ? new Date(item.auction_end_time).toLocaleString("en-IN", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  }) : "-",
              property_inspection_datetime: item?.property_inspection_datetime ? new Date(item.property_inspection_datetime).toLocaleString("en-IN", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  }) : "-",
              auction_type: item?.auctionType?.name || "-",
              application_submission_date: item?.application_submission_date ? new Date(item.application_submission_date).toLocaleString("en-IN", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              }) : "-",
              time_extension: item?.time_extension || "-", 
              property_address: item?.property_address || "-",
              area_locality: item?.area_locality || "-",
              taluka: item?.taluka || "-",
              city_town: item?.city_town || "-",
              province_state: item?.province_state || "-",
              country: item?.country || "-",
              service_provider: item?.service_provider || "-",
              description:item?.description || "-",
              bank_name: item?.Bank?.bank_name || "-",
              branch_name: item?.Branch?.branch_name || "-",
              emd: item?.emd || "-",
              reserve_price: item?.reserve_price || "-",
              min_increment: item?.min_increment || "-",
              amount_outstanding: item?.amount_outstanding || "-",
              contact_name: item?.contact_name || "-",
              contact_number: item?.contact_number || "-",
            }
          })

          // Export to Excel
          const worksheet = XLSX.utils.json_to_sheet(dataToExport);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "NPA Properties");
          const fileName = "NPA Properties(Admin).xlsx";
          XLSX.writeFile(workbook, fileName);
          setLoading(false);
          dispatch(npaProperties({ 
            search: filterValues?.search, 
            page: currentPage,
            status: cardStatus,
            asset_category: filterValues?.asset,
            property_type: filterValues?.property,
            possession_type: filterValues?.possession,
            city_town: filterValues?.city,
            reserve_price: filterValues?.reservePrice, limit: 10 }))
      })
    }catch (error) {
      console.error("Error exporting data: ", error);
      setSnackbarMessage("Error exporting data: ", error);
      setSnackbarVariant("toast-success");
      setShowSnackbar(true);
      setLoading(false); // Disable loading state on error
    }
  }

  // Handler for downloading Sample CSV file
  // const downloadSampleCsvFile = (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   const fileUrl = "/npaProperties.xlsx"; // Ensure this file is in the 'public' folder
  //   const link = document.createElement("a");
  //   link.href = fileUrl;
  //   link.setAttribute("download", "SampleTemplate(Npa-Properties).csv");
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  //   URL.revokeObjectURL(fileUrl); 
  //   setLoading(false);
  // }
  // const downloadSampleCsvFile = async (e) => {
  //   e.preventDefault();
  
  //   const workbook = new ExcelJS.Workbook();
  //   const worksheet = workbook.addWorksheet("Sample");
  
  //   // Define headers
  //   const headers = [
  //     "asset_category",
  //     "property_type",
  //     "property_address",
  //     "province_state",
  //     "taluka",
  //     "city_town",
  //     "area_locality",
  //     "land_area",
  //     "builtup_carpet_area",
  //     "borrower_names",
  //     "possession_type",
  //     "reserve_price",
  //     "emd",
  //     "min_increment",
  //     "amount_outstanding",
  //     "dsc_required",
  //     "auction_type",
  //     "auction_start_time",
  //     "auction_end_time",
  //     "status",
  //     "description",
  //     "country",
  //     "contact_name",
  //     "contact_number",
  //     "time_extension",
  //     "application_submission_date",
  //     "property_inspection_datetime",
  //     "service_provider",
  //   ];
  
  //   worksheet.addRow(headers); // Add header row
  
  //   // Define dropdown options
  //   const dropdownOptions = {
  //     A: ["Residential", "Commercial", "Industrial", "Agricultural"], // asset_category
  //     K: ["Vacant", "Occupied", "Under Litigation"], // possession_type
  //   };
  
  //   // Apply dropdowns to specific columns
  //   Object.keys(dropdownOptions).forEach((colLetter) => {
  //     const colIndex = colLetter.charCodeAt(0) - 64; // Convert 'A' to 1, 'B' to 2, etc.
  
  //     worksheet.getColumn(colIndex).eachCell((cell, rowNumber) => {
  //       if (rowNumber > 1) { // Apply dropdowns to rows below headers
  //         cell.dataValidation = {
  //           type: "list",
  //           allowBlank: false,
  //           formulae: [`"${dropdownOptions[colLetter].join(",")}"`],
  //         };
  //       }
  //     });
  //   });
  
  //   // Generate Excel file and trigger download
  //   const buffer = await workbook.xlsx.writeBuffer();
  //   const fileData = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  //   saveAs(fileData, "SampleTemplate(Npa-Properties).xlsx");
  // };

  // const downloadSampleCsvFile = async (e) => {
  //   e.preventDefault(); // Prevents default event behavior

  //   const workbook = new ExcelJS.Workbook();
  //   const worksheet = workbook.addWorksheet("Sample");
  
  //   // ✅ Static dropdown values
  //   const options1 = ["Select an option", "Option A1", "Option A2", "Option A3", "Option A4"];
  //   const options2 = ["Select an option", "Option B1", "Option B2", "Option B3"];
  
  //   // ✅ Add headers
  //   worksheet.addRow(["Name", "Category", "Type", "Location", "Status"]);
  
  //   // ✅ Set column widths
  //   worksheet.columns = [
  //     { header: "Name", key: "name", width: 20 },
  //     { header: "Category", key: "category", width: 25 },
  //     { header: "Type", key: "type", width: 25 },
  //     { header: "Location", key: "location", width: 20 },
  //     { header: "Status", key: "status", width: 25 },
  //   ];
  
  //   // ✅ Apply dropdowns to cells (Rows 2 to 50) with default "Select an option"
  //   for (let rowIndex = 2; rowIndex <= 50; rowIndex++) {
  //     const categoryCell = worksheet.getCell(`B${rowIndex}`);
  //     const typeCell = worksheet.getCell(`C${rowIndex}`);
  
  //     categoryCell.value = "Select"; // Set default placeholder
  //     typeCell.value = "Select"; // Set default placeholder
  
  //     categoryCell.dataValidation = {
  //       type: "list",
  //       allowBlank: false,
  //       formulae: [`"${options1.join(",")}"`],
  //     };
  
  //     typeCell.dataValidation = {
  //       type: "list",
  //       allowBlank: false,
  //       formulae: [`"${options2.join(",")}"`],
  //     };
  
  //     // ✅ Add light background for better visibility
  //     categoryCell.fill = {
  //       type: "pattern",
  //       pattern: "solid",
  //       fgColor: { argb: "FFFFFF" }, // Light orange
  //     };
  //     typeCell.fill = {
  //       type: "pattern",
  //       pattern: "solid",
  //       fgColor: { argb: "FFFFFF" }, // Light green
  //     };
  
  //     // ✅ Add border to dropdown cells
  //     [categoryCell, typeCell].forEach((cell) => {
  //       cell.border = {
  //         top: { style: "thin" },
  //         left: { style: "thin" },
  //         bottom: { style: "thin" },
  //         right: { style: "thin" },
  //       };
  //       cell.font = { bold: true, size: 12 }; // Bigger font for better visibility
  //     });
  //   }
  
  //   // ✅ Apply header styling
  //   worksheet.getRow(1).eachCell((cell) => {
  //     cell.font = { bold: true, color: { argb: "000000" } };
  //     cell.fill = {
  //       type: "pattern",
  //       pattern: "solid",
  //       fgColor: { argb: "FFD3D3D3" }, // Light gray background
  //     };
  //     cell.alignment = { horizontal: "center" };
  //   });
  
  //   // ✅ Generate and download Excel file
  //   const buffer = await workbook.xlsx.writeBuffer();
  //   const blob = new Blob([buffer], {
  //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //   });
  
  //   saveAs(blob, "SampleTemplate.xlsx");
  // };
  // const downloadSampleCsvFile = async (e) => {
  //   e.preventDefault(); // Prevent default event behavior
  
  //   const workbook = new ExcelJS.Workbook();
  //   const worksheet = workbook.addWorksheet("Sample");
  
  //   // ✅ Dynamic dropdown values (Fixed)
  //   const optionsPropertyTypes = ["Select", ...dropdownData.propertyTypes];
  //   const optionsAssetCategories = ["Select", ...dropdownData.assetCategories];
  //   const optionsPossessionTypes = ["Select", ...dropdownData.possessionTypes];
  //   const optionsAuctionTypes = ["Select", ...dropdownData.auctionTypes];
  
  //   // ✅ Define headers
  //   const headers = [
  //     "asset_category", "property_type", "property_address", "province_state", "taluka", "city_town",
  //     "area_locality", "land_area", "builtup_carpet_area", "borrower_names", "possession_type",
  //     "reserve_price", "emd", "min_increment", "amount_outstanding", "dsc_required", "auction_type",
  //     "auction_start_time", "auction_end_time", "status", "description", "country", "contact_name",
  //     "contact_number", "time_extension", "application_submission_date", "property_inspection_datetime",
  //     "service_provider"
  //   ];
  
  //   // ✅ Add headers to the worksheet
  //   worksheet.addRow(headers);
  
  //   // ✅ Set column widths for better visibility
  //   worksheet.columns = headers.map(header => ({ header, key: header, width: 25 }));
  
  //   // ✅ Apply dropdowns to specific columns (Rows 2 to 50)
  //   for (let rowIndex = 2; rowIndex <= 10; rowIndex++) {
  //     const assetCategoryCell = worksheet.getCell(`A${rowIndex}`);
  //     const propertyTypeCell = worksheet.getCell(`B${rowIndex}`);
  //     const possessionTypeCell = worksheet.getCell(`K${rowIndex}`);
  //     const auctionTypeCell = worksheet.getCell(`Q${rowIndex}`);
  
  //     // Set default placeholder text
  //     assetCategoryCell.value = "Select";
  //     propertyTypeCell.value = "Select";
  //     possessionTypeCell.value = "Select";
  //     auctionTypeCell.value = "Select";
  
  //     // Apply dropdown validation
  //     assetCategoryCell.dataValidation = {
  //       type: "list",
  //       allowBlank: false,
  //       formulae: [`"${optionsAssetCategories.join(",")}"`],
  //     };
  //     propertyTypeCell.dataValidation = {
  //       type: "list",
  //       allowBlank: false,
  //       formulae: [`"${optionsPropertyTypes.join(",")}"`],
  //     };
  //     possessionTypeCell.dataValidation = {
  //       type: "list",
  //       allowBlank: false,
  //       formulae: [`"${optionsPossessionTypes.join(",")}"`],
  //     };
  //     auctionTypeCell.dataValidation = {
  //       type: "list",
  //       allowBlank: false,
  //       formulae: [`"${optionsAuctionTypes.join(",")}"`],
  //     };
  
  //     // Apply styling for better visibility
  //     [assetCategoryCell, propertyTypeCell, possessionTypeCell, auctionTypeCell].forEach(cell => {
  //       cell.fill = {
  //         type: "pattern",
  //         pattern: "solid",
  //         fgColor: { argb: "FFFFFF" }, // Light background
  //       };
  //       cell.border = {
  //         top: { style: "thin" },
  //         left: { style: "thin" },
  //         bottom: { style: "thin" },
  //         right: { style: "thin" },
  //       };
  //       cell.font = { bold: true, size: 12 };
  //     });
  //   }
  
  //   // ✅ Apply header styling
  //   worksheet.getRow(1).eachCell(cell => {
  //     cell.font = { bold: true, color: { argb: "000000" } };
  //     cell.fill = {
  //       type: "pattern",
  //       pattern: "solid",
  //       fgColor: { argb: "FFD3D3D3" }, // Light gray background
  //     };
  //     cell.alignment = { horizontal: "center" };
  //   });
  
  //   // ✅ Generate and download Excel file
  //   const buffer = await workbook.xlsx.writeBuffer();
  //   const blob = new Blob([buffer], {
  //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //   });
  
  //   saveAs(blob, "SampleTemplate.xlsx");
  // };
  const downloadSampleCsvFile = async (e) => {
    e.preventDefault(); // Prevent default event behavior
    setLoading(true);
  
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sample");
  
    // ✅ Dynamic dropdown values
    const optionsPropertyTypes = ["Select", ...dropdownData.propertyTypes];
    const optionsAssetCategories = ["Select", ...dropdownData.assetCategories];
    const optionsPossessionTypes = ["Select", ...dropdownData.possessionTypes];
    const optionsAuctionTypes = ["Select", ...dropdownData.auctionTypes];
  
    // ✅ Define headers
    const headers = [
      "asset_category", "property_type", "property_address", "province_state", "taluka", "city_town",
      "area_locality", "land_area", "builtup_carpet_area", "borrower_names", "possession_type",
      "reserve_price", "emd", "min_increment", "amount_outstanding", "dsc_required", "auction_type",
      "auction_start_time", "auction_end_time", "description", "country", "contact_name",
      "contact_number", "time_extension", "application_submission_date", "property_inspection_datetime",
      "service_provider"
    ];
  
    // ✅ Add headers to the worksheet
    worksheet.addRow(headers);
  
    // ✅ Set column widths for better visibility
    worksheet.columns = headers.map(header => ({ header, key: header, width: 25 }));
  
    // ✅ Static values for other columns
    const staticValues = {
      property_address: "123 Main St",
      province_state: "Gujarat",
      taluka: "Varacha",
      city_town: "Surat",
      area_locality: "Katargam",
      land_area: "2000.00",
      builtup_carpet_area: "1500.00",
      borrower_names: "John Doe",
      reserve_price: "5000000.00",
      emd: "5000000.00",
      min_increment: "6000000.00",
      amount_outstanding: "8000000.00",
      dsc_required: "No",
      auction_start_time: "01/01/2025, 05:30 am",
      auction_end_time: "05/01/2020, 05:30 am",
      description: "Spacious house 3Bhk",
      country: "India",
      contact_name: "Manish Mehta",
      contact_number: "1234567890",
      time_extension: "10 min",
      application_submission_date: "06/01/2025, 05:30 am",
      property_inspection_datetime: "10/01/2025, 05:30 am",
      service_provider: "Mehta and Sons",
    };
  
    // ✅ Apply dropdowns & static values (Rows 2 to 10 only)
    for (let rowIndex = 2; rowIndex <= 10; rowIndex++) {
      const rowData = [];
  
      headers.forEach((header, index) => {
        if (header === "asset_category") {
          rowData[index] = "Select"; // Dropdown
        } else if (header === "property_type") {
          rowData[index] = "Select"; // Dropdown
        } else if (header === "possession_type") {
          rowData[index] = "Select"; // Dropdown
        } else if (header === "auction_type") {
          rowData[index] = "Select"; // Dropdown
        } else {
          rowData[index] = staticValues[header] || ""; // Static value
        }
      });
  
      worksheet.addRow(rowData);
  
      // ✅ Apply dropdown validation
      const assetCategoryCell = worksheet.getCell(`A${rowIndex}`);
      const propertyTypeCell = worksheet.getCell(`B${rowIndex}`);
      const possessionTypeCell = worksheet.getCell(`K${rowIndex}`);
      const auctionTypeCell = worksheet.getCell(`Q${rowIndex}`);
  
      assetCategoryCell.dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: [`"${optionsAssetCategories.join(",")}"`],
      };
      propertyTypeCell.dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: [`"${optionsPropertyTypes.join(",")}"`],
      };
      possessionTypeCell.dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: [`"${optionsPossessionTypes.join(",")}"`],
      };
      auctionTypeCell.dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: [`"${optionsAuctionTypes.join(",")}"`],
      };
  
      // ✅ Apply styling for better visibility
      [assetCategoryCell, propertyTypeCell, possessionTypeCell, auctionTypeCell].forEach(cell => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFFFF" }, // Light background
        };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cell.font = { bold: true, size: 12 };
      });
    }
  
    // ✅ Apply header styling
    worksheet.getRow(1).eachCell(cell => {
      cell.font = { bold: true, color: { argb: "000000" } };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFD3D3D3" }, // Light gray background
      };
      cell.alignment = { horizontal: "center" };
    });
  
    // ✅ Generate and download Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
  
    saveAs(blob, "SampleTemplate.xlsx");

    setLoading(false);
  };

  // Import Btn Handler
  const importBtnHandler = () => {
    setOpenImportModal(true);
    dispatch(importUserList({limit:1000000}))
  }

  // close Import Modal
  const handleCloseImportModal = () => {
    setOpenImportModal(false);
    setSelectedUserId("")
    setCsvFileName("");
    setCsvData("")
    // setErrors([]);
  }

  // Function to convert Excel serial date to ISO string using Moment.js
  // const convertDatesInObject = (data, dateFields) => {
  //   return data.map((item) => {
  //     const newItem = { ...item };
  //     dateFields.forEach((field) => {
  //       if (newItem[field]) {
  //         const jsDate = new Date((newItem[field] - 25569) * 86400 * 1000); // Convert serial to JS Date
  //         newItem[field] = moment(jsDate).toISOString(); // Convert to ISO format
  //       }
  //     });
  //     return newItem;
  //   });
  // };
 
  // const handleFileUpload = (event) => {
  //   const file = event.target.files[0];
  //   setCsvFileName(file?.name);
  //   console.log("file",file, file?.name);
    
  //   if (!file) return;

  //   // Reset errors
  //   setErrors([]);

  //   const fileExtension = file.name.split(".").pop().toLowerCase();
    
  //   // Check if file is CSV or XLSX
  //   if (fileExtension === "csv") {
  //     handleCSVUpload(file);
  //   } else if (fileExtension === "xlsx") {
  //     handleXLSXUpload(file);
  //   } else {
  //     setErrors(["Invalid file type. Please upload a .csv or .xlsx file."]);
  //   }

  // };

  // const handleCSVUpload = (file) => {
  //   Papa.parse(file, {
  //     complete: (result) => {
  //       if (result.data.length > 0) {
  //          // List of fields that should be converted to ISO date format
  //       const dateFields = [
  //         "application_submission_date",
  //         "auction_start_time",
  //         "auction_end_time",
  //         "property_inspection_datetime"
  //       ];

  //       // Convert date fields
  //       const processedData = convertDatesInObject(result.data, dateFields);
  //         setCsvData(processedData); // Store CSV data as array of objects
  //       } else {
  //         setErrors(["CSV file is empty."]);
  //       }
  //     },
  //     header: true, // Assuming CSV has headers for key-value pairs
  //     skipEmptyLines: true, // Skip empty lines
  //   });
  // };

  // const handleXLSXUpload = (file) => {
  //   const reader = new FileReader();
  //   reader.onload = (e) => {
  //     const data = new Uint8Array(e.target.result);
  //     const workbook = XLSX.read(data, { type: "array" });
  //     const sheetNames = workbook.SheetNames;
  //     const sheet = workbook.Sheets[sheetNames[0]]; // Assuming first sheet to process
  //     const jsonData = XLSX.utils.sheet_to_json(sheet);
  //     if (jsonData.length > 0) {
  //              // List of fields that should be converted to ISO date format
  //              const dateFields = [
  //               "application_submission_date",
  //               "auction_start_time",
  //               "auction_end_time",
  //               "property_inspection_datetime"
  //             ];
      
  //             // Convert date fields
  //             const processedData = convertDatesInObject(jsonData, dateFields);
  //       setCsvData(processedData); // Store XLSX data as array of objects
  //     } else {
  //       setErrors(["XLSX file is empty."]);
  //     }
  //   };
  //   reader.readAsArrayBuffer(file);
  // };

  // // Final Handler for Importing CSV NPA Properties
  // const handleImportNpaProperties = () => {
  //   console.log("Final CSV Data being sent:", csvData); // Ensure data is populated
  //   if (csvData.length === 0) {
  //     alert("No data to import! Please select a valid CSV file.");
  //     return;
  //   }
    
  //   // dispatch(reqToImportNpaProperties({ data: csvData })).then((res) => {
  //   //   console.log("API Response:", res);
  //   // });
  // };

  // 
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file){
      setSnackbarMessage("No data available to Import");
      setSnackbarVariant("toast-success");
      setShowSnackbar(true);
      setLoading(false);
    }
    setCsvFileName(file?.name);
    setCsvData(file);
  }

  const handleSubmit = async () => {
    if (!csvData){
      setSnackbarMessage("Please upload a file before submitting.");
      setSnackbarVariant("toast-success");
      setShowSnackbar(true);
      setLoading(false);
    }
    const formData = new FormData();
    formData.append('properties_file', csvData);
    formData.append('assigned_to',selectedUserId)
    try{
     setLoading(true);
     const result = await dispatch(reqToImportNpaProperties({data:formData})).unwrap()
    //  console.log("result",result)
     if (result){
      setOpenImportModal(false);
      setSnackbarMessage(result?.msg || "Properties Imported Successfully!");
      setSnackbarVariant("toast-success");
      setShowSnackbar(true);
      dispatch(npaProperties({
        status: cardStatus,
        page: currentPage,
        limit: limit?.value,
        asset_category: filterValues?.asset,
        property_type: filterValues?.property,
        possession_type: filterValues?.possession,
        city_town: filterValues?.city,
        reserve_price: filterValues?.reservePrice,
      })).then((res) => {
          setSelectedUserId("")
          setCsvFileName("");
          setCsvData("")
          setLoading(false)
        })
     }
    }catch(error){
      setLoading(false);
      setImportApiErr(error?.message)
      const errorMessage = error?.message || "Failed to Add Import Properties!";
      setSnackbarMessage(errorMessage);
      setSnackbarVariant("toast-danger");
    }finally{
      // setOpenImportModal(false);
      // setSelectedUserId("")
      // setCsvFileName("");
      // setCsvData("")
      // setErrors([]);
    }
  };

  return (
    <>
      {/* Toast Section */}
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{snackbarMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <Container fluid className="px-0">
        <div className="grid gap-4">
          {NpaPropertiesCard?.map((item, i) => (
            <DashboardCard
              key={i}
              classNameMain="g-col-4 g-col-xxl-3"
              totalUser={item?.total}
              title={item?.title}
              src={item?.src}
              alt={item?.title}
              active={item.status === cardStatus}
              status={item?.status}
              onClick={handleCardClick}
            />
          ))}
        </div>

        <div className="bg-white rounded-2 overflow-hidden common-shadow mt-5">
          {/* Export, Import, Add Btn Section */}
          <NpaAddImpExprtBtn
            navigate={navigate}
            filterValues={filterValues}
            setFilterModal={setFilterModal}
            setFilterValues={setFilterValues}
            importBtnHandler={importBtnHandler}
            setCurrentPage={setCurrentPage}
            handleExportNpaProperties={handleExportNpaProperties}
          />

          {/* List Section */}
          <NpaListSection
            isEdit={isEdit}
            data={properties}
            navigate={navigate}
            currentPage={currentPage}
            openDropdown={openDropdown}
            handleEditBtn={handleEditBtn}
            getCustomerActions={getCustomerActions}
            handleToggleDropdown={handleToggleDropdown}
          />

          {/* Pagination */}
          <div className="p-3 mx-1 d-flex flex-wrap align-items-center justify-content-between gap-4">
            {/* <p className="custom-table-infotext ">
              Displaying{" "}
              {Math.min((currentPage - 1) * limit?.value + 1, totalProperties)}{" "}
              to {Math.min(currentPage * limit?.value, totalProperties)} of{" "}
              {totalProperties}
            </p> */}
            <p className="custom-table-infotext ">
              Displaying{" "}
              {Math.min(((currentPage || 1) - 1) * (limit?.value || 10) + 1, totalProperties || 0)}{" "}
              to {Math.min((currentPage || 1) * (limit?.value || 10), totalProperties || 0)} of{" "}
              {totalProperties || 0}
            </p>

            <div className="d-flex gap-4">
              {totalProperties > limit?.value && (
                <>
                  {/* <CreatableSelect
              isClearable
              options={recordsPerPageOptions}
              value={limit}
              onChange={handleChange}
              placeholder="Select or create"
              menuPortalTarget={document.body} // Render the menu at the body level
              menuPlacement="top" // Display the options above the input
              styles={{
                control: (base) => ({
                  ...base,
                  width: "170px", // Fixed width for the input field
                }),
                menu: (base) => ({
                  ...base,
                  width: "170px", // Fixed width for the dropdown menu
                }),
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999, // Ensure it appears above everything
                }),
              }}
            /> */}
                  <CommonPagination
                    totalItems={totalProperties}
                    itemsPerPage={limit?.value}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </Container>

      {/* Delete Modal */}
      <DeletePopup
        show={openDeletePropertyModal}
        onHide={() => setOpenDeletePropertyModal(false)}
        icon={
          <Delete02Icon
            width={48}
            height={48}
            color="#ff4c51"
            strokeWidth="1.5"
          />
        }
        title="Confirm Delete?"
        content="Are you sure you want to delete this Property?"
        removeTitle="Cancel"
        onClickSave={handleConfirmDelete}
        disabled={isSubmitting}
        saveTitle={isSubmitting ? "Deleting..." : "Delete"}
      />

      {/* Filter Modal */}
      <Modal
        size="lg"
        show={filterModal}
        onHide={() => setFilterModal(false)}
        centered
      >
        <Modal.Body className="mx-3 py-3">
          <div className="pb-3 border-bottom">
            {/* <h5 className="clear-btn-link">In this view show records</h5> */}
          </div>
          <Row className="g-4 pt-3">
            <Col xs={4}>
              <Select
                options={assetOptions}
                value={
                  filterValues?.asset
                    ? {
                        value: filterValues?.asset,
                        label: filterValues?.asset,
                      }
                    : null
                }
                isClearable
                placeholder="Asset category"
                className="w-60"
                onChange={(e) => {
                  setFilterValues((val) => ({
                    ...val,
                    asset: e?.value || "",
                  }));
                  setCurrentPage(1)
                }}
              />
            </Col>
            <Col xs={4}>
              <Select
                options={propertyOptions}
                value={
                  filterValues?.property
                    ? {
                        value: filterValues?.property,
                        label: filterValues?.property,
                      }
                    : null
                }
                className="w-60"
                isClearable
                placeholder="Property Type"
                onChange={(e) => {
                  setFilterValues((val) => ({
                    ...val,
                    property: e?.value || "",
                  }));
                  setCurrentPage(1)
                }}
              />
            </Col>
            <Col xs={4}>
              <Select
                options={possessionOptions}
                value={
                  filterValues?.possession
                    ? {
                        value: filterValues?.possession,
                        label: filterValues?.possession,
                      }
                    : null
                }
                className="w-60"
                isClearable
                placeholder="Possession Type"
                onChange={(e) => {
                  setFilterValues((val) => ({
                    ...val,
                    possession: e?.value || "",
                  }));
                  setCurrentPage(1)
                }}
              />
            </Col>
            <Col xs={4}>
              <Select
                options={cityOptions}
                value={
                  filterValues?.city
                    ? { value: filterValues?.city, label: filterValues?.city }
                    : null
                }
                className="w-60"
                isClearable
                placeholder="City"
                onChange={(e) => {
                  setFilterValues((val) => ({
                    ...val,
                    city: e?.value || "",
                  }));
                  setCurrentPage(1)
                }}
              />
            </Col>
            <Col xs={4}>
              <Select
                options={reservePriceOptions}
                value={
                  filterValues?.reservePrice
                    ? {
                        value: filterValues?.reservePrice,
                        label: filterValues?.reservePrice,
                      }
                    : null
                }
                className="w-60"
                isClearable
                placeholder="Reserve Price"
                onChange={(e) => {
                  setFilterValues((val) => ({
                    ...val,
                    reservePrice: e?.value || "",
                  }));
                  setCurrentPage(1)
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="mx-3 py-3">
          <Button
            className="p-0 m-0 clear-btn-link fw-medium"
            type="button"
            variant="link"
            onClick={() => {
              setFilterValues({
                asset: "",
                property: "",
                possession: "",
                city: "",
                reservePrice: "",
              })
              setFilterModal(false)
            }}
          >
            Clear all filters
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Import Modal */}
      <Modal
        show={openImportModal}
        centered
        dialogClassName="common-popup-dialog"
        contentClassName="common-popup-content"
        backdropClassName="common-popup-backdrop"
        // onHide={handleCloseImportModal}
      >
        <Modal.Body className="common-popup-body">
          <div
            className="position-absolute end-0 z-2 bg-white rounded-1 cursor-pointer d-flex align-items-center justify-content-center common-popup-close-btn"
            onClick={handleCloseImportModal}
          >
            <Cancel01Icon width={16} height={16} color="#BCBBC1" strokeWidth="2.5"/>
          </div>
          <div
            className="text-center common-popup-heading"
            style={{ paddingRight: "20px", paddingLeft: "20px" }}
          >
            <h2 className="text-center p-light mb-2">Import Properties</h2>

            <Row className="text-start ">
              <Col className="mt-4" md={12}>
                <CommonFormLabel title="Select User" />
                  {/* Dropdown for user selection */}
                  <Form.Select
                    className="position-relative z-1 custome-select-box"
                    aria-label="Select User"
                    onChange={(e) => setSelectedUserId(e.target.value)} // Update state on selection change
                    value={selectedUserId}
                  >
                    <option value="">Select User</option>
                    {importModalUsers.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.label}
                      </option>
                    ))}
                  </Form.Select>
              </Col>
              {selectedUserFromDropdown && (
                <>
                  <Col md={12} className="mt-4">
                    <CommonFormLabel title="Bank Details" />
                    <div className="table-responsive rounded-2  border">
                      <Table className="tbl-content mb-0">
                        <thead>
                          <tr className="">
                            <th className="text-capitalize fw-normal border-0 text-start py-1 px-4">Bank Name</th>
                            <th className="text-capitalize fw-normal border-0 text-start py-1 px-4">Branch</th>
                            <th className="text-capitalize fw-normal border-0 text-start py-1 px-4">City</th>
                          </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td className="border-0 py-2 px-4">{selectedUserFromDropdown?.bankName || "N/A"}</td>
                          <td className="border-0 py-2 px-4">{selectedUserFromDropdown?.branchName || "N/A"}</td>
                          <td className="border-0 py-2 px-4">{selectedUserFromDropdown?.city || "N/A"}</td>
                        </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                
                  <Col md={12} className="mt-4">
                    <CommonFormLabel title="Import File" />
                    <div className="border position-relative cursor-pointer custom-import-file rounded-2 overflow-hidden">
                      <div>{csvFileName ? csvFileName : "Choose file"}</div>
                      <span className="px-3 text-white d-flex align-items-center justify-content-center  position-absolute top-0 bottom-0 end-0 ">Browse</span>
                      <input type="file" accept=".csv, .xlsx" onChange={handleFileUpload} className=" position-absolute top-0 bottom-0 cursor-pointer start-0 end-0 opacity-0" />
                    </div>
                  </Col>
                </>
                )}
            </Row>

            <div className="text-center" style={{ width: "50%", margin: "0 auto" }}>
              <CommonButton
                title="Import"
                type="submit"
                className="w-100 btn-primary primary-shadow my-4"
                onClick={handleSubmit}
                disabled={!csvData}
              />
              {importApiErr && (
                <div className="alert alert-danger mt-3">
                    <p>{importApiErr}</p>
                </div>
              )}
            </div>
            <div className="d-flex align-items-center text-start justify-content-between">
              <p className="cursor-pointer text-decoration-underline" onClick={(e) => downloadSampleCsvFile(e)}>Download Sample CSV File</p>
              <OverlayTrigger 
                placement="top" 
                delay={{ show: 250, hide: 250 }}
                overlay={<Tooltip id="button-tooltip">Please send your CSV file in the correct format as mentioned in Download Sample CSV file (including headers and values) to avoid any kind of rejections.</Tooltip>}>
                <span className="cursor-pointer d-flex align-items-center justify-content-center">
                  <InformationCircleIcon width={19} height={19} color="#9F2F48" strokeWidth="2" />
                </span>  
              </OverlayTrigger>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
