import React, { useEffect, useState } from "react";
import {Card, Container, Form, Toast, ToastContainer} from "react-bootstrap";
import CommonButton from "../../components/commonButton";
import Property from "../../assets/images/npa-properties/property.png";
import PropertyWhite from "../../assets/images/npa-properties/property-white.png";
import BankDetails from "../../assets/images/npa-properties/bank-details.png";
import BankDetailsWhite from "../../assets/images/npa-properties/bank-detail-white.png";
import CommonMainHeading from "../../components/commonHeading";
import CommonFormLabel from "../../components/formLabel";
import SimpleDropdown from "../../components/simpleDropdown";
import Input from "../../components/input";
import TextArea from "../../components/commonTextArea";
import ContactDetailsImg from "../../assets/images/npa-properties/contact-details.png";
import BackPageTab from "../../components/back-page-tab";
import { ArrowLeftIcon, CheckmarkBadge04Icon} from "../../assets/icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useMounted from "../../hooks/useMounted";
// import {reqToGetAllBankList, reqToGetBranchList,} from "../../redux/slice/userSlice";
import {addNpaProperties, importUserList, npaProperties, propertyTypesDropdown, updateNpaProperties} from "../../redux/slice/propertiesSlice";
import ValidationFeedback from "../../components/validationFeedback";
import moment from "moment";
import { Formik } from "formik";
import { addPropertySchema } from "../../schema/validationSchemas";
// import {assetCategory, auctionType, possessionType, propertyType} from "../../components/Data";
import FileInput from "../../components/fileInput";
import { routes } from "../../routes/pageRoutes";
import { useLoader } from "../../context/LoaderContext";

const AddNpaProperties = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const hasMounted = useMounted();
  const {setLoading} = useLoader();
  const params = useParams();
  const id = params?.id;
  const editValues = location?.state?.item;
  const isEdit = location?.state?.isEdit;

  console.log("editValues", editValues);
  // console.log("isEdit",isEdit);
  
  
  const [showToast, setShowToast] = useState(false);
  const [toastVariant, setToastVariant] = useState("toast-success");
  const [toastMessage, setToastMessage] = useState("");
  const [addPropertyActiveTab, setAddPropertyActiveTab] = useState("property");
  // const [selectedBankId, setSelectedBankId] = useState(
  //   editValues ? (editValues?.bank_id ? editValues?.bank_id : null) : null
  // );
  // const [selectedStateId, setSelectedStateId] = useState(
  //   editValues
  //     ? editValues?.province_state
  //       ? editValues?.province_state
  //       : null
  //     : null
  // );
  // const [stateList, setStateList] = useState([]);
  // const [cityList, setCityList] = useState([]);
  // const [
    // bankOptions,
    //  setBankOptions] = useState([]);
  // const [
    // branchOptions, 
    // setBranchOptions] = useState([]);
  const [setImagePreview] = useState(null);
  const [pdfPreview, setPdfPreview] = useState(null);
  const [dropdownData, setDropdownData] = useState({
    propertyTypes: [],
    assetCategories: [],
    possessionTypes: [],
    auctionTypes: [],
  });

  const importModalUsers= useSelector((state) => state?.property?.importBankUsers?.data || []);

  // console.log("dropDownData",dropdownData);
  

  const importModalUsersList  = importModalUsers?.map((user) => ({
    id: user?.id,
    value: user?.id,
    label: user?.profile?.name,
    bank_id: user?.profile?.bank?.id,
    branch_id: user?.profile?.branch?.id,
  }))

  // console.log("importModalUsersList",importModalUsersList);
  
  

  const initialEventState = {
    assigned_to:editValues?.assigned_to || "",
    bank_id: editValues?.bank_id || "",
    branch_id: editValues?.branch_id || "",
    asset_category: editValues?.asset_category_id || "",
    property_type: editValues?.property_type_id  || "",
    property_address: editValues?.property_address || "",
    province_state: editValues?.province_state || "",
    taluka: editValues?.taluka || "",
    city_town: editValues?.city_town || "",
    area_locality: editValues?.area_locality || "",
    land_area: editValues?.land_area || "",
    builtup_carpet_area: editValues?.builtup_carpet_area || "",
    borrower_names: editValues?.borrower_names || "",
    possession_type: editValues?.possession_type_id || "",
    reserve_price: editValues?.reserve_price || "",
    emd: editValues?.emd || "",
    min_increment: editValues?.min_increment || "",
    amount_outstanding: editValues?.amount_outstanding || "",
    dsc_required: editValues?.dsc_required || "",
    auction_type: editValues?.auction_type_id || "",
    auction_start_time: editValues?.auction_start_time || "",
    auction_end_time: editValues?.auction_end_time || "",
    time_extension: editValues?.time_extension || "",
    application_submission_date: editValues?.application_submission_date || "",
    property_inspection_datetime:
      editValues?.property_inspection_datetime || "",
    service_provider: editValues?.service_provider || "",
    description: editValues?.description || "",
    country: editValues?.country || "",
    property_document: editValues?.property_document || "",
    contact_name: editValues?.contact_name || "",
    contact_number: editValues?.contact_number || "",
  };

  useEffect(() => {
    dispatch(importUserList({limit:1000000}))
    dispatch(propertyTypesDropdown({})).then((res) => {
      if (res?.payload?.success === true){
        setDropdownData(res?.payload?.data)
      }
    })

    // dispatch(reqToGetAllBankList({})).then((res) => {
    //   if (res?.payload?.success === true) {
    //     const list = res?.payload?.banks?.map((item) => ({
    //       value: item?.id,
    //       label: item?.bank_name,
    //     }));
    //     setBankOptions(list);
    //   }
    // });

    // dispatch(reqToGetStateList({})).then((res) => {
    //   if (res?.payload?.success === true) {
    //     const list = res?.payload?.states?.map((item) => ({
    //       value: item?.id,
    //       label: item?.name,
    //     }));
    //     setStateList(list);
    //   }
    // });
  }, [dispatch]);

  // useEffect(() => {
  //   if (selectedBankId !== null) {
  //     dispatch(reqToGetBranchList({ selectedBankId })).then((res) => {
  //       if (res?.payload?.success === true) {
  //         const list = res?.payload?.branches?.map((item) => ({
  //           value: item?.id,
  //           label: `${item?.branch_name} - (${item?.branch_city})`,
  //         }));
  //         setBranchOptions(list);
  //       }
  //     });
  //   }

    // if (selectedStateId !== null) {
    //   dispatch(reqToGetCityList({ selectedStateId })).then((res) => {
    //     if (res?.payload?.success === true) {
    //       const list = res?.payload?.cities?.map((item) => ({
    //         value: item?.id,
    //         label: item?.name,
    //       }));
    //       setCityList(list);
    //     }
    //   });
    // }
  // }, [dispatch, editValues, selectedBankId]);

  const handleSubmit = async (values, { setSubmitting }) => {
    const formData = new FormData();
    formData.append("assigned_to",values?.assigned_to)
    formData.append("bank_id", values?.bank_id);
    formData.append("branch_id", values?.branch_id);
    formData.append("asset_category", values.asset_category);
    formData.append("property_type", values.property_type);
    formData.append("property_address", values.property_address);
    formData.append("province_state", values?.province_state);
    formData.append("taluka", values?.taluka);
    formData.append("city_town", values.city_town);
    formData.append("area_locality", values.area_locality);
    formData.append("land_area", values.land_area);
    formData.append("builtup_carpet_area", values?.builtup_carpet_area);
    formData.append("borrower_names", values?.borrower_names);
    formData.append("possession_type", values.possession_type);
    formData.append("reserve_price", values.reserve_price);
    formData.append("emd", values.emd);
    formData.append("min_increment", values?.min_increment);
    formData.append("amount_outstanding", values?.amount_outstanding);
    formData.append("dsc_required", values?.dsc_required);
    formData.append("auction_type", values?.auction_type);
    formData.append("auction_start_time", values.auction_start_time);
    formData.append("auction_end_time", values.auction_end_time);
    formData.append("time_extension", values.time_extension);
    formData.append(
      "application_submission_date",
      values.application_submission_date
    );
    formData.append(
      "property_inspection_datetime",
      values.property_inspection_datetime
    );
    formData.append("service_provider", values.service_provider);
    formData.append("description", values?.description);
    formData.append("country", values?.country);
    formData.append("contact_name", values?.contact_name);
    formData.append("contact_number", values?.contact_number);

    if (values?.property_document) {
      formData.append("property_document", values.property_document);
    }
 
    setLoading(true);
    try {
      const result = isEdit
        ? await dispatch(updateNpaProperties({ id, data: formData })).unwrap()
        : await dispatch(addNpaProperties({ data: formData })).unwrap();
      setToastMessage(
        isEdit
          ? result?.msg || "Property updated successfully!"
          : result?.msg || "Property Added successfully!"
      );
      setToastVariant("toast-success");
      setTimeout(() => {
        if (hasMounted) {
          navigate("/", { replace: true });
          dispatch(npaProperties({})).then((res) => {
            // setLoading(false);
          })
        }
      }, 1000);
    } catch (err) {
      const errorMessage = isEdit
        ? err?.message || "Failed to Update Properties!"
        : err?.message || "Failed to Add Properties!";
      setToastMessage(errorMessage);
      setToastVariant("toast-danger");
      setSubmitting(false);
      setLoading(false);
    } finally {
      setShowToast(true);
      setSubmitting(false);
      setLoading(false);
    }
  };

  const handleImagePdfChange = (type, file, setFieldValue) => {
    if (type === "image") {
      if (file) {
        setFieldValue("property_image", file);
        setImagePreview(URL.createObjectURL(file));
      }
    } else if (type === "pdf") {
      if (file) {
        setFieldValue("property_document", file);
        setPdfPreview(URL.createObjectURL(file));
      }
    }
  };

  return (
    <>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={5000}
          autohide
          bg={toastVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{toastMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Container>
        <div className="d-flex mb-4 align-items-center">
          <BackPageTab
            to="/"
            icon={
              <ArrowLeftIcon
                width={22}
                height={22}
                color="#444050"
                strokeWidth="2.2"
              />
            }
            title={isEdit ? "Edit Property" : "New Property"}
          />
        </div>

        <Formik
          initialValues={{
            ...initialEventState,
            dsc_required: isEdit ? (initialEventState.dsc_required || false) : "",
          }}
          validationSchema={addPropertySchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            touched,
            isSubmitting,
            handleChange,
            values,
            setFieldValue,
            handleSubmit,
          }) => (
            <>
            {/* {console.log("values", values)} */}
            {/* {console.log("errors", errors)} */}
              <Form onSubmit={handleSubmit}>
                <Card className="bg-white border-0 p-4 rounded-2 shadow-sm">
                  <div className="d-flex align-items-center gap-3">
                    <CommonButton
                      type="button"
                      icon={<span><img src={addPropertyActiveTab ? PropertyWhite : Property} alt="Property" className="img-class"/></span>}
                      className={`${addPropertyActiveTab === "property" ? "btn-primary" : "basic-style"}`}
                      subClassName="gap-2"
                      title="Property"
                      onClick={(e) => {
                        e.stopPropagation();
                        setAddPropertyActiveTab("property")
                      }}
                    />
                    <CommonButton
                      type="button"
                      icon={<span><img src={addPropertyActiveTab ? BankDetailsWhite : BankDetails} alt="BankDetails" className="img-class"/></span>}
                      className={`${addPropertyActiveTab === "bank-details" ? "btn-primary" : "basic-style"}`}
                      subClassName="gap-2"
                      title="Bank Details"
                      onClick={(e) => {
                        e.stopPropagation();
                        setAddPropertyActiveTab("bank-details")}}
                         disabled={
                          !values?.borrower_names ||
                          !values?.property_type ||
                          !values?.asset_category ||
                          !values?.land_area ||
                          !values?.builtup_carpet_area ||
                          !values?.possession_type ||
                          !values?.auction_start_time ||
                          !values?.auction_end_time ||
                          !values?.property_inspection_datetime ||
                          !values?.auction_type ||                           
                          !values?.application_submission_date ||
                          !values?.time_extension ||
                          !values?.property_address ||
                          !values?.area_locality ||
                          !values?.taluka ||
                          !values?.city_town ||
                          !values?.province_state ||
                          !values?.country ||
                          !values?.service_provider ||
                          !values?.description}
                    />
                  </div>
                  {addPropertyActiveTab === "property" ? (
                    <div className="mt-4 pt-1 grid gap-4">
                      <div className="g-col-6 g-col-xxl-4">
                        <CommonFormLabel
                          htmlFor="borrower_names"
                          title="Borrower Name"
                        />
                        <Input
                          id=" borrower_names"
                          name="borrower_names"
                          type="text"
                          placeholder="Elenor.Aguilar"
                          value={values?.borrower_names}
                          onChange={(e) => {
                            setFieldValue("borrower_names", e.target.value);
                          }}
                          className={`shadow-none ${
                            errors?.borrower_names && touched.borrower_names
                              ? "error"
                              : "correct"
                          }`}
                        />
                        {touched.borrower_names && errors.borrower_names ? (
                          <ValidationFeedback title={errors.borrower_names} />
                        ) : null}
                      </div>
                      <div className="g-col-6 g-col-xxl-4">
                        <SimpleDropdown
                          label="Property Type"
                          name="property_type"
                          value={values.property_type}
                          onChange={(selectedOption) => {
                            setFieldValue(
                              "property_type",
                              selectedOption.value
                            );
                          }}
                          // options={propertyType}
                          options={dropdownData.propertyTypes.map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))}
                          placeholder="Select a property type"
                          error={errors?.property_type}
                          touched={touched?.property_type}
                        />
                      </div>
                      <div className="g-col-6 g-col-xxl-4">
                        <SimpleDropdown
                          label="Asset Category"
                          name="asset_category"
                          value={values.asset_category}
                          onChange={(selectedOption) => {
                            setFieldValue(
                              "asset_category",
                              selectedOption.value
                            );
                          }}
                          // options={assetCategory}
                          options={dropdownData?.assetCategories?.map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))}
                          placeholder="Select a asset category"
                          error={errors?.asset_category}
                          touched={touched?.asset_category}
                        />
                      </div>
                      <div className="g-col-6 g-col-xxl-4">
                        <CommonFormLabel
                          htmlFor="land_area"
                          title="Land Area"
                        />
                        <Input
                          id="land_area"
                          name="land_area"
                          type="text"
                          value={values?.land_area}
                          placeholder="5000.50"
                          onChange={handleChange}
                          className={`shadow-none ${
                            errors?.land_area && touched.land_area
                              ? "error"
                              : "correct"
                          }`}
                        />
                        {touched.land_area && errors.land_area ? (
                          <ValidationFeedback title={errors.land_area} />
                        ) : null}
                      </div>
                      <div className="g-col-6 g-col-xxl-4">
                        <CommonFormLabel
                          htmlFor="builtup_carpet_area"
                          title="Carpet Area"
                        />
                        <Input
                          id="builtup_carpet_area"
                          name="builtup_carpet_area"
                          type="text"
                          value={values?.builtup_carpet_area}
                          placeholder="3500.25"
                          onChange={handleChange}
                          className={`shadow-none ${
                            errors?.builtup_carpet_area &&
                            touched.builtup_carpet_area
                              ? "error"
                              : "correct"
                          }`}
                        />
                        {touched.builtup_carpet_area &&
                        errors.builtup_carpet_area ? (
                          <ValidationFeedback
                            title={errors.builtup_carpet_area}
                          />
                        ) : null}
                      </div>
                      <div className="g-col-6 g-col-xxl-4">
                        <div className="mt-21">
                          <CommonFormLabel title="DSC Required" />
                          <div className="d-flex align-items-center justify-content-start h-100 gap-4">
                            <div className="d-flex align-items-center gap-2">
                              <input
                                type="radio"
                                name="dsc_required"
                                value={true}
                                checked={values.dsc_required === true}
                                onChange={(e) =>
                                  setFieldValue("dsc_required", true)
                                }
                              />
                              <CommonFormLabel title="Yes" className="m-0" />
                            </div>
                            <div className="d-flex align-items-center gap-2">
                              <input
                                type="radio"
                                name="dsc_required"
                                value={false}
                                checked={values.dsc_required === false}
                                onChange={(e) =>
                                  setFieldValue("dsc_required", false)
                                }
                              />
                              <CommonFormLabel title="No" className="m-0" />
                            </div>
                          </div>
                          {touched.dsc_required && errors.dsc_required ? (
                            <ValidationFeedback title={errors.dsc_required} />
                          ) : null}
                        </div>
                      </div>
                      <div className="g-col-6 g-col-xxl-4">
                        <SimpleDropdown
                          label="Possession Type"
                          name="possession_type"
                          value={values.possession_type}
                          onChange={(selectedOption) => {
                            setFieldValue(
                              "possession_type",
                              selectedOption.value
                            );
                          }}
                          // options={possessionType}
                          options={dropdownData.possessionTypes?.map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))}
                          placeholder="Select a possession type"
                          error={errors?.possession_type}
                          touched={touched?.possession_type}
                        />
                      </div>
                      <div className="g-col-3 g-col-xxl-2">
                        <CommonFormLabel title="Auction Start Date" />
                        <Input
                          type="datetime-local"
                          className={`shadow-none ${
                            errors?.auction_start_time &&
                            touched.auction_start_time
                              ? "error"
                              : "correct"
                          }`}
                          placeholder="Start date"
                          id="auction_start_time"
                          name="auction_start_time"
                          onChange={(e) => {
                            setFieldValue("auction_start_time", e.target.value);
                          }}
                          value={moment(values?.auction_start_time).format(
                            "YYYY-MM-DDTHH:mm"
                          )}
                          // min={moment().format("YYYY-MM-DDTHH:mm")}
                        />
                        {touched.auction_start_time &&
                        errors.auction_start_time ? (
                          <ValidationFeedback
                            title={errors?.auction_start_time}
                          />
                        ) : null}
                      </div>
                      <div className="g-col-3 g-col-xxl-2">
                        <CommonFormLabel title="Auction End Date" />
                        <Input
                          type="datetime-local"
                          className={`shadow-none ${
                            errors?.auction_end_time && touched.auction_end_time
                              ? "error"
                              : "correct"
                          }`}
                          placeholder="End Date"
                          id="auction_end_time"
                          onChange={(e) => {
                            setFieldValue("auction_end_time", e.target.value);
                          }}
                          value={moment(values?.auction_end_time).format(
                            "YYYY-MM-DDTHH:mm"
                          )}
                          name="auction_end_time"
                          disabled={!values?.auction_start_time}
                          // min={moment(values?.auction_start_time).format(
                          //   "YYYY-MM-DDTHH:mm"
                          // )}
                        />
                        {touched.auction_end_time && errors.auction_end_time ? (
                          <ValidationFeedback
                            title={errors?.auction_end_time}
                          />
                        ) : null}
                      </div>
                      <div className="g-col-6 g-col-xxl-4">
                        <CommonFormLabel title="Property Inspection date" />
                        <Input
                          type="datetime-local"
                          className={`shadow-none ${
                            errors?.property_inspection_datetime &&
                            touched.property_inspection_datetime
                              ? "error"
                              : "correct"
                          }`}
                          placeholder="Start date"
                          id="property_inspection_datetime"
                          onChange={(e) => {
                            setFieldValue(
                              "property_inspection_datetime",
                              e.target.value
                            );
                          }}
                          value={moment(
                            values?.property_inspection_datetime
                          ).format("YYYY-MM-DDTHH:mm")}
                          name="property_inspection_datetime"
                        />
                        {touched.property_inspection_datetime &&
                        errors.property_inspection_datetime ? (
                          <ValidationFeedback
                            title={errors?.property_inspection_datetime}
                          />
                        ) : null}
                      </div>
                      <div className="g-col-6 g-col-xxl-4">
                        <SimpleDropdown
                          label="Auction Type"
                          name="auction_type"
                          value={values.auction_type}
                          onChange={(selectedOption) => {
                            setFieldValue("auction_type", selectedOption.value);
                          }}
                          // options={auctionType}
                          options={dropdownData?.auctionTypes?.map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))}
                          placeholder="Select a Auction Type"
                          error={errors?.auction_type}
                          touched={touched?.auction_type}
                        />
                      </div>
                      <div className="g-col-6 g-col-xxl-4">
                        <CommonFormLabel title="Application Submission date & time" />
                        <Input
                          type="datetime-local"
                          className={`shadow-none ${
                            errors?.application_submission_date &&
                            touched.application_submission_date
                              ? "error"
                              : "correct"
                          }`}
                          placeholder="Start date"
                          id="application_submission_date"
                          name="application_submission_date"
                          onChange={(e) => {
                            setFieldValue(
                              "application_submission_date",
                              e.target.value
                            );
                          }}
                          value={moment(
                            values?.application_submission_date
                          ).format("YYYY-MM-DDTHH:mm")}
                        />
                        {touched.application_submission_date &&
                        errors.application_submission_date ? (
                          <ValidationFeedback
                            title={errors?.application_submission_date}
                          />
                        ) : null}
                      </div>
                      <div className="g-col-6 g-col-xxl-4">
                        <CommonFormLabel title="Time Extension" />
                        <Input
                          type="time"
                          className={`shadow-none ${
                            errors?.time_extension && touched.time_extension
                              ? "error"
                              : "correct"
                          }`}
                          placeholder="Select time"
                          id="time_extension"
                          onChange={(e) => {
                            setFieldValue("time_extension", e.target.value);
                          }}
                          value={values?.time_extension}
                          name="time_extension"
                        />
                        {touched.time_extension && errors.time_extension ? (
                          <ValidationFeedback title={errors?.time_extension} />
                        ) : null}
                      </div>
                      <div className="g-col-6 g-col-xxl-4">
                        <CommonFormLabel
                          htmlFor="property_address"
                          title="Address LIne 1"
                        />
                        <Input
                          id="property_address"
                          name="property_address"
                          type="text"
                          placeholder="123, Palm Beach Road, Sector 15, Navi Mumbai"
                          value={values.property_address}
                          onChange={handleChange}
                          className={`shadow-none ${
                            errors?.property_address && touched.property_address
                              ? "error"
                              : "correct"
                          }`}
                        />
                        {touched.property_address &&
                          errors?.property_address && (
                            <ValidationFeedback
                              title={errors?.property_address}
                            />
                          )}
                      </div>
                      <div className="g-col-6 g-col-xxl-4">
                        <CommonFormLabel
                          htmlFor="area_locality"
                          title="Area Locality"
                        />
                        <Input
                          id="area_locality"
                          name="area_locality"
                          type="text"
                          placeholder="Sector 15"
                          value={values?.area_locality}
                          onChange={handleChange}
                          className={`shadow-none ${
                            errors?.area_locality && touched.area_locality
                              ? "error"
                              : "correct"
                          }`}
                        />
                        {touched.area_locality && errors.area_locality ? (
                          <ValidationFeedback title={errors.area_locality} />
                        ) : null}
                      </div>
                      <div className="g-col-6 g-col-xxl-4">
                        <CommonFormLabel htmlFor="taluka" title="Taluka" />
                        <Input
                          id="taluka"
                          name="taluka"
                          type="text"
                          placeholder="Thane"
                          value={values?.taluka}
                          onChange={handleChange}
                          className={`shadow-none ${
                            errors?.taluka && touched.taluka
                              ? "error"
                              : "correct"
                          }`}
                        />
                        {touched.taluka && errors.taluka ? (
                          <ValidationFeedback title={errors.taluka} />
                        ) : null}
                      </div>
                      <div className="g-col-6 g-col-xxl-4">
                          <CommonFormLabel htmlFor="city_town" title="City" />
                          <Input
                            id="city_town"
                            name="city_town"
                            type="text"
                            placeholder="City"
                            value={values?.city_town}
                            onChange={handleChange}
                            className={`shadow-none ${
                              errors?.city_town && touched.city_town
                                ? "error"
                                : "correct"
                            }`}
                          />
                          {touched.city_town && errors.city_town ? (
                            <ValidationFeedback title={errors.city_town} />
                          ) : null}
                      </div>
                      <div className="g-col-6 g-col-xxl-4">
                          <CommonFormLabel htmlFor="province_state" title="State" />
                          <Input
                            id="province_state"
                            name="province_state"
                            type="text"
                            placeholder="State"
                            value={values.province_state}
                            onChange={handleChange}
                            className={`shadow-none ${
                              errors?.province_state && touched.province_state
                                ? "error"
                                : "correct"
                            }`}
                          />
                          {touched.province_state && errors.province_state ? (
                            <ValidationFeedback title={errors.province_state} />
                          ) : null}
                       </div>
                      <div className="g-col-6 g-col-xxl-4">
                        <CommonFormLabel htmlFor="country" title="Country" />
                        <Input
                          id="country"
                          name="country"
                          type="text"
                          placeholder="Country"
                          value={values?.country}
                          onChange={handleChange}
                          className={`shadow-none ${
                            errors?.country && touched.country
                              ? "error"
                              : "correct"
                          }`}
                        />
                        {touched.country && errors.country ? (
                          <ValidationFeedback title={errors.country} />
                        ) : null}
                      </div>
                      <div className=" g-col-6 g-col-xxl-4">
                        <CommonFormLabel
                          htmlFor="service_provider"
                          title="Service Provider"
                        />
                        <Input
                          id="service_provider"
                          name="service_provider"
                          type="text"
                          placeholder="AuctionCorp Pvt. Ltd."
                          value={values?.service_provider}
                          onChange={handleChange}
                          className={`shadow-none ${
                            errors?.service_provider && touched.service_provider
                              ? "error"
                              : "correct"
                          }`}
                        />
                        {touched.service_provider && errors.service_provider ? (
                          <ValidationFeedback title={errors.service_provider} />
                        ) : null}
                      </div>
                      <div className="g-col-12">
                        <CommonFormLabel
                          htmlFor="description"
                          title="Description"
                        />
                        <TextArea
                          id="description"
                          name="description"
                          placeholder="Description"
                          value={values.description}
                          onChange={handleChange}
                          className={`shadow-none ${
                            errors?.description && touched.description
                              ? "error"
                              : "correct"
                          }`}
                          rows={3}
                        />
                        {touched.description && errors?.description && (
                          <ValidationFeedback title={errors?.description} />
                        )}
                      </div>
                      <div className="g-col-12">
                        <div className="d-flex align-content-center gap-3">
                          <CommonButton
                            type="button"
                            title="Next"
                            className="btn-primary"
                            onClick={(e) => {
                              e.stopPropagation();
                              setAddPropertyActiveTab("bank-details");
                            }}
                            disabled={
                              !values?.borrower_names ||
                              !values?.property_type ||
                              !values?.asset_category ||
                              !values?.land_area ||
                              !values?.builtup_carpet_area ||
                              !values?.possession_type ||
                              !values?.auction_start_time ||
                              !values?.auction_end_time ||
                              !values?.property_inspection_datetime ||
                              !values?.auction_type ||                           
                              !values?.application_submission_date ||
                              !values?.time_extension ||
                              !values?.property_address ||
                              !values?.area_locality ||
                              !values?.taluka ||
                              !values?.city_town ||
                              !values?.province_state ||
                              !values?.country ||
                              !values?.service_provider ||
                              !values?.description}
                          />
                          <CommonButton
                            title="Cancel"
                            className="btn-outline-primary "
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(routes.home);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="mt-4 pt-1 grid gap-4">
                       <div className="g-col-6 g-col-xxl-4">
                        <SimpleDropdown
                          label="Select a Bank User"
                          name="assigned_to"
                          value={values.assigned_to}
                          onChange={(selectedOption) => {
                            setFieldValue("assigned_to", selectedOption.value); // Set the assigned_to
                            setFieldValue("bank_id", selectedOption.bank_id);   // Set the bank_id
                            setFieldValue("branch_id", selectedOption.branch_id); // Set the branch_id
                          }}
                          options={importModalUsersList}
                          placeholder="Select a Bank User"
                          error={errors?.assigned_to}
                          touched={touched?.assigned_to}
                        />
                      </div>
                      {/* <div className="g-col-6 g-col-xxl-4">
                        <SimpleDropdown
                          label="Bank Name"
                          name="bank_id"
                          value={values.bank_id}
                          onChange={(selectedOption) => {
                            setFieldValue("bank_id", selectedOption.value);
                            setSelectedBankId(selectedOption.value);
                          }}
                          options={bankOptions}
                          placeholder="Select a Bank"
                          error={errors?.bank_id}
                          touched={touched?.bank_id}
                        />
                      </div> */}
                      {/* <div className="g-col-6 g-col-xxl-4">
                        <SimpleDropdown
                          label="Branch Name"
                          name="branch_id"
                          value={values.branch_id}
                          onChange={(selectedOption) => {
                            setFieldValue("branch_id", selectedOption.value);
                          }}
                          options={branchOptions}
                          placeholder="Select a Branch"
                          error={errors?.branch_id}
                          touched={touched?.branch_id}
                        />
                      </div> */}
                      {/* <div className="g-col-6 g-col-xxl-4">
                        <SimpleDropdown
                          label="State"
                          name="province_state"
                          value={values.province_state}
                          onChange={(selectedOption) => {
                            setFieldValue(
                              "province_state",
                              selectedOption.value
                            );
                            setSelectedStateId(selectedOption.value);
                          }}
                          options={stateList}
                          placeholder="Select a State"
                          error={errors?.province_state}
                          touched={touched?.province_state}
                        />
                      </div> */}
                      {/* <div className="g-col-6 g-col-xxl-4">
                        <SimpleDropdown
                          label="Branch City"
                          name="city_town"
                          value={values.city_town}
                          onChange={(selectedOption) => {
                            setFieldValue("city_town", selectedOption.value);
                          }}
                          options={cityList}
                          placeholder="Select a City"
                          error={errors?.city_town}
                          touched={touched?.city_town}
                        />
                      </div> */}
                      <div className="g-col-6 g-col-xxl-4">
                        <CommonFormLabel htmlFor="emd" title="EMD" />
                        <Input
                          id="emd"
                          name="emd"
                          type="text"
                          placeholder="250000.00"
                          value={values?.emd}
                          onChange={handleChange}
                          className={`shadow-none ${
                            errors?.emd && touched.emd ? "error" : "correct"
                          }`}
                        />
                        {touched.emd && errors.emd ? (
                          <ValidationFeedback title={errors.emd} />
                        ) : null}
                      </div>
                      <div className="g-col-6 g-col-xxl-4">
                        <CommonFormLabel
                          htmlFor="reserve_price"
                          title="Reserve Price"
                        />
                        <Input
                          id="reserve_price"
                          name="reserve_price"
                          type="text"
                          placeholder="25000000.00"
                          value={values?.reserve_price}
                          onChange={handleChange}
                          className={`shadow-none ${
                            errors?.reserve_price && touched.reserve_price
                              ? "error"
                              : "correct"
                          }`}
                        />
                        {touched.reserve_price && errors.reserve_price ? (
                          <ValidationFeedback title={errors.reserve_price} />
                        ) : null}
                      </div>
                      <div className="g-col-6 g-col-xxl-4">
                        <CommonFormLabel
                          htmlFor="min_increment"
                          title="Minimum Increment"
                        />
                        <Input
                          id="min_increment"
                          name="min_increment"
                          type="text"
                          placeholder="50000.00"
                          onChange={handleChange}
                          value={values?.min_increment}
                          className={`shadow-none ${
                            errors?.min_increment && touched.min_increment
                              ? "error"
                              : "correct"
                          }`}
                        />
                        {touched.min_increment && errors.min_increment ? (
                          <ValidationFeedback title={errors.min_increment} />
                        ) : null}
                      </div>
                      <div className="g-col-6 g-col-xxl-4">
                        <CommonFormLabel
                          htmlFor="amount_outstanding"
                          title="Amount Outstanding"
                        />
                        <Input
                          id="amount_outstanding"
                          name="amount_outstanding"
                          type="text"
                          placeholder="15000000.00"
                          value={values?.amount_outstanding}
                          onChange={handleChange}
                          className={`shadow-none ${
                            errors?.amount_outstanding &&
                            touched.amount_outstanding
                              ? "error"
                              : "correct"
                          }`}
                        />
                        {touched.amount_outstanding &&
                        errors.amount_outstanding ? (
                          <ValidationFeedback
                            title={errors.amount_outstanding}
                          />
                        ) : null}
                      </div>
                      <div className="g-col-12">
                        <div className="d-flex align-items-center mb-2">
                          <img
                            src={ContactDetailsImg}
                            alt="contact-details"
                            className="contact-detail-img"
                          />
                          <CommonMainHeading
                            title="Contact Details"
                            className="contact-detail-head"
                          />
                        </div>
                        <div className="grid gap-4">
                          <div className="g-col-6 g-col-xxl-4">
                            <CommonFormLabel
                              htmlFor="contact_name"
                              title="Contact Name"
                            />
                            <Input
                              id="contact_name"
                              name="contact_name"
                              type="text"
                              placeholder="Name"
                              onChange={handleChange}
                              value={values?.contact_name}
                              className={`shadow-none ${
                                errors?.contact_name && touched.contact_name
                                  ? "error"
                                  : "correct"
                              }`}
                            />
                          </div>
                          <div className="g-col-6 g-col-xxl-4">
                            <CommonFormLabel
                              htmlFor="contact_number"
                              title="Contact Number"
                            />
                            <Input
                              id="contact_number"
                              name="contact_number"
                              type="text"
                              placeholder="Contact Number"
                              value={values?.contact_number}
                              onChange={handleChange}
                              className={`shadow-none ${
                                errors?.contact_number && touched.contact_number
                                  ? "error"
                                  : "correct"
                              }`}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="g-col-12">
                        <div className="d-flex align-items-center mb-2">
                          <img
                            src={ContactDetailsImg}
                            alt="contact-details"
                            className="contact-detail-img"
                          />
                          <CommonMainHeading
                            title="Upload Documents"
                            className="contact-detail-head"
                          />
                        </div>
                        <div className="grid gap-4">
                          <div md={6} className="g-col-6 g-col-xxl-4">
                            <div>
                              <CommonFormLabel
                                htmlFor="property_document"
                                title="Upload Pdf"
                              />
                              <FileInput
                                name="property_document"
                                accept=".pdf"
                                value={values?.property_document}
                                onChange={(file) =>
                                  handleImagePdfChange(
                                    "pdf",
                                    file,
                                    setFieldValue
                                  )
                                }
                                error={errors?.property_document}
                                touched={touched.property_document}
                              />
                              {touched.property_document &&
                                errors?.property_document && (
                                  <ValidationFeedback
                                    title={errors?.property_document}
                                  />
                                )}
                              {pdfPreview && (
                                <div className="mt-3">
                                  <a
                                    href={pdfPreview}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Uploaded PDF
                                  </a>
                                </div>
                              )}
                            </div>
                          </div>
                          <div md={6} className="g-col-6 g-col-xxl-4">
                            {isEdit &&
                              (values.property_document || pdfPreview) && (
                                <>
                                  <div className="mt-3">
                                    {typeof values.property_document ===
                                      "string" &&
                                      values.property_document.includes(
                                        ".pdf"
                                      ) && (
                                        <a
                                          href={`${process.env.REACT_APP_BASE_URL}/${values.property_document}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {values.property_document
                                            .split("property_document-")
                                            .pop()}
                                        </a>
                                      )}
                                  </div>
                                  <div
                                    style={{
                                      width: "100%",
                                      height: "400px",
                                      border: "1px solid #ddd",
                                      borderRadius: "5px",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <iframe
                                      src={
                                        pdfPreview ||
                                        (typeof values.property_document ===
                                        "string"
                                          ? `${process.env.REACT_APP_BASE_URL}/${values.property_document}`
                                          : "")
                                      }
                                      title="PDF Viewer"
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        border: "none",
                                      }}
                                    ></iframe>
                                  </div>
                                </>
                              )}
                          </div>
                          <div className="g-col-12">
                            <div className="d-flex align-items-center gap-3">
                              <CommonButton
                                title="Submit"
                                type="submit"
                                className="btn-primary"
                                disabled={isSubmitting}
                              />
                              <CommonButton
                                type="button"
                                title="Cancel"
                                className="btn-outline-primary"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  navigate(routes.home);
                                }}
                              />
                              <CommonButton
                                type="button"
                                title="Back"
                                className="btn-outline-primary"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setAddPropertyActiveTab("property");
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Card>
              </Form>
            </>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default AddNpaProperties;
