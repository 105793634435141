import React from 'react'
import CommonActionDropDown from '../../../components/common-action-dropdown'
import { MoreVerticalIcon } from '../../../assets/icons'
import StatusChip from '../../../components/statusChip'
import ExportImportImg from "../../../assets/images/table/export.png";
import { ButtonGroup,Table } from 'react-bootstrap'
import { RejectedUserList } from '../../../components/TableHeadings';
import moment from 'moment';
import NoData from '../../../components/no-data';
import Input from '../../../components/input';
import CommonButton from '../../../components/commonButton';
import { BankIcons } from '../../../components/common';

const List = ({data,setCurrentPage,searchTerm,setSearchTerm,openDropdown,getCustomerActions,handleToggleDropdown,handleExportRejectedUser}) => {
  return (
    <>
        <div className="p-4 d-flex align-items-center justify-content-between gap-4">
          <Input
            type="search"
            value={searchTerm}
            placeholder="Search Rejected User"
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setCurrentPage(1);
            }}
            className="shadow-none base-search"
          />

          <div className="action-buttons-container">
            <CommonButton
              icon={<span><img src={ExportImportImg} alt="import" /></span>}
              className="btn-primary-light me-2"
              title="Export"
              onClick={(e) => {
                e.stopPropagation();
                handleExportRejectedUser();
              }}
            />
          </div>
        </div>

        <Table responsive className="common-table">
          <thead>
            <tr>{RejectedUserList?.map((column, index) => (<th key={index}>{column.label}</th>))}</tr>
          </thead>

          <tbody>
            {data?.length === 0 ? (
              <NoData />
            ) : (
              data?.map((item, i) => (
                <tr key={item?.id}>
                  <td>{item?.profile && item?.profile?.name ? item?.profile?.name : '-'}</td>
                  <td>{item?.mobile_number ? item?.mobile_number : '-'}</td>
                  <td>{item?.email ? item?.email : ""}</td>
                  <td>{item?.profile && item?.profile?.designation ? item?.profile?.designation : '-'}</td>
                  <td className="text-start">{item?.profile?.bank ? <>{BankIcons(item?.profile?.bank?.bank_name)}{" "} {item?.profile?.bank?.bank_name}</> : '-'}</td>
                  <td>{item?.profile?.branch ? item?.profile?.branch?.branch_city :"-"}</td>
                  {/* <td>{item?.created_at ? moment(item.created_at).format("DD-MM-YYYY hh:mm A") : ""}</td> */}
                  <td className="text-start">
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                      <span>{item?.created_at ? moment(item.created_at).format("DD MMM YYYY") : ""}</span>
                      <span>{item?.created_at ? moment(item.created_at).format("hh:mm A") : ""}</span>
                    </div>
                  </td>
                  <td className="text-capitalize">
                    {item?.status ? (
                      <StatusChip
                        pill
                        bg="chip-red"
                        title={item?.status}
                        className="text-capitalize"
                      />
                    ) : (
                      ""
                    )}
                  </td>
                  <td onClick={(e) => e.stopPropagation()}>
                    <CommonActionDropDown
                      as={ButtonGroup}
                      id={`dropdown-basic-${i}`}
                      icon={
                        <MoreVerticalIcon
                          width={20}
                          height={20}
                          color="#6D6B77"
                          strokeWidth="4"
                        />
                      }
                      actions={getCustomerActions(item)}
                      show={openDropdown === i}
                      onToggle={() => handleToggleDropdown(i)}
                      className="action-menu"
                    />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
  </>
  )
}

export default List