export const NpaPropertiesList= [
  { id: 1, label: "PROPERTY ID" },
  { id: 2, label: "BORROWER NAME"},
  { id: 3, label: "BANK NAME" },
  { id: 4, label: "PRICE" },
  { id: 5, label: "BANK USER"},
  { id: 6, label: "AUCTION START"},
  { id: 7, label: "AUCTION END"},
  { id: 8, label: "STATUS"},
  { id: 9, label: "ASSET CATEGORY"},
  { id: 10, label: "POSSESSION TYPES" },
  { id: 11, label: "PROPERTY TYPE" },
  { id: 12, label: "TOTAL INQUIRY" },
  // { id: 13, label: "INQUIRY VIEW" },
  { id: 14, label: "ACTIONS" },
];

export const NpaInquiryList = [
    { id: 1, label: "PROPERTY ID" },
    { id: 2, label: "NAME"},
    { id: 3, label: "MOBILE NUMBER" },
    { id: 4, label: "EMAIL ADDRESS" },
    { id: 5, label: "PROPERTY TYPE" },
    { id: 6, label: "POSSESSION TYPES" },
    { id: 7, label: "INQUIRY CREATED" },
    { id: 8, label: "ACTIONS" },
  ];

export const AllUsersList = [
  { id: 1, label: "NAME"},
  { id: 2, label: "MOBILE NUMBER" },
  { id: 3, label: "EMAIL ADDRESS" },
  { id: 4, label: "DESIGNATION" },
  { id: 5, label: "BANK NAME" },
  { id: 6, label: "BRANCH CITY" },
  { id: 7, label: "USER CREATED" },
  { id: 8, label: "STATUS" },
  { id: 9, label: "ACTIONS" },
]

export const RequestedUserList = [
  { id: 1, label: "NAME"},
  { id: 2, label: "MOBILE NUMBER" },
  { id: 3, label: "EMAIL ADDRESS" },
  { id: 4, label: "DESIGNATION" },
  { id: 5, label: "BANK NAME" },
  { id: 6, label: "CITY" },
  { id: 7, label: "USER CREATED" },
  { id: 8, label: "STATUS" },
  { id: 9, label: "ACTIONS" },
]
  
export const ApprovedUserList = [
  { id: 1, label: "NAME"},
  { id: 2, label: "MOBILE NUMBER" },
  { id: 3, label: "EMAIL ADDRESS" },
  { id: 4, label: "DESIGNATION" },
  { id: 5, label: "BANK NAME" },
  { id: 6, label: "CITY" },
  { id: 7, label: "USER CREATED" },
  { id: 8, label: "STATUS" },
  { id: 9, label: "ACTIONS" },
]

export const RejectedUserList = [
  { id: 1, label: "NAME"},
  { id: 2, label: "MOBILE NUMBER" },
  { id: 3, label: "EMAIL ADDRESS" },
  { id: 4, label: "DESIGNATION" },
  { id: 5, label: "BANK NAME" },
  { id: 6, label: "CITY" },
  { id: 7, label: "USER CREATED" },
  { id: 8, label: "STATUS" },
  { id: 9, label: "ACTIONS" },
]

export const BanksList = [
  { id: 1, label: "BANK NAME"},
  { id: 2, label: "BRANCH COUNT" },
]

export const BanksDetailList = [
  { id: 1, label: "Branch Name"},
  { id: 2, label: "Branch City" },
]