import React from "react";
import {Table,ButtonGroup,OverlayTrigger,Tooltip} from "react-bootstrap";
import { NpaPropertiesList } from "../../components/TableHeadings";
import { Link} from "react-router-dom";
import NoData from "../../components/no-data";
import moment from "moment";
import StatusChip from "../../components/statusChip";
import CommonActionDropDown from "../../components/common-action-dropdown";
import { EditIcon, MepEyeIcon, MoreVerticalIcon } from "../../assets/icons";
import { BankIcons, getUserInitial } from "../../components/common";

const NpaListSection = ({
  data,
  navigate,
  currentPage,
  openDropdown,
  handleEditBtn,
  getCustomerActions,
  handleToggleDropdown,
}) => {
  return (
    <>
      {/* Table */}
      <Table responsive className="common-table">
        <thead>
          <tr>
            {NpaPropertiesList?.map((column, index) => (
              <th key={index}>{column.label}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data?.length === 0 ? (
            <NoData />
          ) : (
            data?.map((item, i) => (
              <tr
                key={item?.id}
                onClick={() => navigate(`/npa-properties/property-details/${item?.id}`,{state:{currentPage:currentPage}})}
                className="cursor-pointer"
              >
                <td>{item?.property_number_id ? `# ${item?.property_number_id}` : "-"}</td>
                <td>
                  <div className="d-flex align-items-center gap-2">
                    <div className="profile-icon">
                      {item?.borrower_names &&
                        getUserInitial(item?.borrower_names)}
                    </div>
                    <div className="ms-1">
                      <p className="fw-medium text-capitalize">
                        {item?.borrower_names ? item?.borrower_names : "-"}
                      </p>
                    </div>
                  </div>
                </td>
                <td className="text-start">{item?.Bank && item?.Bank?.bank_name ? <>{BankIcons(item?.Bank?.bank_name)}{" "} {item?.Bank?.bank_name}</> : "-"}</td>
                <td className="text-start">{item?.reserve_price ? `₹ ${item?.reserve_price}` : ""}</td>
                <td className="text-start">{item?.assignedUser ? item?.assignedUser?.profile?.name : item?.createdUser ? item?.createdUser?.profile?.name : "-"}</td>
              
                <td className="text-start">
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                    <span>{item?.auction_start_time ? moment(item.auction_start_time).format("DD MMM YYYY") : ""}</span>
                    <span>{item?.auction_start_time ? moment(item.auction_start_time).format("hh:mm A") : ""}</span>
                  </div>
                </td>

                <td className="text-start">
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                    <span>{item?.auction_end_time ? moment(item.auction_end_time).format("DD MMM YYYY") : ""}</span>
                    <span>{item?.auction_end_time ? moment(item.auction_end_time).format("hh:mm A") : ""}</span>
                  </div>
                </td>
                <td>
                  {item?.status ? (
                    <StatusChip
                      bg={
                        item?.status === "upcoming"
                          ? "chip-warning"
                          : item?.status === "live"
                          ? "chip-green"
                          : item?.status === "expired"
                          ? "chip-red"
                          : "custom-status-chip"
                      }
                      pill
                      title={item?.status}
                      className="text-capitalize"
                    />
                  ) : (
                    "-"
                  )}
                </td>
                <td>{item?.assetCategory ? item?.assetCategory?.name : "-"}</td>
                <td>{item?.possessionType ? <StatusChip bg={"chip-green"} pill title={item?.possessionType?.name} className="text-capitalize"/> : ""}</td>
                <td>{item?.propertyType ? <StatusChip bg={"chip-green"} pill title={item?.propertyType?.name} className="text-capitalize"/> : ""} </td>
                <td>{item?.inquiry_count ? item?.inquiry_count : "-"}</td>
                {/* <td>-</td> */}

                <td onClick={(e) => e.stopPropagation()}>
                <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>Edit</Tooltip>} >
                  <span onClick={() => handleEditBtn(item)} className="mx-2 cursor-pointer">
                    <EditIcon width={20} height={20} color="#6D6B77" strokeWidth="4"/>
                  </span>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-top`}>View</Tooltip>} >
                  <Link to={`/npa-properties/property-details/${item.id}`}>
                    <MepEyeIcon width={20} height={20} color="#6D6B77"strokeWidth="4"/>
                  </Link>
                </OverlayTrigger>
                  <CommonActionDropDown
                    as={ButtonGroup}
                    id={`dropdown-basic-${i}`}
                    icon={
                      <MoreVerticalIcon
                        width={20}
                        height={20}
                        color="#6D6B77"
                        strokeWidth="4"
                      />
                    }
                    actions={getCustomerActions(item)}
                    show={openDropdown === i}
                    onToggle={() => handleToggleDropdown(i)}
                    className="action-menu"
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </>
  );
};

export default NpaListSection;
