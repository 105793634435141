import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../utils/axios";

// To Fetch All Bank List
export const reqToGetAllBankList = createAsyncThunk("auth/reqToGetAllBankList",async ({ rejectWithValue }) => {
  try {
    const response = await Axios.get(`/api/banks/all`);
    return response?.data; // Success case
  } catch (error) {
    return rejectWithValue(error.response.data); // Error case
  }
});

// To Fetch Branch List based on Selected Bank Id
export const reqToGetBranchList = createAsyncThunk("auth/reqToGetBranchList",async (data, { rejectWithValue }) => {
  try {
    const response = await Axios.get(`/api/banks/branches/${data?.selectedBankId}`);
    return response?.data; // Success case
  } catch (error) {
    return rejectWithValue(error.response.data); // Error case
  }
});

// To Fetch All State List
export const reqToGetStateList = createAsyncThunk("auth/reqToGetStateList",async ({ rejectWithValue }) => {
  try {
    const response = await Axios.get(`/api/locations/states`);
    return response?.data; // Success case
  } catch (error) {
    return rejectWithValue(error.response.data); // Error case
  }
});

// To Fetch City List based on selected State Id
export const reqToGetCityList = createAsyncThunk("auth/reqToGetCityList",async (data, { rejectWithValue }) => {
  try {
    const response = await Axios.get(`/api/locations/cities/${data?.selectedStateId}`);
    return response?.data; // Success case
  } catch (error) {
    return rejectWithValue(error.response.data); // Error case
  }
});

// To Add Bank User From Admin
export const reqToAddUserFromAdmin = createAsyncThunk("user/reqToAddUserFromAdmin",async ({data},{ rejectWithValue }) => {
  try {
    const response = await Axios.post(`/api/admin/users/add`,data, authHeaders());
    return response.data; //success Case
  } catch (error) {
    return rejectWithValue(error.message); // Error case
  }
});

// To change Password 
export const reqToChangePassword = createAsyncThunk("user/reqToChangePassword",async ({data},{ rejectWithValue }) => {
  try {
    const response = await Axios.post(`/api/admin/users/change-user-password`,data, authHeaders());
    return response.data; //success Case
  } catch (error) {
    return rejectWithValue(error.message); // Error case
  }
});

// To Fetch All User List
export const allUserList = createAsyncThunk("user/allUserList",async ({search,status,page,limit},{ rejectWithValue }) => {
    try {
      const response = await Axios.get(`/api/admin/users/all?search=${search || ""}&status=${status || ""}&limit=${limit || 10}&page=${page || 1}`, authHeaders());
      return response.data; //success Case
    } catch (error) {
      return rejectWithValue(error.message); // Error case
    }
  });

// TO Approve or REject user based on passed Status
export const approveOrRejectUser = createAsyncThunk("user/approveOrRejectUser",async ({id,data}, { rejectWithValue }) => {
    try {
      const response = await Axios.patch(`/api/admin/users/${id}/status`,data,authHeaders());
      return response.data; //success Case
    } catch (error) {
      return rejectWithValue(error.message); // Error case
    }
});

// To fetch All pending User
export const requestedUser = createAsyncThunk("user/requestedUser",async ({status,search,page,limit}, { rejectWithValue }) => {
    try {
      const response = await Axios.get(`/api/admin/users/status/${status}?search=${search || ""}&limit=${limit || 10}&page=${page || 1}`,authHeaders());
      return response.data; //success Case
    } catch (error) {
      return rejectWithValue(error.message); // Error case
    }
});


const userSlice = createSlice({
  name: "user",
  initialState: {
    error: null,
    isLoading: false,
    banksList: [],
    branchList: [],
    state: [],
    city: [],
    allUser: [],
    requested:[],
    changeUserStatus: {},
    addUser:{},
  },
  reducers: {},
  extraReducers: (builder) => {
    // request to get all bank list
    builder
    .addCase(reqToGetAllBankList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.banksList = action.payload;
    })
    // request to get branch list based on selected bank
    .addCase(reqToGetBranchList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.branchList = action.payload;
    })
    // request to fetch all state list
    .addCase(reqToGetStateList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.state = action.payload;
    })
    // request to get all city list
    .addCase(reqToGetCityList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.city = action.payload;
    })
    // request to add Bank user from admin
    .addCase(reqToAddUserFromAdmin.fulfilled, (state, action) => {
      state.isLoading = false;
      state.addUser = action.payload;
    })
    // fetch All register Users List
    .addCase(allUserList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allUser = action.payload;
    })
    // Approve or Reject the Registered User
    .addCase(approveOrRejectUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.changeUserStatus = action.payload;
    })
    // Requested User (Approved,Reject,Pending)
    .addCase(requestedUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.requested = action.payload;
    })
  },
});

export default userSlice.reducer;
