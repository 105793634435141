import React, { useEffect, useState } from "react";
import { Card, Col, Container, Image, Row, Toast, ToastContainer } from "react-bootstrap";
import {ArrowLeftIcon,Calendar03Icon,CarpetAreaIcon,CheckmarkBadge04Icon,Clock05Icon,DailerIcon,GreenDownloadIcon,LandAreaIcon,PdfIcon,RedUserIcon} from "../../assets/icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StatusChip from "../../components/statusChip";
import { useDispatch, useSelector } from "react-redux";
import { useLoader } from "../../context/LoaderContext";
import { propertyDetails } from "../../redux/slice/propertiesSlice";
import { BankIcons, formatCurrencyIndianStyle} from "../../components/common";
import moment from "moment";
import { routes } from "../../routes/pageRoutes";
import Avatar from "../../assets/images/npa-properties/avatar.png";
import BackPageBtnTab from "../../components/back-page-btn-tab";

const ViewNpaProperties = () => {
  const { setLoading } = useLoader();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const id = params?.id;

  const details = useSelector((state) => state?.property?.propertyDetail);
  const passedPageNo = location?.state?.currentPage
  // console.log("passedPageNo",passedPageNo);

  //Toast
  const [showToast, setShowToast] = useState(false);
  const [toastVariant, setToastVariant] = useState("toast-success");
  const [toastMessage, setToastMessage] = useState("");

    useEffect(() => {
      if (id) {
        setLoading(true);
        dispatch(propertyDetails({ id }))
          .then(() => {
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }, [dispatch,id,setLoading]);

  const downloadPDF = async () => {
    if (!details?.property_document) {
      setShowToast(true);
      setToastMessage("No document available for Download");
      setToastVariant("toast-danger");
      return;
    }

    try {
      setLoading(true);
      // Construct the full URL
      const fullUrl = `${process.env.REACT_APP_BASE_URL}/${details?.property_document}`;

      // Fetch the file as a Blob
      const response = await fetch(fullUrl);

      if (!response.ok) {
        throw new Error("Failed to fetch the file.");
      }
      const blob = await response.blob();

      // Create a Blob URL and trigger the download
      const blobUrl = URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = blobUrl;
      anchor.download = details ? details?.borrower_names : "Downloaded Pdf"; // Extract the filename
      document.body.appendChild(anchor); // Append the anchor to the body
      anchor.click(); // Trigger the download
      document.body.removeChild(anchor); // Remove the anchor after download
      URL.revokeObjectURL(blobUrl); // Release the Blob URL
      setLoading(false);
      setToastMessage("Document downloaded successfully");
      setToastVariant("toast-success");
    } catch (error) {
      setLoading(false);
      setToastMessage("Error downloading PDF:", error);
      setToastVariant("toast-danger");
      console.error("Error downloading PDF:", error);
    } finally {
      setShowToast(true);
      setLoading(false);
    }
  };

  // Back button (for navigating to a listing page)
  const handleBack = () => {
    navigate(routes.home)
    sessionStorage.setItem('currentPageNo', passedPageNo);
  }

  return (
    <>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={5000}
          autohide
          bg={toastVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{toastMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Container fluid className="px-0">
        <BackPageBtnTab
          type="button"
          onClick={handleBack}
          icon={
            <ArrowLeftIcon
              width={22}
              height={22}
              color="#444050"
              strokeWidth="2.2"
            />
          }
          title="Details"
        />
        <Row className="mt-4 gy-4 mt-0">
          <Col xl={12} xxl={8}>
            <div className="grid column-gap-4 column-gap-xxl-0">
              <Card className="rounded-2 g-col-12 g-col-xl-6 g-col-xxl-12 p-4 border-0 common-shadow w-100 common-card">
                <Card.Header className="px-0 pt-0 bg-transparent border-0 common-card-header">
                  <div className="d-flex justify-content-between align-items-center gap-3">
                    <h5 className="fw-medium npa-details">
                      {/* Shivam Plaza Commercial Hub */}
                      {`${details?.assetCategory?.name} for Auction at ${details?.city_town ? details?.city_town : ""}`}
                    </h5>
                    <StatusChip
                      pill
                      bg={
                        details?.status === "upcoming"
                          ? "chip-warning"
                          : details?.status === "live"
                          ? "chip-green"
                          : details?.status === "expired"
                          ? "chip-red"
                          : "custom-status-chip"
                      }
                      title={details?.status}
                      className="text-capitalize"
                    />
                  </div>
                </Card.Header>
                <Card.Body className="p-0 common-card-body">
                  <div className="d-flex align-items-center gap-2 text-decoration-none mb-2 card-profile">
                    <div className="rounded-circle d-flex align-items-center justify-content-center flex-shrink-1 user-icon">
                      <span className="text-uppercase fw-normal">
                        {details?.Bank
                          ? BankIcons(details?.Bank?.bank_name)
                          : "NA"}
                      </span>
                    </div>

                    <p className="ms-1 p-light">
                      {details?.Bank ? details?.Bank?.bank_name : "NA"}
                    </p>
                  </div>
                  <div className="d-flex flex-column gap-3 card-content">
                    <div>
                      <p className="mb-1 p-light mb-3">
                        Possession Type{" "}
                        <span className="fw-medium">
                          {details?.possessionType
                            ? details?.possessionType?.name
                            : "-"}
                        </span>
                      </p>
                      <p className="mb-3">
                        <StatusChip
                          pill
                          bg="chip-green"
                          title={
                            details?.possessionType
                              ? details?.possessionType?.name
                              : "-"
                          }
                          className="text-capitalize"
                        />
                        <StatusChip
                          pill
                          bg="chip-green"
                          title={
                            details?.propertyType
                              ? details?.propertyType?.name
                              : "-"
                          }
                          className="text-capitalize mx-2"
                        />
                      </p>
                      <h6 className="mb-2 npa-details">About</h6>
                      <p className="mb-1 fw-normal mb-3">
                        {details?.property_address
                          ? details?.property_address
                          : "-"}
                      </p>
                      <div className="d-flex flex-column flex-xxl-row gap-3 w-100">
                        <div className="rounded border p-3 flex-grow-1">
                          {/* Styled div */}
                          <Row>
                            <Col
                              xs={6}
                              className="d-flex flex-column align-items-center border-end"
                            >
                              <p className="mb-1 p-light">Created date</p>
                              <h6 className="npa-details">
                                {details?.created_at
                                  ? moment(details?.created_at).format(
                                      "DD-MM-YYYY"
                                    )
                                  : "-"}
                              </h6>
                            </Col>
                            <Col
                              xs={6}
                              className="d-flex flex-column align-items-center"
                            >
                              <p className="mb-1 p-light">Created time</p>
                              <h6 className="npa-details">
                                {" "}
                                {details?.created_at
                                  ? moment(details?.created_at).format(
                                      "hh:mm A"
                                    )
                                  : "-"}
                              </h6>
                            </Col>
                          </Row>
                        </div>
                        {/* Second Box */}
                        <div className="rounded border p-3 flex-grow-1">
                          {" "}
                          {/* Styled div */}
                          <Row>
                            <Col
                              xs={6}
                              className="d-flex flex-column align-items-center border-end"
                            >
                              <p className="mb-1 p-light">Updated date</p>
                              <h6 className="npa-details">
                                {details?.updated_at
                                  ? moment(details?.updated_at).format(
                                      "DD-MM-YYYY"
                                    )
                                  : "-"}
                              </h6>
                            </Col>
                            <Col
                              xs={6}
                              className="d-flex flex-column align-items-center"
                            >
                              <p className="mb-1 p-light">Updated time</p>
                              <h6 className="npa-details">
                                {details?.updated_at
                                  ? moment(details?.updated_at).format(
                                      "hh:mm A"
                                    )
                                  : "-"}
                              </h6>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <Card className="rounded-2 g-col-12 g-col-xl-6 g-col-xxl-12 p-4 border-0 common-shadow w-100 common-card">
                <Card.Header className="px-0 pt-0 pb-4 bg-transparent border-0 common-card-header">
                  <div className="d-flex justify-content-between align-items-center gap-3">
                    <h6 className="mb-2 npa-details">Bank Details</h6>
                  </div>
                </Card.Header>
                <Card.Body className="p-0 common-card-body">
                  <div className="d-flex align-items-center gap-2 text-decoration-none mb-4 card-profile">
                    <div className="rounded-circle d-flex align-items-center justify-content-center flex-shrink-1 user-icon">
                      <span className="text-uppercase fw-normal">
                        {details?.Bank
                          ? BankIcons(details?.Bank?.bank_name)
                          : "NA"}
                      </span>
                    </div>

                    <div className="d-flex flex-column">
                      <h6 className="npa-details">
                        {details?.Bank ? details?.Bank?.bank_name : "NA"}
                      </h6>
                      <p className="p-light">Bank Name</p>
                    </div>
                  </div>

                  <div className="d-flex flex-column gap-3 card-content">
                    <div className="d-flex align-items-center justify-content-between border-bottom ">
                      <p className="p-extra-light mb-2">Branch Name</p>
                      <p className="p-dark mb-2">
                        {details?.Branch ? details?.Branch?.branch_name : "-"}
                      </p>
                    </div>
                    <div className="d-flex align-items-center justify-content-between border-bottom">
                      <p className="p-extra-light mb-2">Branch City</p>
                      <p className="p-dark mb-2">
                        {details?.Branch ? details?.Branch?.branch_city : "-"}
                      </p>
                    </div>

                    <div className="d-flex flex-column flex-xxl-row gap-3 w-100">
                      {/* First Box */}
                      <div className="rounded border p-3 flex-grow-1">
                        {" "}
                        {/* Styled div */}
                        <Row>
                          <Col
                            xs={6}
                            className="d-flex flex-column align-items-center border-end"
                          >
                            <p className="mb-1 p-light">EMD</p>
                            <h6 className="npa-details">
                              {details?.emd
                                ? `₹ ${formatCurrencyIndianStyle(details?.emd)}`
                                : "-"}
                            </h6>
                          </Col>
                          <Col
                            xs={6}
                            className="d-flex flex-column align-items-center"
                          >
                            <p className="mb-1 p-light">Reserve Price</p>
                            <h6 className="npa-details">
                              {details?.reserve_price
                                ? `₹ ${formatCurrencyIndianStyle(
                                    details?.reserve_price
                                  )}`
                                : "-"}
                            </h6>
                          </Col>
                        </Row>
                      </div>
                      {/* Second Box */}
                      <div className="rounded border p-3 flex-grow-1">
                        {/* Styled div */}
                        <Row>
                          <Col
                            xs={6}
                            className="d-flex flex-column align-items-center border-end"
                          >
                            <p className="mb-1 p-light">Minimum Increment</p>
                            <h6 className="npa-details">
                              {details?.min_increment
                                ? `₹ ${formatCurrencyIndianStyle(
                                    details?.min_increment
                                  )}`
                                : "-"}
                            </h6>
                          </Col>
                          <Col
                            xs={6}
                            className="d-flex flex-column align-items-center"
                          >
                            <p className="mb-1 p-light">Amount Outstanding</p>
                            <h6 className="npa-details">
                              {details?.amount_outstanding
                                ? `₹ ${formatCurrencyIndianStyle(
                                    details?.amount_outstanding
                                  )}`
                                : "-"}
                            </h6>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <Card className="rounded-2 g-col-12 g-col-lg-6 g-col-xxl-12 p-4 border-0 common-shadow w-100 common-card">
                <Card.Header className="px-0 pt-0 pb-4 bg-transparent border-0 common-card-header">
                  <div className="d-flex justify-content-between align-items-center gap-3">
                    <h5 className="fw-medium text-capitalize">
                      Auction Details
                    </h5>
                  </div>
                </Card.Header>
                <Card.Body className="p-0 common-card-body">
                  <div className="grid row-gap-3 column-gap-0 column-gap-xxl-2">
                    <div className="g-col-12 g-col-xl-6 g-col-xxl-3">
                      <div className="d-flex align-items-center status-content primary-status">
                        <div className="rounded-5 d-flex align-items-center justify-content-center status-icon">
                          <Calendar03Icon
                            width={22}
                            height={22}
                            color="#9F2F48"
                            strokeWidth="1.5"
                          />
                        </div>
                        <div>
                          <h6 className="npa-details">
                            {details?.auction_start_time
                              ? moment(details?.auction_start_time).format(
                                  "DD-MM-YYYY"
                                )
                              : "-"}
                          </h6>
                          <span className="text-capitalize">
                            Auction Start Date
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="g-col-12 g-col-xl-6 g-col-xxl-3">
                      <div className="d-flex align-items-center status-content primary-status">
                        <div className="rounded-5 d-flex align-items-center justify-content-center status-icon">
                          <Clock05Icon
                            width={22}
                            height={22}
                            color="#9F2F48"
                            strokeWidth="1.5"
                          />
                        </div>
                        <div>
                          <h6 className="npa-details">
                            {details?.auction_start_time
                              ? moment(details?.auction_start_time).format(
                                  "hh:mm A"
                                )
                              : "-"}
                          </h6>
                          <span className="text-capitalize">
                            Auction Start Time
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="g-col-12 g-col-xl-6 g-col-xxl-3">
                      <div className="d-flex align-items-center status-content primary-status">
                        <div className="rounded-5 d-flex align-items-center justify-content-center status-icon">
                          <Calendar03Icon
                            width={22}
                            height={22}
                            color="#9F2F48"
                            strokeWidth="1.5"
                          />
                        </div>
                        <div>
                          <h6 className="npa-details">
                            {details?.auction_end_time
                              ? moment(details?.auction_end_time).format(
                                  "DD-MM-YYYY"
                                )
                              : "-"}
                          </h6>
                          <span className="text-capitalize">
                            Auction End Date
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="g-col-12 g-col-xl-6 g-col-xxl-3">
                      <div className="d-flex align-items-center status-content primary-status">
                        <div className="rounded-5 d-flex align-items-center justify-content-center status-icon">
                          <Clock05Icon
                            width={22}
                            height={22}
                            color="#9F2F48"
                            strokeWidth="1.5"
                          />
                        </div>
                        <div>
                          <h6 className="npa-details">
                            {details?.auction_end_time
                              ? moment(details?.auction_end_time).format(
                                  "hh:mm A"
                                )
                              : "-"}
                          </h6>
                          <span className="text-capitalize">
                            Auction End Time
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <Card className="rounded-2 g-col-12 g-col-lg-6 g-col-xxl-12 p-4 border-0 common-shadow w-100 common-card">
                <Card.Header className="px-0 pt-0 pb-4 bg-transparent border-0 common-card-header">
                  <div className="d-flex justify-content-between align-items-center gap-3">
                    <h5 className="fw-medium text-capitalize">
                      Contact Details
                    </h5>
                  </div>
                </Card.Header>
                <Card.Body className="p-0 common-card-body">
                  <div className="grid row-gap-3 column-gap-0 column-gap-xxl-2">
                    <div className="g-col-12 g-col-xl-6 g-col-xxl-3">
                      <div className="d-flex align-items-center status-content primary-status">
                        <div className="rounded-2 d-flex align-items-center justify-content-center status-icon">
                          <span>
                            <RedUserIcon
                              width={22}
                              height={22}
                              color="#9F2F48"
                              strokeWidth="1.5"
                            />
                          </span>
                        </div>
                        <div>
                          <h6 className="npa-details">
                            {details?.contact_name
                              ? details?.contact_name
                              : "-"}
                          </h6>
                          <span className="text-capitalize">Name</span>
                        </div>
                      </div>
                    </div>
                    <div className="g-col-12 g-col-xl-6 g-col-xxl-3">
                      <div className="d-flex align-items-center status-content primary-status">
                        <div className="rounded-2 d-flex align-items-center justify-content-center status-icon">
                          <DailerIcon
                            width={22}
                            height={22}
                            color="#9F2F48"
                            strokeWidth="1.5"
                            className="detail-icons"
                          />
                        </div>
                        <div>
                          <h6 className="npa-details">
                            {details?.contact_number
                              ? details?.contact_number
                              : "-"}
                          </h6>
                          <span className="text-capitalize">Phone</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <Card className="rounded-2 g-col-12 p-4 border-0 common-shadow w-100 common-card">
                <Card.Header className="px-0 pt-0 bg-transparent border-0 common-card-header">
                  <div>
                    <h5 className="fw-medium text-capitalize mb-1">
                      Downloads
                    </h5>
                  </div>
                </Card.Header>
                <Card.Body className="p-0 common-card-body">
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <p>
                      <PdfIcon
                        width={22}
                        height={22}
                        color="#7367f0"
                        strokeWidth="2"
                      />
                     Property document
                    </p>
                    <p className="cursor-pointer" onClick={downloadPDF}>
                      <GreenDownloadIcon
                        width={22}
                        height={22}
                        color="#7367f0"
                        strokeWidth="2"
                      />
                    </p>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </Col>

          <Col xl={12} xxl={4}>
            <div className="grid column-gap-4 column-gap-xxl-0">
              <Card className="rounded-2 g-col-12 g-col-lg-6 g-col-xxl-12 p-4 border-0 common-shadow w-100 common-card">
                <Card.Header className="px-0 pt-0 pb-4 bg-transparent border-0 common-card-header">
                  <div className="d-flex justify-content-between align-items-center gap-3">
                    <h5 className="fw-medium text-capitalize">Details</h5>
                  </div>
                </Card.Header>
                <Card.Body className="p-0 common-card-body">
                  <div
                    className="d-flex align-items-center gap-2 text-decoration-none mb-4 card-profile"
                  >
                    <div className="rounded-circle d-flex align-items-center justify-content-center flex-shrink-1 user-icon">
                      <span className="text-uppercase fw-normal">
                        <Image src={Avatar} alt="Avatar" />
                      </span>
                    </div>

                    <div className="d-flex flex-column">
                      <h6 className="fw-medium ms-1 text-capitalize">
                        {details?.borrower_names
                          ? details?.borrower_names
                          : "-"}
                      </h6>
                      <p className="p-extra-light mx-1">Borrower Name</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between border-bottom">
                    <p className="p-extra-light mb-2">Property ID</p>
                    <p className="p-dark mb-2">
                      {details?.property_number_id
                        ? `#${details?.property_number_id}`
                        : "-"}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between border-bottom mt-2">
                    <p className="p-extra-light mb-2">Auction Type</p>
                    <p className="p-dark mb-2">
                      {details?.auctionType ? details?.auctionType?.name : "-"}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between border-bottom mt-2">
                    <p className="p-extra-light mb-2">Possession Type</p>
                    <p className="p-dark mb-2">
                      {details?.possessionType
                        ? details?.possessionType?.name
                        : "-"}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <p className="p-extra-light mb-2">DSC Required</p>
                    <p className="p-dark mb-2">
                      {details?.dsc_required
                        && details?.dsc_required === true
                          ? "Yes"
                          : details?.dsc_required === false
                          ? "No"
                          : "-"
                        }
                    </p>
                  </div>
                </Card.Body>
              </Card>

              <Card className="rounded-2 g-col-12 g-col-lg-6 g-col-xxl-12 p-4 border-0 common-shadow w-100 common-card">
                <Card.Header className="px-0 pt-0 pb-4 bg-transparent border-0 common-card-header">
                  <div className="d-flex justify-content-between align-items-center gap-3">
                    <h5 className="fw-medium text-capitalize">Area</h5>
                  </div>
                </Card.Header>
                <Card.Body className="p-0 common-card-body">
                  <div className="grid row-gap-3 column-gap-0 column-gap-xxl-2">
                    <div className="g-col-12 g-col-xl-6">
                      <div className="d-flex align-items-center status-content primary-status">
                        <div className="rounded-5 d-flex align-items-center justify-content-center status-icon">
                          <LandAreaIcon
                            width={22}
                            height={22}
                            color="#9F2F48"
                            strokeWidth="1.5"
                          />
                        </div>
                        <div>
                          <h6 className="npa-details">
                            {/* 43,560 sq ft */}
                            {details?.land_area
                              ? `${formatCurrencyIndianStyle(
                                  details?.land_area
                                )} sq ft`
                              : ""}
                          </h6>
                          <span className="text-capitalize">Land Area</span>
                        </div>
                      </div>
                    </div>
                    <div className="g-col-12 g-col-xl-6">
                      <div className="d-flex align-items-center status-content primary-status">
                        <div className="rounded-5 d-flex align-items-center justify-content-center status-icon">
                          <CarpetAreaIcon
                            width={22}
                            height={22}
                            color="#9F2F48"
                            strokeWidth="1.5"
                          />
                        </div>
                        <div>
                          <h6 className="npa-details">
                            {/* 1,500 sq ft */}
                            {details?.builtup_carpet_area
                              ? `${formatCurrencyIndianStyle(
                                  details?.builtup_carpet_area
                                )} sq ft`
                              : ""}
                          </h6>
                          <span className="text-capitalize">Carpet Area</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <Card className="rounded-2 g-col-12 g-col-lg-6 g-col-xxl-12 p-4 border-0 common-shadow w-100 common-card">
                <Card.Header className="px-0 pt-0 pb-4 bg-transparent border-0 common-card-header">
                  <div className="d-flex justify-content-between align-items-center gap-3">
                    <h5 className="fw-medium text-capitalize">
                      Property Inspection
                    </h5>
                  </div>
                </Card.Header>
                <Card.Body className="p-0 common-card-body">
                  <div className="grid row-gap-3 column-gap-0 column-gap-xxl-2">
                    <div className="g-col-12 g-col-xl-6">
                      <div className="d-flex align-items-center status-content primary-status">
                        <div className="rounded-5 d-flex align-items-center justify-content-center status-icon">
                          <Calendar03Icon
                            width={22}
                            height={22}
                            color="#9F2F48"
                            strokeWidth="1.5"
                          />
                        </div>
                        <div>
                          <h6 className="npa-details">
                            {details?.property_inspection_datetime
                              ? moment(
                                  details?.property_inspection_datetime
                                ).format("DD-MM-YYYY")
                              : "-"}
                          </h6>
                          <span className="text-capitalize">
                            Inspection Date
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="g-col-12 g-col-xl-6">
                      <div className="d-flex align-items-center status-content primary-status">
                        <div className="rounded-5 d-flex align-items-center justify-content-center status-icon">
                          <Clock05Icon
                            width={22}
                            height={22}
                            color="#9F2F48"
                            strokeWidth="1.5"
                          />
                        </div>
                        <div>
                          <h6 className="npa-details">
                            {details?.property_inspection_datetime
                              ? moment(
                                  details?.property_inspection_datetime
                                ).format("hh:mm A")
                              : "-"}
                          </h6>
                          <span className="text-capitalize">
                            Inspection Time
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <Card className="rounded-2 g-col-12 g-col-lg-6 g-col-xxl-12 p-4 border-0 common-shadow w-100 common-card">
                <Card.Header className="px-0 pt-0 pb-4 bg-transparent border-0 common-card-header">
                  <div className="d-flex justify-content-between align-items-center gap-3">
                    <h5 className="fw-medium text-capitalize">
                      Application Submission
                    </h5>
                  </div>
                </Card.Header>
                <Card.Body className="p-0 common-card-body">
                  <div className="grid row-gap-3 column-gap-0 column-gap-xxl-2">
                    <div className="g-col-12 g-col-xl-6">
                      <div className="d-flex align-items-center status-content primary-status">
                        <div className="rounded-5 d-flex align-items-center justify-content-center status-icon">
                          <Calendar03Icon
                            width={22}
                            height={22}
                            color="#9F2F48"
                            strokeWidth="1.5"
                          />
                        </div>
                        <div>
                          <h6 className="npa-details">
                            {details?.application_submission_date
                              ? moment(
                                  details?.application_submission_date
                                ).format("DD-MM-YYYY")
                              : "-"}
                          </h6>
                          <span className="text-capitalize">
                            Submissions Date
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="g-col-12 g-col-xl-6">
                      <div className="d-flex align-items-center status-content primary-status">
                        <div className="rounded-5 d-flex align-items-center justify-content-center status-icon">
                          <Clock05Icon
                            width={22}
                            height={22}
                            color="#9F2F48"
                            strokeWidth="1.5"
                          />
                        </div>
                        <div>
                          <h6 className="npa-details">
                            {details?.application_submission_date
                              ? moment(
                                  details?.application_submission_date
                                ).format("hh:mm A")
                              : "-"}
                          </h6>
                          <span className="text-capitalize">
                          Submissions Time
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <Card className="rounded-2 g-col-12  p-4 border-0 common-shadow w-100 common-card">
                <Card.Header className="px-0 pt-0 pb-4 bg-transparent border-0 common-card-header">
                  <div className="d-flex justify-content-between align-items-center gap-3 address-mb">
                    <h5 className="fw-medium text-capitalize">Address</h5>
                  </div>
                </Card.Header>
                <Card.Body className="p-0 common-card-body">
                  <div>
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14874.616504626927!2d72.87209981497662!3d21.245559737334933!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f4fb5c0b087%3A0xb7aabd8a90da0679!2sMota%20Varachha%2C%20Surat%2C%20Gujarat!5e0!3m2!1sen!2sin!4v1733745823143!5m2!1sen!2sin" 
                  width="100%" 
                  height="125" 
                  title="map"
                  style={{
                    borderRadius: "10px"
                  }}
                  allowFullScreen
                  loading="lazy" 
                  referrerPolicy="no-referrer-when-downgrade"></iframe>
                  </div>
                  <div className="d-flex align-items-center justify-content-between border-bottom mb-2 mt-2">
                    <p className="p-extra-light mb-2">Province/State</p>
                    <p className="p-dark mb-2">
                      {details?.province_state ? details?.province_state : "-"}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between border-bottom mb-2">
                    <p className="p-extra-light mb-2">Taluka</p>
                    <p className="p-dark mb-2">
                      {details?.taluka ? details?.taluka : "-"}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between border-bottom mb-2">
                    <p className="p-extra-light mb-2">City/Town</p>
                    <p className="p-dark mb-2">
                      {details?.city_town ? details?.city_town : "-"}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="p-extra-light">Area/locality</p>
                    <p className="p-dark">
                      {details?.area_locality ? details?.area_locality : "-"}
                    </p>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ViewNpaProperties;
