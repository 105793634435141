import React from "react";
import { Form } from "react-bootstrap";
import "./heading.css";

export default function CommonMainHeading({
  htmlFor,
  title,
  className = null,
  style = null,
  onClick,
}) {
  return (
    <Form.Label
      htmlFor={htmlFor}
      className={`mb-1 common-form-heading-label  ${className}`}
      style={style}
      onClick={onclick}
    >
      {title}
    </Form.Label>
  );
}
