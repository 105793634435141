import React from "react";
import { Image } from "react-bootstrap";
import NoDataImg from "../../assets/images/table/no-data.png";

const NoData = () => {
  return (
    <tr>
      <td
        className="text-center"
        colSpan="10"
        // colSpan={getColumnsForActiveTab(activeTab).length + 1}
      >
        <div className=" text-center w-100 blank-table-view">
          <Image
            src={NoDataImg}
            alt="Blank-Table-Data-Image"
            className="img-fluid"
          />
          <div className="mt-3">
            <h5 className="fw-medium">Couldn't find any data.</h5>
            <p className="pt-2 mt-1">
              This report does not contain any points. Try to change your filter
              and try again.
            </p>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default NoData;
