import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Axios, { authHeaders } from "../../utils/axios";

export const getInquiries = createAsyncThunk("inquiry/getInquiries",async ({search,page,limit},{ rejectWithValue }) => {
    try {
      const response = await Axios.get(`/api/admin/inquiries/list?limit=${limit || 10}&page=${page || 1}&search=${search || ""}`, authHeaders());
      return response.data; //success Case
    } catch (error) {
      return rejectWithValue(error.message); // Error case
    }
  }
);

const inquirySlice = createSlice({
    name: 'inquiry',
    initialState: {
        inquiries: [],
        isLoading: false,
        error: null,
    },
    reducers:{},
    extraReducers: (builder) => {
      builder
        .addCase(getInquiries.fulfilled, (state, action) => {
            state.isLoading = false;
            state.inquiries = action.payload;
        })
    }
})

export default inquirySlice.reducer;