import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function SidebarItem({
  to,
  icon,
  title,
  className,
  children = null,
  dropdownIcon = null,
}) {
  const location = useLocation();

  // Memoize isActive to prevent re-creation on every render
  const isActive = useCallback(
    (path) => {
      if (location?.pathname === path) return true;
      // Ensure `/bank-details/:id` is treated as part of `/banks`
      if (location?.pathname.startsWith("/bank-details/") && path === "/banks") return true;
      return false;
    },
    [location.pathname]
  );
  
  // const isActive = useCallback(
  //   (path) => location?.pathname === path,
  //   [location.pathname]
  // );
  

  // Memoize isAnyChildActive to prevent re-creation on every render
  const isAnyChildActive = useCallback(
    () => children && children.some((child) => isActive(child.link)),
    [children, isActive]
  );

  // console.log("isAnyChildActive",isAnyChildActive);

  const [isOpen, setIsOpen] = useState(isAnyChildActive());

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isAnyChildActive()) {
      setIsOpen(true);
    }
  }, [isAnyChildActive]);

  return (
    <li className={`sidebar-item ${isOpen ? "open" : ""}`}>
      {children ? (
        <>
          {/* Parent item for the dropdown */}
          <div
            className="d-flex align-items-center justify-content-between gap-2 mx-2 py-2 px-3 text-decoration-none cursor-pointer"
            onClick={toggleDropdown}
          >
            <div className="d-flex align-items-center gap-2">
              <div>{icon}</div>
              <div className="text-capitalize">{title}</div>
            </div>
            <div className={`ms-3 rotate-icon ${isOpen ? "rotate" : ""}`}>
              {dropdownIcon}
            </div>
          </div>

          {/* Dropdown content */}
          {isOpen && (
            <ul className="dropdown-content list-unstyled">
              {children.map((child, index) => (
                <li key={index} className="sidebar-subitem">
                  <Link
                    to={child.link}
                    className={`d-flex align-items-center gap-2 text-decoration-none ${className} ${
                      isActive(child.link) ? "active" : ""
                    }`}
                  >
                    <div className="ms-4">{child.icon}</div>
                    <div className="text-capitalize">{child.title}</div>
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </>
      ) : (
        // Standard sidebar item
        <Link
          to={to}
          className={`d-flex align-items-center gap-2 text-decoration-none ${className}`}
        >
          {icon}
          <div className="text-capitalize sidebar-title-mr">{title}</div>
        </Link>
      )}
    </li>
  );
}
