import React from 'react';
import Select from 'react-select';
import ValidationFeedback from '../validationFeedback'; // Assuming this is your custom feedback component
import "./index.css";

const SimpleDropdownOther = ({
  label,
  name,
  value,
  onChange,
  options = [],
  placeholder = 'Select an option',
  error,
  touched,
  isClearable
}) => {
  return (
    <div>
      {label && <label htmlFor={name} className="mb-2 common-form-label">{label}</label>}
      <Select
        name={name}
        value={options.find((option) => option?.value === value) || null}
        options={options}
        // onChange={(selectedOption) => onChange(selectedOption)}
        onChange={(selectedOption) => onChange(selectedOption ? selectedOption : { value: '', label: '' })}
        placeholder={placeholder}
        classNamePrefix="custom-dropdown"
        isClearable={isClearable}
      />
      {touched && error ? <ValidationFeedback title={error} /> : null}
    </div>
  );
};

export default SimpleDropdownOther;
