// import React, { useEffect } from 'react';
// import { useDispatch } from 'react-redux'
// import { useNavigate } from "react-router-dom";
// import { userDetails } from "../modules/Auth/authSlice";
// import { Spinner } from "react-bootstrap";
// import { useAuth } from "../context/AuthContext";

// const ProtectedRoute = ({ children }) => {
//     const navigate = useNavigate();
//     const dispatch = useDispatch();
//     const { isAuthenticated } = useAuth();
//     console.log("isAuthenticated---", isAuthenticated)

//     useEffect(() => {
//         const checkAuthenticated = async () => {
//             dispatch(userDetails()).then(res => {
//                 if (res.payload === undefined) {
//                     navigate('/');
//                 } else {
//                     if (res.payload.data.success === false) {
//                         navigate('/');
//                     }
//                 }  
//             });
//         }

//         checkAuthenticated();
//     }, [dispatch,navigate]);

//     // return (isAuthenticated === true) ? children : <div className="loading show"> <Spinner className="loader" animation="grow" variant="primary" /> </div>;
//     return (isAuthenticated === true) ? children : <div className="d-flex justify-content-center align-items-center" style={{ zIndex: 9999999, height: "100vh", width: "100vw", position: "fixed", top: 0, left: 0, backgroundColor: "rgba(0,0,0,0.5)" }}>
//     <Spinner animation="border" role="status" variant="light">
//         <span className="visually-hidden">Loading...</span>
//     </Spinner>
//     </div>;
// };

// export default ProtectedRoute;


import React  from 'react';
import { Navigate} from "react-router-dom";
import { Spinner } from "react-bootstrap";

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('auth-token');
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  const isAuthenticated = token !== null ? true : false;

  // const { isAuthenticated, isLoading } = useSelector((state) => state.auth);
  // const test = useSelector((state) => state?.auth);
  // console.log("test",test);
  
  // const dispatch = useDispatch();
  

  // useEffect(() => {
  //   console.log("Protected Routes Call");
  //   if (!isAuthenticated) {
  //     dispatch(fetchUserDetails()).then((res) => {
  //       console.log("res",res);
  //       if(res?.error?.message){
  //         navigate(routes.login)
  //       }
  //     })
  //   }
  // }, [isAuthenticated, dispatch]);


  // console.log("isAuthenticated---", isAuthenticated)

  // If the authentication state is still loading, you can display a loader or spinner.
  if (isAuthenticated === undefined) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ zIndex: 9999999, height: "100vh", width: "100vw", position: "fixed", top: 0, left: 0, backgroundColor: "rgba(0,0,0,0.5)" }}>
        <Spinner animation="border" role="status" variant="light">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  // If the user is authenticated, render the children (protected route content)
  // If not, redirect to the login page
  return isAuthenticated ? children : <Navigate to="/login" replace />;
};

export default PrivateRoute;
