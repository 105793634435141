import React from "react";
import RedChevronDown from "../../assets/images/table/red-chevron-down.png";
import ExportImportImg from "../../assets/images/table/export.png";

const index = ({title,onClick,disabled,className,type}) => {
  return (
    <button className={`btn-primary-light me-2 export-btn-icon ${className}`} onClick={onClick} disabled={disabled} type={type}>
      <span className="export-icon">
        <img src={ExportImportImg} alt="btn" />
      </span>
      {/* Export */}
      {title}
      <span className="red-chevron">
        <img src={RedChevronDown} alt="export" />
      </span>
    </button>
  );
};

export default index;
