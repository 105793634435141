import React, { useCallback, useEffect, useRef, useState } from "react";
import { Container, Toast, ToastContainer } from "react-bootstrap";
import List from "./List";
import * as XLSX from "xlsx";
import "../../../assets/styles/src/container/pages/npa-properties.css";
import { useDispatch, useSelector } from "react-redux";
import {allUserList, approveOrRejectUser} from "../../../redux/slice/userSlice";
import { CheckmarkBadge04Icon } from "../../../assets/icons";
import { useLoader } from "../../../context/LoaderContext";
import DashboardCard from "../../../components/dashboard-card";
import AddUser from "./AddUser";
import ChangePasswordModal from "./ChangePasswordModal";
import AllUserImg from "../../../assets/images/npa-properties/all-users.png";
import RequestedUser from "../../../assets/images/npa-properties/requested-user.png";
import AcceptedUser from "../../../assets/images/npa-properties/accepted-user.png";
import RejectedUser from "../../../assets/images/npa-properties/rejected-user.png";
import CommonPagination from "../../../components/commonPagination";

const AllUsers = () => {
  const dispatch = useDispatch();
  const { setLoading } = useLoader();
  // Toast
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("toast-success");
  // Pagination
  const [
    limit,
    // setLimit
  ] = useState({ value: 10, label: 10 });
  const [currentPage, setCurrentPage] = useState(1);
  // Open Add User Modal
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  // Open ChangePassword Modal
  const [openChangePwModal, setOpenChangePwModal] = useState(false);
  const [changePasswordId,setChangePasswordId] = useState("");
  // Toggle Dropdown
  const [openDropdown, setOpenDropdown] = useState(null);
  // Card Status
  const [cardStatus, setCardStatus] = useState("");
  // Search Bar
  const [searchTerm, setSearchTerm] = useState("");


  // console.log("cardStatus",cardStatus);

  const {users = [], statusCounts = {}, totalUsers = 0} = useSelector((state) => state?.user?.allUser?.data || {});

  // console.log("Users:", users);
  // console.log("Status Counts:", statusCounts);
  // console.log("Total Users:", totalUsers);

  const AllTypesOfUserCard = [
    {
      id: 1,
      total:statusCounts?.total || 0,
      title: "Total User",
      src: AllUserImg,
      status: "",
    },
    {
      id: 2,
      total: statusCounts?.pending || 0,
      title: "Requested User",
      src: RequestedUser,
      status: "pending",
    },
    {
      id: 3,
      total: statusCounts?.approved || 0,
      title: "Approved User",
      src: AcceptedUser,
      status: "approved",
    },
    {
      id: 4,
      total: statusCounts?.rejected || 0,
      title: "Rejected User",
      src: RejectedUser,
      status: "rejected",
    },
  ];

  // Pagination Handler
  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber);
  };

  // Action Dropdown handler
  const handleToggleDropdown = (dropdownId) => {
    setOpenDropdown((prev) => (prev === dropdownId ? null : dropdownId));
  };

  // status handler based on Card Click (APi will get call and display list based on status)
  const handleCardClick = (value) => {
    setCardStatus(value);
    setCurrentPage(1);
  };

  // Open Add User Modal
  const addUserHandler = () => {
    setOpenAddUserModal(true);
  };

  const getCustomerActions = (item) => {
    if (!item || !item.status) {
      return []; // Return an empty array if the item or status is invalid
    }

    const actions = [];

    if (item.status === "pending") {
      // Show both Approve and Reject buttons
      actions.push(
        {
          title: "Approve",
          handler: () => handleUserApproveOrReject("approved", item),
        },
        {
          title: "Reject",
          handler: () => handleUserApproveOrReject("rejected", item),
        }
      );
    } else if (item.status === "approved") {
      // Show only the Reject button
      actions.push({
        title: "Reject",
        handler: () => handleUserApproveOrReject("rejected", item),
      });
    } else if (item.status === "rejected") {
      // Show only the Approve button
      actions.push({
        title: "Approve",
        handler: () => handleUserApproveOrReject("approved", item),
      });
    }

    // Actions always available
    actions.push(
      // {
      //   title: "Deactivate",
      //   handler: () => handleDeactivateUser(item),
      //   style: { color: "red" },
      // },
      {
        title: "Change Password",
        handler: () => handleChangePassword(item),
      }
    );
    return actions;
  };

  const handleChangePassword = (item) => {
    setChangePasswordId(item);
    setOpenChangePwModal(true);
  };

  // const handleDeactivateUser = (item) => {
  //   // console.log("Deactivate:", item);
  //   // Add logic to view the user's profile
  // };

  // Approve or Reject User
  const handleUserApproveOrReject = (type, item) => {
    try {
      const id = item?.id;
      if (type && id) {
        setLoading(true);
        const data = { status: type };
        dispatch(approveOrRejectUser({ data, id })).then((res) => {
          setToastMessage(
            res?.payload.message || "User status updated successfully!"
          );
          if (res?.payload?.user) {
            dispatch(allUserList({status: cardStatus, page: currentPage}));
          }
        });
      } else {
        setLoading(false);
        setToastMessage("User status or Id Missing!");
        setToastVariant("toast-danger");
      }
    } catch (err) {
      setLoading(false);
      const errorMessage = err?.message || "Failed to update user status!";
      setToastMessage(errorMessage);
      setToastVariant("toast-danger");
    } finally {
      setLoading(false);
      setShowToast(true);
    }
  };

  const searchTimeout = useRef(null);

  // Debounced Search Handler
  const handleSearch = useCallback(() => {
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    searchTimeout.current = setTimeout(() => {
      // console.log("SEARCH API...");  
      dispatch(allUserList({ search: searchTerm, page: currentPage, status: cardStatus, }));
    }, 300); // 300ms delay for better UX
  }, [searchTerm,currentPage,cardStatus, dispatch]);

  // Trigger API Call when search or page changes
  useEffect(() => {
    handleSearch();
    return () => clearTimeout(searchTimeout.current);
  }, [handleSearch]);

  // Api Call for Search
  // useEffect(() => {
  //   const delayTimeout = setTimeout(() => {
  //       dispatch(allUserList({ search: searchTerm }));
  //   }, 3000); // 3 second delay
  
  //   return () => clearTimeout(delayTimeout);
  // }, [searchTerm, dispatch]); 

  // Api Call for All Users
  const getAllUsersData = useCallback(() => {
    setLoading(true);
    // console.log("MAIN API...");
    dispatch(
      allUserList({
        status: cardStatus,
        page: currentPage,
        limit: limit?.value,
      })
    )
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, cardStatus, limit?.value, currentPage, setLoading]);

  useEffect(() => {
    getAllUsersData();
  }, [getAllUsersData]);

  // Handler for Exporting All Users
  const handleExportAllUsersList = () => {
    try{
      setLoading(true);
      dispatch(allUserList({ search: searchTerm, status: cardStatus, limit: 100000 })).then((res) => {
          const data = res?.payload?.data?.users; 
          if (!data || data.length === 0) {
            setToastMessage("No data available to Export");
            setToastVariant("toast-success");
            setShowToast(true);
            setLoading(false);
            return;
          }

          // Map the data for export
          const dataToExport = data?.map((item) => {
            return {
              Name: item?.profile?.name  || "N/A",
              MobileNo: item?.mobile_number || "N/A",
              Email: item?.email || "N/A",
              Designation: item?.profile?.designation || "N/A",
              BankName: item?.profile?.bank?.bank_name || "N/A",
              BranchCity: item?.profile?.branch?.branch_name || "N/A",
              Status: item?.status || "N/A",
              CreatedAt: item?.created_at ? new Date(item.created_at).toLocaleString("en-IN", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  }) : "-",
              UpdatedAt: item?.updated_at ? new Date(item.updated_at).toLocaleString("en-IN", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  }) : "-",
            }
          })

          // Export to Excel
          const worksheet = XLSX.utils.json_to_sheet(dataToExport);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "All Users");
          const fileName = "All Users(Admin).xlsx";
          XLSX.writeFile(workbook, fileName);
          setLoading(false);
          dispatch(allUserList({ search: searchTerm, status: cardStatus,page: currentPage,limit: limit?.value}))
      })
    } catch (error) {
      console.error("Error exporting data: ", error);
      setToastMessage("Error exporting data: ", error);
      setToastVariant("toast-success");
      setShowToast(true);
      setLoading(false); // Disable loading state on error
    }
  }

  return (
    <>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={5000}
          autohide
          bg={toastVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{toastMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Container fluid className="px-0">
        {/* Card Section */}
        <div className="grid gap-4">
          {AllTypesOfUserCard?.map((item, i) => (
            <DashboardCard
              key={i}
              totalUser={item?.total}
              title={item?.title}
              classNameMain="g-col-4 g-col-xxl-3"
              src={item?.src}
              alt={item?.title}
              active={item.status === cardStatus}
              status={item?.status}
              onClick={handleCardClick}
            />
          ))}
        </div>

        <div className="bg-white rounded-2 overflow-hidden common-shadow mt-5">
          {/* List Section */}
          <List
            data={users}
            searchTerm={searchTerm}
            openDropdown={openDropdown}
            setCurrentPage={setCurrentPage}
            setSearchTerm={setSearchTerm}
            addUserHandler={addUserHandler}
            getCustomerActions={getCustomerActions}
            handleToggleDropdown={handleToggleDropdown}
            handleExportAllUsersList={handleExportAllUsersList}
          />

          {/* Pagination */}
          <div className="p-3 mx-1 d-flex flex-wrap align-items-center justify-content-between gap-4">
            <p className="custom-table-infotext ">
              Displaying{" "}
              {Math.min((currentPage - 1) * limit?.value + 1, totalUsers)} to{" "}
              {Math.min(currentPage * limit?.value, totalUsers)} of {totalUsers}
            </p>
            <div className="d-flex gap-4">
              {totalUsers > limit?.value ? (
                <>
                  {/* <CreatableSelect
                      isClearable
                      options={recordsPerPageOptions}
                      value={limit}
                      onChange={handleChange}
                      placeholder="Select or create"
                      menuPortalTarget={document.body} // Render the menu at the body level
                      menuPlacement="top" // Display the options above the input
                      styles={{
                        control: (base) => ({
                          ...base,
                          width: "170px", // Fixed width for the input field
                        }),
                        menu: (base) => ({
                          ...base,
                          width: "170px", // Fixed width for the dropdown menu
                        }),
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999, // Ensure it appears above everything
                        }),
                      }}
                    /> */}

                  <CommonPagination
                    totalItems={totalUsers}
                    itemsPerPage={limit?.value}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                  />
                </>
              ) : null}
            </div>
          </div>

          {/* Change Password Modal */}
          <ChangePasswordModal
            show={openChangePwModal}
            onHide={() => setOpenChangePwModal(false)}
            changePasswordId={changePasswordId}
            setChangePasswordId={setChangePasswordId}
            cardStatus={cardStatus}
            currentPage={currentPage}
            limit={limit}
          />
          {/* Add User Modal */}
          <AddUser
            show={openAddUserModal}
            onHide={() => setOpenAddUserModal(false)}
            setOpenAddUserModal={setOpenAddUserModal}
            cardStatus={cardStatus}
            currentPage={currentPage}
            limit={limit}
          />
        </div>
      </Container>
    </>
  );
};

export default AllUsers;
