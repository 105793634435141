import React from 'react';

export default function FileInput({ name,accept, onChange, error, touched, inputType = null }) {
  const handleFileChange = (event) => {
    if (event.target && event.target.files.length > 0) {
      const file = event.target.files[0];
      onChange(file); // Call the onChange prop with the file object
    }
  };

  return (
    <div>
      {
        inputType === 'image' ? (
          <div className="d-flex align-items-center">
            <input
              type="file"
              accept="image/*"
              name={name}
              onChange={handleFileChange}
              className={`form-control ${error && touched ? 'is-invalid' : ''}`}
            />
          </div>
        ) : (
          <input
            type="file"
            accept={accept}
            name={name}
            onChange={handleFileChange}
            className={`form-control ${error && touched ? 'is-invalid' : ''}`}
          />
        )
      }
      {/* {error && touched && <div className="invalid-feedback">{error}</div>} */}
    </div>
  );
}
